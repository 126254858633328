import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1003;
  background: var(--bg-black-opacity-60, rgba(0, 0, 0, 0.6));
`;

export const BoxContainer = styled.div`
  margin: 0 84px;
  margin-top: 200px;

  z-index: 1004;

  flex-shrink: 0;
  border-radius: 20px;
  background: var(--Font-01_White, #fff);
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  :nth-child(1) {
    margin-bottom: 12px;
  }
  h1 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
  button {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
    height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
