import styled from 'styled-components';
import { STAMP_BACKGROUND } from 'src/assets/database/stampData';
import { BoardSetWidth } from './OverviewStyle';
import { FlexBox } from '../OverviewStyle';

export const BoardSetWidthStamp = styled(BoardSetWidth)`
  background-image: url(${STAMP_BACKGROUND});
  background-position: center;
  background-size: cover;
  padding-top: 52px;
  justify-content: center;
`;

export const StampSuccessModal = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 280px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);

  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    color: var(--Font-02_black, #111);
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.45px;
    margin-bottom: 18px;
  }
  > button {
    width: 90%;
    height: 40px;
    flex-shrink: 0;

    border-radius: 6px;
    background: ${({ theme }) => theme.colors.mainColor};
    color: var(--white, #fff);
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: 20px;
  width: 90%;
  max-width: 328px;
  height: 56px;
  flex-shrink: 0;

  border-radius: 28px;
  background: #111;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.32);

  color: var(--white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
`;
