import { useEffect } from 'react';

function useSetLinkImageType(
  imgUrl: string,
  setImgUrlType: React.Dispatch<React.SetStateAction<string>>,
) {
  // 정규식을 사용하여 확장자 추출
  const extension = imgUrl.match(/\/([a-zA-Z]+);base64,/) || ['DEFAULT'];

  useEffect(() => {
    switch (extension[0].toLowerCase()) {
      case '.png':
        setImgUrlType('image/png');
        break;
      case '.jpg':
        setImgUrlType('image/jpg');
        break;
      case '.jpeg':
        setImgUrlType('image/jpeg');
        break;
      case '.gif':
        setImgUrlType('image/gif');
        break;
      default:
        break;
    }
  }, []);
}

export default useSetLinkImageType;
