import MarkdownViewer from 'src/components/user-mobile/post/MarkDownViewer';
import styled from 'styled-components';

import { MenuContainer } from './FullMenu';

function FullBasic({ content }: { content: string }) {
  return (
    <BasicContainer>
      <MarkdownViewer content={content} />
    </BasicContainer>
  );
}
export default FullBasic;

const BasicContainer = styled(MenuContainer)`
  padding: 16px;
  padding-bottom: 40px;

  h2 {
    border-bottom: 1px solid #e5e5ec;
  }
`;
// const DetailImg = styled.img`
//   width: 100%;
//   flex-shrink: 0;
//   border-radius: 4px;
//   margin-top: 16px;
// `;

// const TmpDiv = styled.div`
//   margin-top: 16px;
//   height: 335px;
//   flex-shrink: 0;
//   border-radius: 4px;
//   background: #d9d9d9;
// `;
