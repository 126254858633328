import { IMissingInfo } from 'src/interfaces/user-mobile/missing.interface';
import { useEffect, useState } from 'react';
import { apiMissing } from 'src/apis/utils/missing';
import MissingPersonPopupBox from './MissingPersonPopupBox';

function MissingPersonPopup() {
  const [missingPeople, setMissingPeople] = useState<IMissingInfo[]>([]);

  useEffect(() => {
    apiMissing.getAll().then(res => {
      res.data.missingPersonDtos.forEach(
        (e: { id: number; name: string; popup: boolean }) => {
          if (e.popup)
            apiMissing.getPerson(e.id).then(res2 => {
              setMissingPeople(prev => [...prev, res2.data]);
            });
        },
      );
    });
  }, []);

  return (
    <>
      {missingPeople.map((info: IMissingInfo, i: number) => {
        return (
          <MissingPersonPopupBox
            info={info}
            i={i}
            total={missingPeople?.length}
          />
        );
      })}
    </>
  );
}

export default MissingPersonPopup;
