/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { apiInquiry } from 'src/apis/utils/inquiry';
import secret from 'src/assets/images/user-web/Secret.svg';
import * as S from 'src/components/user-web/inquiry/WebInquiryList/WebInquiryListStyle';
import { IWebInquiryList } from 'src/interfaces/user-mobile/inquiry.interface';
import { useMutation } from '@tanstack/react-query';
import WebInquiryModal from './WebInquiryModal';

function WebInquiryList({
  inquiryList,
  setCurrentPage,
  showModal,
  setShowModal,
  setInquiryInfo,
}: IWebInquiryList) {
  const [requestPassword, setRequestPassword] = useState('');
  const [requestInquireId, setRequestInquireId] = useState(0);
  const [requestUserId, setRequestUserId] = useState('');
  const mutationInfo = useMutation({
    mutationFn: ({
      inquiryId,
      userId,
      password,
    }: {
      inquiryId: number;
      userId: string;
      password: string;
    }) => apiInquiry.postInquiryInfoGetForUser(inquiryId, userId, password),
  });

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestPassword(event.currentTarget.value);
  };
  const onClickPageForm = () => {
    setCurrentPage('form');
  };

  const onClickCloseModal = () => {
    setShowModal(false);
  };

  const movePageDetail = () => {
    setCurrentPage('detail');
    setShowModal(false);
  };

  const fetchInquiryInfo = async (
    inquiryId: number,
    userId: string,
    password: string,
    isSecret: boolean,
  ) => {
    try {
      const response = await mutationInfo.mutateAsync({
        inquiryId,
        userId,
        password,
      });
      setInquiryInfo(response.data);
      movePageDetail();
    } catch (error) {
      if (isSecret) alert('비밀번호가 일치하지 않습니다.');
      else alert('문의 정보를 불러오는데 실패하였습니다.');
    }
  };

  const onClickInquiryElement = (event: React.MouseEvent<HTMLDivElement>) => {
    const { id } = event.currentTarget;
    const { isSecret, userId } = event.currentTarget.dataset;

    if (isSecret === 'true') {
      setShowModal(true);
      setRequestInquireId(Number(id));
      setRequestUserId(userId || '');
    } else {
      fetchInquiryInfo(Number(id), '', '', false);
    }
  };

  const onClickSubmitInfo = () => {
    fetchInquiryInfo(requestInquireId, requestUserId, requestPassword, true);
  };

  return (
    <S.BoardInquiryList>
      <S.TopContainer>
        <h1>문의사항</h1>
        <button onClick={onClickPageForm} type="button">
          문의사항 작성
        </button>
      </S.TopContainer>
      <S.InquiryList>
        <S.InquiryListHeader>
          <p>답변 여부</p>
          <p>제목</p>
          <p>작성자</p>
          <p>작성 시간</p>
        </S.InquiryListHeader>
        <S.InquiryListHeaderBlank />
        {inquiryList?.map(item => (
          <S.InquiryListElement
            id={item.id.toString()}
            key={item.id}
            onClick={onClickInquiryElement}
            data-user-id={item.userId}
            data-is-secret={item.isSecret}
            isAnswered={item.isAnswered}
          >
            <S.AnsweredText>
              {item.isAnswered ? '답변완료' : '미답변'}
            </S.AnsweredText>
            {item.isSecret ? (
              <h1>
                비밀글입니다.
                <img src={secret} alt="자물쇠 페이지" />
              </h1>
            ) : (
              <h1>{item.title}</h1>
            )}

            <h2>{item.userId}</h2>
            <h3>{item.createdDate.replace('T', ' ').slice(0, 16)}</h3>
          </S.InquiryListElement>
        ))}
      </S.InquiryList>
      {showModal && (
        <WebInquiryModal
          onChangePassword={onChangePassword}
          onClickCloseModal={onClickCloseModal}
          onClickSubmitInfo={onClickSubmitInfo}
        />
      )}
    </S.BoardInquiryList>
  );
}

export default WebInquiryList;
