import { useRef, useState } from 'react';
import { monthList } from 'src/assets/database/monthData';
import MonthBox from 'src/components/user-mobile/schedule/MonthBox';
import SelectMonth from 'src/components/user-mobile/schedule/SelectMonth';
import SelectYear from 'src/components/user-mobile/schedule/SelectYear';
import TopFixedBarSchedule from 'src/components/user-mobile/schedule/TopFixedBarSchedule';
import WebHeader from 'src/components/WebHeader';
import styled from 'styled-components';

function ScheduleHome() {
  const contentRefs = useRef<HTMLDivElement[]>([]); // 12개의 박스를 참조할 배열
  const [activeMonth, setActiveMonth] = useState<number>(1);

  const handleMonthSelect = (monthId: number) => {
    const selectedRef = contentRefs.current[monthId - 1];
    const offsetHeight = 50;

    const elementPosition =
      selectedRef.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offsetHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <TopFixedBarSchedule />
      <WebHeader />
      <ScheduleHomeContainer>
        <SelectYear />
        <SelectMonth
          activeMonth={activeMonth}
          setActiveMonth={setActiveMonth}
          onMonthSelect={handleMonthSelect}
          contentRefs={contentRefs}
        />
        <div>
          {monthList.map(e => (
            <MonthBox refs={contentRefs} e={e} key={e.id} />
          ))}
        </div>
      </ScheduleHomeContainer>
    </>
  );
}
export default ScheduleHome;

const ScheduleHomeContainer = styled.div`
  background: #ecebe3;
`;
