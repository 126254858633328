import { useEffect, useState } from 'react';

import { useOutletContext } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { apiLineUp } from 'src/apis/utils/concert';
import { ILineUp } from 'src/interfaces/user-mobile/concert.interface';
import {
  lineUpBlank,
  lineUpInfoBlank,
} from 'src/consts/user-mobile/blank.const';
import imageBlank from 'src/assets/images/user-mobile/GrayBlank.png';

import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';
import {
  BoardSetWidthInfoImageBoard,
  InfoImageBoard,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';
import TopFixedBarLineUp from 'src/components/user-mobile/concert/TopFixedBarLineUp';
import { useQuery } from '@tanstack/react-query';

function LineUp() {
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  // 미리 첫 번째 원소가 선택되어있어야 함
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dayList, setDayList] = useState([1]);

  const { data: lineUpList } = useQuery({
    queryKey: ['lineUpListData', festivalId],
    queryFn: () =>
      apiLineUp
        .getLineUps(festivalId)
        .then(response =>
          response.data.concertGetListDto.sort(
            (a: ILineUp, b: ILineUp) => a.day - b.day,
          ),
        )
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: [{ ...lineUpBlank }],
  });
  const { data: lineUpInfo } = useQuery({
    queryKey: ['lineUpInfo', lineUpList, currentIndex],
    queryFn: () =>
      apiLineUp
        .getLineUpInfo(lineUpList[currentIndex]?.concertId)
        .then(response => response.data)
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: { ...lineUpInfoBlank },
  });

  useEffect(() => {
    const tmpDayList = lineUpList?.map((item: ILineUp) => item.day);
    setDayList(tmpDayList?.sort((a: number, b: number) => a - b));
  }, [lineUpList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log('currentIndex : lineUpInfo', currentIndex, lineUpInfo);
  return (
    <Wrapper>
      <BoardSetWidthInfoImageBoard>
        <TopFixedBarLineUp
          categoryInfo="공연 라인업"
          dayList={dayList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
        <TopFixedBarBlank />
        {dayList?.length > 1 && <TopFixedBarBlank style={{ height: '46px' }} />}
        <InfoImageBoard>
          {lineUpInfo?.images.map((item: IImageInfo) => (
            <img
              key={item.imageId.toString()}
              id={item.imageId.toString()}
              src={item.image || imageBlank}
              alt="공연 이미지"
            />
          ))}
        </InfoImageBoard>
      </BoardSetWidthInfoImageBoard>
    </Wrapper>
  );
}

export default LineUp;
