import { useEffect } from 'react';

function useStampCheck() {
  useEffect(() => {
    const { pathname } = window.location;
    if (
      pathname !== '/stamp/start' &&
      pathname !== '/stamp/login' &&
      pathname !== '/stamp/sign_up' &&
      pathname.includes('/stamp') &&
      (!localStorage.getItem('uuid') || !localStorage.getItem('stampId'))
    ) {
      alert('이용자 정보가 없습니다.\n스탬프투어 시작화면으로 돌아갑니다');
      window.location.href = '/stamp/start';
    }
  }, []);
}

export default useStampCheck;
