import { instance } from '..';

export const apiLostItem = {
  getAll: () =>
    instance.get('/lostItem', {
      headers: {
        withcredentials: true,
      },
    }),
};
