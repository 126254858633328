import { useEffect } from 'react';

import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';
import TopFixedBarInquiry from 'src/components/user-mobile/inquiry/TopFixedBarInquiry';
import InquiryListBoard from 'src/components/user-mobile/inquiry/InquiryListBoard';

function InquiryList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // 스크롤 오류 때문에 일단 이렇게 강제로 올려놈

  return (
    <Wrapper>
      <TopFixedBarInquiry text="문의사항" />
      <TopFixedBarBlank />
      <InquiryListBoard />
    </Wrapper>
  );
}

export default InquiryList;
