import { EXCEPT_FOR_WEB_BOARD } from 'src/consts/user-web.const';
import { FlexBox, GridBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export const BoardInquiryList = styled(FlexBox)`
  width: 820px;
  margin-top: 64px;
  margin-bottom: 120px;

  flex-direction: column;
`;
export const TopContainer = styled(FlexBox)`
  width: 100%;
  margin-bottom: 20px;
  justify-content: space-between;
  > h1 {
    color: #111;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.6px;
  }
  > button {
    border-radius: 8px;
    background: #054c95;
    color: #fbf7f3;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    display: inline-flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;
export const InquiryList = styled(FlexBox)`
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-height: ${window.innerHeight -
  EXCEPT_FOR_WEB_BOARD -
  120 -
  64 -
  34 -
  20}px;
  max-height: 50vh;
  overflow-y: auto;
`;
export const InquiryListHeaderBlank = styled(FlexBox)`
  position: relative;
  width: 100%;
  min-height: 53px;
  border-bottom: 1px solid var(--Font-03_Gray, #505050);
`;
export const InquiryListHeader = styled(GridBox)`
  position: sticky;
  z-index: 10;
  top: 0;
  margin-bottom: -52px;
  width: 100%;
  min-height: 52px; //grid인 경우에는 단순 height설정 시 줄어들 수가 있음 (min-height로 설정)

  background-color: #fbf7f3;
  grid-template-columns: 1fr 6fr 1fr 2fr;
  > p {
    color: #111;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  > p:nth-of-type(2) {
    justify-content: flex-start;
    padding-left: 20px;
  }
`;

export const AnsweredText = styled.p``;
export const InquiryListElement = styled(GridBox)<{ isAnswered: boolean }>`
  width: 100%;
  min-height: 52px;

  border-bottom: 1px solid var(--Font-03_Gray, #505050);
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr 2fr;
  > ${AnsweredText}, > h1,
  > h2,
  > h3 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.375px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  > ${AnsweredText} {
    color: ${({ isAnswered }) =>
      isAnswered ? '#054C95' : 'var(--Font-03_Gray, #505050)'};
  }
  > h1 {
    padding-left: 20px;
    color: #111;
    justify-content: flex-start;
    > img {
      margin-left: 4px;
    }
  }
  > h2 {
    color: #111;
    font-weight: 400;
  }
  > h3 {
    color: var(--Font-03_Gray, #505050);
    font-weight: 400;
  }
`;
