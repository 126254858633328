// import home from 'src/assets/images/web/HomeImage.webp';
import { FlexBox } from 'src/styles/OverviewStyle';
import { ImageBoardWeb } from 'src/styles/user-web/OverviewStyle';
import styled from 'styled-components';
import blankImage from 'src/assets/images/user-web/BlankImage.png';
import { IMG_BUCKET_PC } from 'src/consts/user-web.const';
import { STAMP_FONT } from 'src/assets/database/stampData';

function WebInstroduction() {
  const BOX_WIDTH1 = 920 * 2;
  const BOX_WIDTH2 = 1116 * 2;
  const BOX_WIDTH3 = 834 * 2;
  return (
    <BoardIntroduction>
      <ImageBoardWeb>
        <img
          src={`${IMG_BUCKET_PC}HomeImage.webp` || blankImage}
          alt="홈 이미지"
        />
      </ImageBoardWeb>
      <BoxIntroduction width={BOX_WIDTH1}>
        <iframe
          width="1116"
          height="628"
          src="https://www.youtube.com/embed/bPE4LpZ4UZQ?si=nW50P9c87v6CUpqL"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </BoxIntroduction>
      <BoxIntroduction width={BOX_WIDTH1}>
        <h1>
          <span style={{ color: '#0064CB' }}>레저스포츠</span>와&nbsp;
          <span style={{ color: '#FF5F30' }}>문화예술</span>이 융합된
          <p>2024 제주레저힐링축제</p>
        </h1>

        <h2>
          <span style={{ fontWeight: '800' }}>
            레저스포츠, 감성 공연, 참여형 콘텐츠
          </span>
          까지 한 곳에서 한꺼번에 즐길 수 있는 제주만의 색다른 축제
        </h2>
        <img
          src={`${IMG_BUCKET_PC}HomeBoxImage1.webp` || blankImage}
          alt="샘플 이미지"
        />
      </BoxIntroduction>

      <BoxIntroduction width={BOX_WIDTH2}>
        <h1>
          흥미진진한 다양한 레저콘텐츠! <br />
        </h1>
        <h2>
          짜릿한 익스트림 레저스포츠부터 아이들과 함께 즐길 레저콘텐츠까지
        </h2>
        <img
          src={`${IMG_BUCKET_PC}HomeBoxImage2.webp` || blankImage}
          alt="샘플 이미지"
        />
      </BoxIntroduction>
      <BoxIntroduction width={BOX_WIDTH3}>
        <h1>제주의 색깔이 물씬 묻어나는 특색있는 공연을 함께 즐겨요</h1>
        <h2>
          관객이 함께하는 신나는 인터렉티브 공연부터 감성넘치는 콘서트까지
        </h2>
        <img
          style={{ marginBottom: '16px' }}
          src={`${IMG_BUCKET_PC}HomeBoxImage3.webp` || blankImage}
          alt="샘플 이미지"
        />
      </BoxIntroduction>
    </BoardIntroduction>
  );
}
export default WebInstroduction;

const BoardIntroduction = styled(FlexBox)`
  width: 100%;
  min-height: 100vh;

  margin-bottom: 208px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const BoxIntroduction = styled(FlexBox)<{ width: number }>`
  width: ${props => props.width / 2}px;

  margin-top: 108px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > h1,
  > h1 > span {
    margin-bottom: 12px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Jalnan;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    > p {
      color: #ff7d00;
      text-align: center;
      font-family: ${STAMP_FONT};
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 114.286% */
    }
  }
  > h2,
  > h2 > span {
    margin-bottom: 40px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
  }

  > img {
    width: 100%;
    height: auto;
  }
`;
