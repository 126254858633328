import { Scanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';
import { apiStampUser } from 'src/apis/utils/stamp';
import { IStampUserMissionInfo } from 'src/interfaces/user-mobile/stamp.interface';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

interface IUserQrScanner {
  uuid: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMissionId: React.Dispatch<React.SetStateAction<number>>;
}
function UserQrScanner({ uuid, setShowModal, setMissionId }: IUserQrScanner) {
  const [key, setKey] = useState(0);
  // useQuery() 필요 없음

  const onScanSuccess = (missionId: number) => {
    apiStampUser.getStampUser(uuid).then(response => {
      const { userMissionInfoGetDtos } =
        response.data.userMissionInfoGetListDto;
      const index = userMissionInfoGetDtos?.findIndex(
        (item: IStampUserMissionInfo) => item.missionId === missionId,
      );
      const { userMissionId } = userMissionInfoGetDtos[index];
      const { clear } = userMissionInfoGetDtos[index];

      if (clear) return alert('이미 완료한 미션입니다.');
      return apiStampUser
        .patchStampUser(uuid, userMissionId)
        .then(() => {
          setShowModal(true);
          setMissionId(missionId);
        })
        .catch(() => alert('qr인식에 실패하였습니다.'));
    });
    setKey(prev => prev + 1);
  };

  return (
    <QrContainer>
      <StyledScanner
        key={key}
        components={{ finder: false }}
        onScan={result => {
          const missionId = Number(result[0].rawValue.split('/').pop());
          onScanSuccess(missionId);
        }}
        scanDelay={1000}
      />
    </QrContainer>
  );
}
export default UserQrScanner;

const QrContainer = styled(FlexBox)`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1; /* 정사각형을 유지합니다 */
  padding: 8px;
  background-color: #fff;
`;

const StyledScanner = styled(Scanner)`
  width: 100%;
  height: 100%;
`;
