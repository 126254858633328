import translate from 'translate';

const getTranslateString = async (text: string) => {
  const language = localStorage.getItem('language') || 'ko';

  translate.engine = 'google'; // "google", "yandex", "libre", "deepl"
  //   translate.key = process.env.DEEPL_KEY;

  const translatedText = await translate(text, {
    from: 'ko',
    to: language,
  });

  return translatedText;
};

export default getTranslateString;
