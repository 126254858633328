import { useState } from 'react';
import { FlexBox, FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import WebProgramLeisure from './WebProgramLeisure';
import WebProgramHealing from './WebProgramHealing';

function WebProgram() {
  const [currentTag, setCurrentTag] = useState('leisure');
  const onClickBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    setCurrentTag(value);
  };
  return (
    <ContainerProgram>
      <LeisureHealingBar>
        <h1>콘텐츠 ・ 프로그램</h1>
        <h2>레저 스포츠와 문화 예술의 융합</h2>
        <BtnBox>
          <Btn
            onClick={onClickBtn}
            selected={currentTag === 'leisure'}
            value="leisure"
            type="button"
          >
            레저 스포츠
          </Btn>
          <Btn
            onClick={onClickBtn}
            selected={currentTag === 'healing'}
            value="healing"
            type="button"
          >
            힐링 체험
          </Btn>
        </BtnBox>
      </LeisureHealingBar>
      {currentTag === 'leisure' && <WebProgramLeisure />}
      {currentTag === 'healing' && <WebProgramHealing />}
    </ContainerProgram>
  );
}
export default WebProgram;

const ContainerProgram = styled(FlexBoxFullWidth)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const LeisureHealingBar = styled(FlexBoxFullWidth)`
  width: 100%;
  height: 254px;
  flex-shrink: 0;
  background-color: white;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1 {
    margin-bottom: 4px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Jalnan;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.08px;
  }
  > h2 {
    margin-bottom: 16px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.045px;
  }
`;

const BtnBox = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const Btn = styled.button<{ selected: boolean }>`
  width: 160px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  ${props =>
    props.selected
      ? `
  border: 1px solid #F08218;
  background: var(--white, #FFF);
  color: #F08218;
  `
      : `
  border: 1px solid var(--Line-Regular_Color, #E5E5EC);
  background: var(--white, #FFF);
  color: var(--Font-03_Gray, #505050);
  `}
  font-family:TTTogether;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.04px;
`;
