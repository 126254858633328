import axios from 'axios';

// export const instance = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });

export const instance = axios.create({
  baseURL: window.location.hostname.includes('localhost')
    ? process.env.REACT_APP_API_TEST
    : process.env.REACT_APP_API,
});

export const manageInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    const requestConfig = { ...config };
    requestConfig.headers.withCredentials = true;
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`;
    }
    return requestConfig;
  },
  error => Promise.reject(error),
);

// 응답 인터셉터
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // 401 Unauthorized 응답을 받으면 로그인 페이지로 리디렉트
      alert('관리자 로그인이 필요합니다.');
      window.location.href = '/biz_login';
    }
    return Promise.reject(error);
  },
);
