import { useEffect, useState } from 'react';
import { apiFestival } from 'src/apis/utils/basic';
import { ContainerFooter } from 'src/styles/user-mobile/FooterStyle';
import styled from 'styled-components';

function FooterUniv({ festivalId }: { festivalId: number }) {
  const [festivalInfo, setFestivalInfo] = useState({
    id: 0,
    name: '',
    subAddress: '',
    latitude: 0,
    longitude: 0,
  });

  const secondText = '디지털 축제 가이드';
  const secondTextSpan = '페스티맵';
  const thirdText =
    'Contact: festimaap@gmail.com\nTel: 070-8027-4852\nCopyright ⓒ WABA ALL RIGHTS RESERVED.';

  useEffect(() => {
    apiFestival
      .getFestival(festivalId)
      .then(response => setFestivalInfo(response.data))
      .catch(() => {
        // console.log(err, item.name);
      });
  }, [festivalId]);

  return (
    <StyledContainerFooter>
      <h1>{festivalInfo.name}</h1>
      <h2>
        {secondText}&nbsp;
        <span>{secondTextSpan}</span>
      </h2>
      <h3>{thirdText}</h3>
    </StyledContainerFooter>
  );
}
export default FooterUniv;

const StyledContainerFooter = styled(ContainerFooter)`
  gap: 4px;
  h1 {
    color: var(--Font-03_Gray, #505050);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.45px;
  }
  h2 {
    color: var(--Font-03_Gray, #505050);

    /* PC/Body/KR/B3_KR_Rg */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    span {
      color: var(--Font-03_Gray, #505050);

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.35px;
    }
  }
  h3 {
    color: var(--Font-04_Gray, #767676);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;
    white-space: pre-line;
  }
`;
