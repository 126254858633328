import QRCode from 'react-qr-code';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

function QrMaker({ value }: { value: string }) {
  return (
    <QrContainer>
      <StyledQRCode level="Q" size={256} value={value} />
    </QrContainer>
  );
}

export default QrMaker;

const QrContainer = styled(FlexBox)`
  width: 248px;
  height: 248px;
  border-radius: 8px;
  padding: 8px;
  border: 1.9px solid #000;
  margin-bottom: 12px;
`;

const StyledQRCode = styled(QRCode)`
  width: 100%;
  height: 100%;
`;
