import styled from 'styled-components';

export const Box = styled.img`
  margin-top: 12px;
  width: 166px;
  height: 224px;
  border-radius: 12px;

  border: 0;
  box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.24);

  cursor: pointer;
`;

export const Container = styled.div`
  width: 380px;
  justify-content: space-between;
  padding: 56px 12px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  > div:nth-child(2n) {
    // > : 자식선택자로 바로 아래 자식 요소 선택 시 사용
    margin-left: 12px;
  }
  @media screen and (max-width: 369px) {
    justify-content: center;
    > div:nth-child(2n) {
      // > : 자식선택자로 바로 아래 자식 요소 선택 시 사용
      margin-left: 4px;
    }
  }
`;

export const Tag = styled.div`
  position: relative;
  bottom: 64px;
  width: 166px;
  height: 64px;
  flex-shrink: 0;
  background: #fff;
  color: #111;
  padding: 8px 0 0 16px;

  border-radius: 0px 0px 12px 12px;

  box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.24);

  /* body1 */

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

export const Category = styled.div`
  color: #f23d37;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`;

export const Name = styled.div`
  color: #111;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

export const PopUpBar = styled.div`
  position: fixed;
  bottom: 16px;

  width: 320px;
  height: 88px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #222;
  border: 1px solid #555;

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 16px;
  h1 {
    color: #fff;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  h2 {
    color: #fff;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    span {
      font-weight: 600;
    }
  }
  img {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
`;

export const EmptyText = styled.p`
  width: 100%;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
`;
