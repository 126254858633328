import cauOverlayImg from 'src/assets/images/map/overlay/cauOverlay.webp';
import khuOverlayImg from 'src/assets/images/map/overlay/khuOverlay.webp';
import jejulhfestivalOverlayImg from 'src/assets/images/map/overlay/jejulhfestivalOverlay.webp';
import expoOverlayImg from 'src/assets/images/map/overlay/expo.webp';
import { IS_ONLY_MOBILE } from 'src/consts/config.const';
import getSubAddress from '../getSubAddress';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function makeOverlay(mapRef: any) {
  const cauBounds = new naver.maps.LatLngBounds(
    new naver.maps.LatLng(37.50160074857147, 126.95373077285015), // sw
    new naver.maps.LatLng(37.507146090792496, 126.95969965964029), // en
  );
  const khuBounds = new naver.maps.LatLngBounds(
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    new naver.maps.LatLng(37.235009623555095, 127.07569218952227), // sw
    new naver.maps.LatLng(37.24911010011597, 127.09002193461744), // en
  );
  const jejulhfestivalBounds = new naver.maps.LatLngBounds(
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    new naver.maps.LatLng(33.54266434796413, 126.66844585589458), // sw
    new naver.maps.LatLng(33.54414864765099, 126.6711407421144), // en
  );
  const expoBounds = new naver.maps.LatLngBounds(
    new naver.maps.LatLng(37.38402955621195, 126.64103183668976), // sw
    new naver.maps.LatLng(37.392534080340894, 126.65273573683855), // en
  );
  const subAddress = getSubAddress(IS_ONLY_MOBILE);

  const cauOverlay = new naver.maps.GroundOverlay(cauOverlayImg, cauBounds, {
    opacity: 1,
    clickable: false,
  });

  const khuOverlay = new naver.maps.GroundOverlay(khuOverlayImg, khuBounds, {
    opacity: 1,
    clickable: false,
  });
  const jejulhfestivalOverlay = new naver.maps.GroundOverlay(
    jejulhfestivalOverlayImg,
    jejulhfestivalBounds,
    {
      opacity: 1,
      clickable: false,
    },
  );

  const expoOverlay = new naver.maps.GroundOverlay(expoOverlayImg, expoBounds, {
    opacity: 1,
    clickable: false,
  });

  if (subAddress === 'cau') cauOverlay.setMap(mapRef.current);
  else if (subAddress === 'adelante') khuOverlay.setMap(mapRef.current);
  else if (subAddress === 'jejulhfestival')
    jejulhfestivalOverlay.setMap(mapRef.current);
  else if (subAddress === 'festimapexpo') expoOverlay.setMap(mapRef.current);
}

export default makeOverlay;
