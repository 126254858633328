import startBackground from 'src/assets/images/user-mobile/stamp/StartBackground.webp';
import background from 'src/assets/images/user-mobile/stamp/Background.webp';
import logo from 'src/assets/images/user-mobile/stamp/StartLogo.svg';
import cautionBoard from 'src/assets/images/user-mobile/stamp/CautionBoard.webp';

import stampStartthumbnail from 'src/assets/images/user-mobile/stamp/StampStartThumbnail.webp';
import fleaMarket from 'src/assets/images/user-mobile/stamp/FleaMarket.webp';
import honeySleep from 'src/assets/images/user-mobile/stamp/HoneySleep.webp';
import playGround from 'src/assets/images/user-mobile/stamp/PlayGround.webp';
import busking from 'src/assets/images/user-mobile/stamp/Busking.webp';
import edmWaterWar from 'src/assets/images/user-mobile/stamp/EdmWaterWar.webp';
import smallBookStore from 'src/assets/images/user-mobile/stamp/SmallBookStore.webp';

import fleaMarketNot from 'src/assets/images/user-mobile/stamp/FleaMarketNotClear.webp';
import honeySleepNot from 'src/assets/images/user-mobile/stamp/HoneySleepNotClear.webp';
import playGroundNot from 'src/assets/images/user-mobile/stamp/PlayGroundNotClear.webp';
import buskingNot from 'src/assets/images/user-mobile/stamp/BuskingNotClear.webp';
import edmWaterWarNot from 'src/assets/images/user-mobile/stamp/EdmWaterWarNotClear.webp';
import smallBookStoreNot from 'src/assets/images/user-mobile/stamp/SmallBookStoreNotClear.webp';

import detail1 from 'src/assets/images/user-mobile/stamp/StampDetail1.webp';
import detail2 from 'src/assets/images/user-mobile/stamp/StampDetail2.webp';
import detail3 from 'src/assets/images/user-mobile/stamp/StampDetail3.webp';
import detail4 from 'src/assets/images/user-mobile/stamp/StampDetail4.webp';
import detail5 from 'src/assets/images/user-mobile/stamp/StampDetail5.webp';
import detail6 from 'src/assets/images/user-mobile/stamp/StampDetail6.webp';

import guide0 from 'src/assets/images/user-mobile/stamp/Guide0.webp';
import guide1 from 'src/assets/images/user-mobile/stamp/Guide1.webp';
import guide2 from 'src/assets/images/user-mobile/stamp/Guide2.webp';
import guide3 from 'src/assets/images/user-mobile/stamp/Guide3.webp';
import guide4 from 'src/assets/images/user-mobile/stamp/Guide4.webp';

import complete from 'src/assets/images/user-mobile/stamp/StampComplete.webp';

export const FESTIVAL_NAME = '아델란테(Adelante) 축제';
export const STAMP_TITLE = '아델란테 스탬프투어';
export const STAMP_DOING = '투어';
export const STAMP_USER_JOB = '참여자';

export const STAMP_START_BACKGROUND = startBackground;
export const STAMP_BACKGROUND = background;
export const STAMP_START_THUMBNAIL = stampStartthumbnail;
export const STAMP_START_LOGO = logo;
export const STAMP_START_P =
  '축제장 곳곳에 있는 미션들을 수행하고\n특별한 경품을 획득하자!';
export const STAMP_CAUTION_BOARD = cautionBoard;
export const STAMP_FONT = 'TAEBAEKfont';

export const STAMP_COMPLETE_HEADER = '스탬프 투어';
export const STAMP_COMPLETE_PLACE = `${STAMP_TITLE} 운영부스`;
export const STAMP_COMPLETE_THUMBNAIL = complete;

export const STAMP_INFO_BOOTH_ID = 16;
export const STMAP_SIGNUP_CAUTION_FOOTER = [
  '﹒개인정보 수집에 동의하지 않을 경우,\n이벤트에 참여하실 수 없습니다.',
  '﹒상품은 스탬프 투어 완료 후, 추첨을 통해 지급됩니다.',
];

export const STAMP_SIGNUP_CAUTION_MODAL = `가. 수집 및 이용 목적
  - 페스티맵에서 실시하는 “${STAMP_TITLE}"에 필요한 사항(참여자 중복 참여 방지, 참여 인원 확인 등)에 대하여 스탬프 투어 서비스 제공을 위하여 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다.

나. 수집 및 이용 항목
  - 필수항목 : 성명, 전화번호, 참여인원
  
  개인정보 수집 동의 항목 기입 시 페스티맵이 위와 같이 개인정보 필수항목을 수집ㆍ이용하는 것에 동의하는 것으로 간주합니다.

다. 개인정보의 보유 및 이용 기간
  - 참여자의 개인정보 수집ㆍ이용에 관한 동의일로부터 ${FESTIVAL_NAME} 종료 시까지 위 이용목적을 위하여 보유 및 이용하게 됩니다. 단, 축제 종료 후에는 경품 증정, 민원처리, 분쟁해결 및 법령상 의무이행 등을 위하여 1년간 보유하게 됩니다.

라. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
  - 위 개인정보 중 필수정보의 수집ㆍ이용에 관한 동의는 “${STAMP_TITLE}" 진행을 위해 필수적이므로, 위 사항에 동의하셔야만 프로그램 체험 및 상품 수령이 가능합니다.`;

export const STAMP_PRIZE_TEXT = `스탬프 투어 참여해, 축제도 즐기고\n경품도 받아가세요. 총 6개의 스탬프를\n모으면 자동으로 이벤트 참여 완료!`;

export const stampList = [
  {
    missionId: 1,
    boothId: 389,
    title: 'EDM 워터워',
    content: 'EDM 워터워 공연 참여하기',
    place: '워터 스테이지',
    time: '프리뷰 (14:30 - 15:00)\n메인 공연 (16:00 - 17:00)',
    clearedThumbnail: edmWaterWar,
    notClearedThumbnail: edmWaterWarNot,
    detailThumbnail: detail1,
  },
  {
    missionId: 2,
    boothId: 389,
    title: '사이사이 버스킹',
    content: '사이사이버스킹 공연 관람하기',
    place: '워터 스테이지',
    time: '1회차 (14:00 - 14:30)\n2회차 (15:00 - 15:30)\n3회차 (17:00 - 17:30)',
    clearedThumbnail: busking,
    notClearedThumbnail: buskingNot,
    detailThumbnail: detail2,
  },
  {
    missionId: 3,
    boothId: 387,
    title: '함덕 놀이공방',
    content: '놀이공방 체험 참여하기',
    place: '함덕해변 공방존',
    time: '11:00 - 18:00',
    clearedThumbnail: playGround,
    notClearedThumbnail: playGroundNot,
    detailThumbnail: detail3,
  },
  {
    missionId: 4,
    boothId: 390,
    title: '함덕 플리마켓',
    content: '함덕플리마켓 구경하기\n*물품 구매는 선택사항입니다.',
    place: '함덕해변 플리마켓존',
    time: '11:00 - 21:00',
    clearedThumbnail: fleaMarket,
    notClearedThumbnail: fleaMarketNot,
    detailThumbnail: detail4,
  },
  {
    missionId: 5,
    boothId: 391,
    title: '꿀잠터',
    content: '꿀잠터 이용하기',
    place: '함덕해변 힐링스페이스존',
    time: '11:00 - 18:00',
    clearedThumbnail: honeySleep,
    notClearedThumbnail: honeySleepNot,
    detailThumbnail: detail5,
  },
  {
    missionId: 6,
    boothId: 391,
    title: '해변가 작은 정원·책방',
    content: '정원·책방 이용하기',
    place: '함덕해변 힐링스페이스존',
    time: '11:00 - 18:00',
    clearedThumbnail: smallBookStore,
    notClearedThumbnail: smallBookStoreNot,
    detailThumbnail: detail6,
  },
];

export const stampGuideList = [
  {
    thumbnail: guide0,
    title: '함덕 미션탐험 시작',
    content:
      '함덕 해수욕장 내\n6개 부스를 방문하고, 미션을 해결하여\n특별한 상품을 받아보세요.',
    ps: '',
  },
  {
    thumbnail: guide1,
    title: '탐험 미션 선택',
    content: '스탬프 판에 있는 6개의 미션 중\n하나를 선택하세요.',
    ps: '',
  },
  {
    thumbnail: guide2,
    title: '탐험 미션 수행',
    content: '미션장소와 미션내용을 확인하세요.',
    ps: 'TIP. 하단 지도버튼을 클릭하면, 상세 위치를\n확인할 수 있습니다.',
  },
  {
    thumbnail: guide3,
    title: '미션 완료 인증',
    content:
      '**미션 인증하기** 버튼을 클릭해 부스에 설치된\nQR 코드를 촬영해 완료 인증을 하세요.\n6개 미션을 완료하면, 상품 수령이 가능합니다.',
    ps: '',
  },
  {
    thumbnail: guide4,
    title: '탐험 미션 선택',
    content:
      '함덕미션탐험 부스에서 상품을 수령하세요.\n*지도를 통해 상품 수령 위치 확인',
    ps: '주의사항 : 상품이 소진 될 경우, 조기 마감될 수 있습니다.',
  },
];

export const stampPrizeList = [
  { id: 1, prize: 'BOSE 블루투스 스피커 & 헤드셋' },
  { id: 2, prize: '배달의민족 5만원권' },
  { id: 3, prize: '영화 2인 관람권' },
  { id: 4, prize: '굽네치킨 고추바사삭+콜라 기프티콘' },
];
