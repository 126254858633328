import { useNavigate } from 'react-router-dom';
import { BkBtn, TopFixedBar } from 'src/styles/user-mobile/TopFixedBarStyle';
import { STAMP_FONT, STAMP_TITLE } from 'src/assets/database/stampData';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import refresh from 'src/assets/images/user-mobile/stamp/Refresh.svg';
import prize from 'src/assets/images/user-mobile/stamp/Prize.svg';
import styled from 'styled-components';
import guide from 'src/assets/images/user-mobile/stamp/Guide.svg';
import {
  IS_STAMP_GUIDE,
  IS_STAMP_REFETCH_BUTTON,
} from 'src/consts/config.const';

TopFixedBarStamp.defaultProps = {
  isStart: false,
  text: undefined,
  navigateText: undefined,
  fetchMissionInfo: undefined, // 기본값으로 undefined를 설정
};

interface IProps {
  isStart?: boolean;
  text?: string;
  navigateText?: string;
  fetchMissionInfo?: () => void;
}

function TopFixedBarStamp({
  isStart,
  text,
  navigateText,
  fetchMissionInfo,
}: IProps) {
  const navigate = useNavigate();

  return (
    <StyledTopFixedBar isGuide={text === STAMP_TITLE}>
      <BkBtn
        style={{ marginLeft: '14px', marginRight: '0' }}
        onClick={() => (navigateText ? navigate(navigateText) : navigate(-1))}
        src={bkBtn2}
      />
      {text && (
        <h1>
          {text}
          {IS_STAMP_GUIDE && (
            <img onClick={() => navigate('/stamp/guide')} src={guide} alt="" />
          )}
        </h1>
      )}
      {!isStart && IS_STAMP_REFETCH_BUTTON && fetchMissionInfo && (
        <RightButton onClick={() => fetchMissionInfo()} src={refresh} alt="" />
      )}
      {!isStart && !IS_STAMP_REFETCH_BUTTON && (
        <RightButton
          onClick={() => navigate('/stamp/prize')}
          src={prize}
          alt=""
        />
      )}
    </StyledTopFixedBar>
  );
}

export default TopFixedBarStamp;

const StyledTopFixedBar = styled(TopFixedBar)<{ isGuide: boolean }>`
  h1 {
    position: absolute;
    top: 50%;
    left: ${({ isGuide }) => (isGuide ? `calc(50% + 12px);` : `50%;`)};
    transform: translate(-50%, -50%);

    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: ${STAMP_FONT};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */

    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      display: ${({ isGuide }) => (isGuide ? `` : `none`)};
      margin-left: 4px;
      cursor: pointer;
    }
  }
`;

const RightButton = styled.img`
  margin-right: 14px;
  cursor: pointer;
`;
