import { useTheme } from 'styled-components';

import { inquiryInfoBlank } from 'src/consts/user-mobile/blank.const';
import { useLocation } from 'react-router-dom';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';

import { IInquiryInfo } from 'src/interfaces/user-mobile/inquiry.interface';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';

import * as S from './InquiryDetailBoardStyle';

function InquiryDetailBoard() {
  const location = useLocation();
  const inquiryInfo: IInquiryInfo = location.state || inquiryInfoBlank;

  const theme = useTheme();
  return (
    <S.InquiryContainer>
      <TopFixedBarBlank />
      <Flex />
      <S.MainTextBoard fontColor={theme.colors.fontColor}>
        {inquiryInfo.isAnswered ? <h6>답변완료</h6> : <h6>미답변</h6>}
        <h1>{inquiryInfo.title}</h1>
        <h2>
          {inquiryInfo.userId} &nbsp;
          {inquiryInfo?.createdDate.slice(0, 19).replace('T', ' ')}
        </h2>
        <h3>{inquiryInfo.content}</h3>
      </S.MainTextBoard>

      {inquiryInfo.isAnswered && (
        <S.AnswerTextBoard>
          <h1>답변</h1>
          {inquiryInfo.answer}
        </S.AnswerTextBoard>
      )}
    </S.InquiryContainer>
  );
}

export default InquiryDetailBoard;
