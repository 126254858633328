import React, { useEffect } from 'react';
import { apiUniv } from 'src/apis/utils/overview';

function useGetFestivalId(
  subAddress: string,
  festivalId: number,
  setFestivalId: React.Dispatch<React.SetStateAction<number>>,
) {
  useEffect(() => {
    if (
      // 추출된 서브도메인이 메인도메인이 아닌 경우 (이 때 축제id Get)
      !subAddress.includes('localhost') &&
      festivalId === 0
    ) {
      apiUniv
        .getUniv(subAddress)
        .then(response => {
          setFestivalId(response.data.id);
          // console.log('festivalId:', response.data.id);
        })
        .catch(() => {
          // console.log(err, item.name);
        });
    }
  }, []);
}

export default useGetFestivalId;
