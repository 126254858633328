import blankImage from 'src/assets/images/user-web/BlankImage.png';
import { IMG_BUCKET_PC } from 'src/consts/user-web.const';
import { FlexBox } from 'src/styles/OverviewStyle';
import { Shrinked2xImage } from 'src/styles/user-web/OverviewStyle';
import styled from 'styled-components';

function WebGuide() {
  const INIT_WIDTH_COMPOSITION = 1117 * 2;
  const INIT_WIDTH = 1117 * 2;

  return (
    <BoardGuide>
      <ImageBoardGuide>
        <h1 style={{ marginBottom: '20px' }}>행사 구성</h1>
        <Shrinked2xImage
          width={INIT_WIDTH_COMPOSITION}
          src={`${IMG_BUCKET_PC}Composition.webp` || blankImage}
          alt="구성 이미지"
        />
      </ImageBoardGuide>

      <ImageBoardGuide>
        <h1 style={{ marginBottom: '2px' }}>메인공연</h1>
        <h2 style={{ marginBottom: '16px' }}>장소 : 썬셋 스테이지</h2>
        <Shrinked2xImage
          width={INIT_WIDTH}
          src={`${IMG_BUCKET_PC}LineUp.webp` || blankImage}
          alt="공연 이미지"
        />
      </ImageBoardGuide>

      <ImageBoardGuide>
        <h1 style={{ marginBottom: '20px' }}>일차별 타임테이블</h1>
        <Shrinked2xImage
          width={INIT_WIDTH}
          src={`${IMG_BUCKET_PC}DayTimeTable.webp` || blankImage}
          alt="일차테이블 이미지"
        />
      </ImageBoardGuide>
    </BoardGuide>
  );
}
export default WebGuide;

const BoardGuide = styled(FlexBox)`
  width: 100vw;
  min-height: 100vh;
  margin-top: 70px;
  margin-bottom: 160px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
`;

const ImageBoardGuide = styled(FlexBox)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > h1 {
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Jalnan;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.08px;
  }
  > h2 {
    color: #e25228;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.04px;
  }
`;
