import styled from 'styled-components';
import KUlogo from 'src/assets/images/user-web/KUlogo.png';

function WebHeader() {
  return (
    <HeaderContainer>
      <Logo src={KUlogo} alt="캠퍼스타운 로고" />
      <MenuList>
        <li>사업단 소개</li>
        <li>입주기업</li>
        <li>공간 소개</li>
        <li>프로그램 일정</li>
        <li>성과 공지</li>
      </MenuList>
    </HeaderContainer>
  );
}

export default WebHeader;

const HeaderContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;

  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  z-index: 1000;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

const Logo = styled.img`
  display: flex;
  width: 251px;
  padding: 2px 0.272px 2px 0px;
  justify-content: center;
  align-items: flex-end;
  gap: 8.146px;
  margin-left: 116px;
`;

const MenuList = styled.ul`
  position: absolute;
  right: 116px;
  display: flex;
  align-items: center;
  gap: 32px;

  li {
    color: var(--Font-03_Gray, #505050);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;

    &:hover {
      cursor: pointer;
    }
  }
`;
