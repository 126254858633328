import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { monthList } from 'src/assets/database/monthData';
import styled from 'styled-components';

interface SelectMonthProps {
  activeMonth: number;
  setActiveMonth: Dispatch<SetStateAction<number>>;
  onMonthSelect: (monthId: number) => void;
  contentRefs: MutableRefObject<HTMLDivElement[]>;
}

function SelectMonth({
  activeMonth,
  setActiveMonth,
  onMonthSelect,
  contentRefs,
}: SelectMonthProps) {
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const selectMonthRef = useRef<HTMLDivElement | null>(null);

  const handleMonthSelectX = (monthId: number) => {
    const selectedRef = refs.current[monthId - 1];
    const monthListBox = selectMonthRef.current;

    if (selectedRef && monthListBox) {
      const selectedRect = selectedRef.getBoundingClientRect();
      const monthListBoxRect = monthListBox.getBoundingClientRect();

      // 선택된 월의 가운데 위치를 계산
      const offset =
        selectedRect.left -
        monthListBoxRect.left +
        selectedRect.width / 2 -
        monthListBoxRect.width / 2;

      // 부드럽게 중앙으로 스크롤
      monthListBox.scrollTo({
        left: monthListBox.scrollLeft + offset,
        behavior: 'smooth',
      });
    }
  };
  const handleMonth = (monthId: number) => {
    onMonthSelect(monthId);
    handleMonthSelectX(monthId);
  };

  useEffect(() => {
    const handleScroll = () => {
      const selectMonthRect = selectMonthRef.current?.getBoundingClientRect(); // SelectMonth 위치
      contentRefs.current.forEach((ref, index) => {
        if (ref) {
          const monthBoxRect = ref.getBoundingClientRect(); // 각 MonthBox의 위치
          if (
            monthBoxRect.top <= (selectMonthRect?.bottom ?? 0) && // MonthBox가 SelectMonth에 닿을 때
            monthBoxRect.bottom >= (selectMonthRect?.top ?? 0)
          ) {
            handleMonthSelectX(index + 1);
            setActiveMonth(index + 1); // MonthBox가 닿으면 activeMonth 업데이트
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll); // 스크롤 이벤트 감지

    return () => {
      window.removeEventListener('scroll', handleScroll); // 컴포넌트 언마운트 시 이벤트 제거
    };
  }, []);

  return (
    <MonthContainer>
      <MonthListBox ref={selectMonthRef}>
        {monthList.map((e, i) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <MonthBox
                activeMonth={activeMonth}
                month={e.id}
                ref={el => {
                  refs.current[i] = el;
                }}
                onClick={() => handleMonth(e.id)}
              >
                {e.month}
              </MonthBox>
              {activeMonth === e.id && <Line />}
            </div>
          );
        })}
      </MonthListBox>
    </MonthContainer>
  );
}
export default SelectMonth;

const MonthContainer = styled.div`
  background: var(--white, #fff);
  top: 52px;
  position: sticky;
  z-index: 1000;

  @media screen and (min-width: 1280px) {
    top: 80px;
  }
`;
const MonthListBox = styled.div`
  padding: 0 16px;
  display: flex;
  white-space: nowrap;

  height: 52px;

  align-items: center;
  gap: 24px;
  width: 100%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 562px) {
    width: 562px;
    margin: 0 auto;
    padding: 0;
  }
`;

const MonthBox = styled.div<{ activeMonth: number; month: number }>`
  height: 48px;

  color: var(--Font-02_black, #111);
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;

  ${prop => {
    return prop.activeMonth === prop.month
      ? 'color: #111; font-weight: 600;'
      : 'color: #767676; font-weight: 400;';
  }}
`;
const Line = styled.div`
  align-self: center;

  width: 16px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 12px;

  background: #111;
`;
