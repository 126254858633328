import styled from 'styled-components';
import { MutableRefObject } from 'react';
import ScheduleBox from './ScheduleBox';

interface Imonth {
  month: string;
  id: number;
}

function MonthBox({
  refs,
  e,
}: {
  refs: MutableRefObject<HTMLDivElement[]>;
  e: Imonth;
}) {
  return (
    <Container>
      <ScheduleContainer>
        <div style={{ position: 'relative' }}>
          <MonthText
            ref={el => {
              const currentRefs = refs.current;
              if (el) {
                currentRefs[e.id - 1] = el; // 배열 인덱스에 맞게 할당
              }
            }}
            data-key={e.id} // 월의 id를 data-key로 추가
          >
            {e.month}
          </MonthText>
          <ScheduleBox />
        </div>
        <ScheduleBox />
        <ScheduleBox />
      </ScheduleContainer>
    </Container>
  );
}

export default MonthBox;

export const Container = styled.div`
  margin-top: 66px;
  min-width: 362px;
`;
const MonthText = styled.div`
  position: absolute;
  color: #111;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 141.667% */
  letter-spacing: -0.6px;
  top: -32px;
`;

const ScheduleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(362px, max-content));
  grid-gap: 16px;
  justify-content: center;
`;
