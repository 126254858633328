import styled from 'styled-components';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  IUserConcertCategory,
  IUserConcertCategoryList,
} from 'src/interfaces/user-mobile/overview.interface';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import { MovingBar } from 'src/styles/user-mobile/MovingBarStyle';

import {
  BkBtn,
  SelectBar,
  TopFixedBar,
  TopFixedBlock,
} from 'src/styles/user-mobile/TopFixedBarStyle';

function TopFixedBarConcertCategory({
  concertCategoryList,
  currentIndex,
  setCurrentIndex,
  categoryName,
}: IUserConcertCategoryList) {
  const navigate = useNavigate();

  const refSelectBar = useRef<HTMLDivElement>(null);
  // <HTMLDivElement>이거 안 하면 refSelectBar?.current?.offsetWidth 할 때 오류남

  const categoryLength = concertCategoryList.length;
  const [pos, setPos] = useState('');
  // 애니메이션 적용할 때 그냥 빈값을 주면 초기위치 잘 찾아감
  const [interval, setInterval] = useState(0);
  const [initial, setInitial] = useState(0);

  const onClickCategory = (event: React.MouseEvent<HTMLHeadingElement>) => {
    event.preventDefault();

    const index = Number(event.currentTarget.id);

    // div나 h에는 value 프로퍼티가 없으므로
    setPos(`${initial + index * interval}%`);
    setCurrentIndex(index);
  };

  useEffect(() => {
    setInterval(100 / categoryLength);
    setInitial(100 / (categoryLength * 2));
    setPos(`${100 / (categoryLength * 2)}%`);
  }, [categoryLength]);

  return (
    <TopFixedBlock>
      <StyledTopFixedBar>
        <Flex style={{ marginLeft: '16px' }}>
          <BkBtn src={bkBtn2} onClick={() => navigate(-1)} />
          <h1>{categoryName}</h1>
        </Flex>
        {/* <MenuBtn src={menuBtn3}/> */}
      </StyledTopFixedBar>

      <SelectBar ref={refSelectBar}>
        {concertCategoryList.map(
          (item: IUserConcertCategory, index: number) => (
            <H1
              key={index.toString()}
              id={index.toString()}
              onClick={onClickCategory}
              interval={interval}
              left={`${initial + index * interval}%`}
              active={currentIndex === index}
            >
              {item.name}
            </H1>
          ),
        )}
        <MovingBar left={pos} />
      </SelectBar>
    </TopFixedBlock>
  );
}

export default TopFixedBarConcertCategory;

const StyledTopFixedBar = styled(TopFixedBar)`
  position: relative;
  background-color: white;
  border-bottom: 4px solid #ededf0;
`;

const H1 = styled.h1<{
  left: string;
  interval: number;
  active: boolean;
}>`
  position: absolute;
  width: ${props => props.interval}%;
  left: ${props => props.left};
  top: 50%;
  transform: translate(-50%, -50%);

  ${props =>
    props.active
      ? 'color: #111; font-weight: 600;'
      : 'color: var(--Font-04_Gray, #767676); font-weight: 400;'}
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */

  cursor: pointer;
`;
