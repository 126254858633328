import { STMAP_SIGNUP_CAUTION_FOOTER } from 'src/assets/database/stampData';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

function StampFooter() {
  return (
    <Footer>
      <h1>참여 유의사항</h1>
      {STMAP_SIGNUP_CAUTION_FOOTER.map((item: string) => (
        <CautionElement>
          <p>{item}</p>
        </CautionElement>
      ))}
    </Footer>
  );
}

export default StampFooter;

const Footer = styled(FlexBox)`
  width: 90%;
  height: auto;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: white;
  padding: 14px 16px;
  margin: 20px auto;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h1 {
    color: ${({ theme }) => theme.colors.mainColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */

    margin-bottom: 4px;
  }
`;
const CautionElement = styled(FlexBox)`
  color: black;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 2px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    text-indent: -12px; /* 첫 줄을 왼쪽으로 당겨줍니다. */
    padding-left: 8px; /* 전체 문단을 오른쪽으로 이동시킵니다. */
  }
`;
