import checked from 'src/assets/images/user-web/Checked.svg';
import unchecked from 'src/assets/images/user-web/UnChecked.svg';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { apiInquiry } from 'src/apis/utils/inquiry';

import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  IFestivalOutlet,
  IInquiryFormHook,
} from 'src/interfaces/overview.interface';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';
import { useTheme } from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import * as S from './InquiryFormBoardStyle';

function InquiryFormBoard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const [secret, setSecret] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    formState: { isValid },
  } = useForm<IInquiryFormHook>({ mode: 'onChange' });
  const postMutation = useMutation({
    mutationFn: (data: IInquiryFormHook) =>
      apiInquiry.postInquiryInfo(festivalId, data),
    onSuccess: () => {
      alert('문의가 성공적으로 등록되었습니다.');
      navigate('/inquiry');
    },
    onError: error => {
      alert(error);
    },
  });

  const onSubmitForm = async (data: IInquiryFormHook) => {
    let requestData = {} as IInquiryFormHook;

    if (secret) requestData = { ...data, isSecret: secret };
    else requestData = { ...data, isSecret: secret, password: '' };

    const tmp = window.confirm(
      '작성해주신 내용으로 문의를 등록하시겠습니까?\n** 등록 후 조회만 가능하며, 수정 및 삭제는 불가합니다 **',
    );

    if (tmp) postMutation.mutate(requestData);
  };

  const onClickCheck = () => {
    setSecret(prev => {
      if (prev) unregister('password');
      return !prev;
    });
  };

  return (
    <S.BoardInquiryForm>
      <TopFixedBarBlank />
      <S.InquiryForm onSubmit={handleSubmit(onSubmitForm)}>
        <S.InputBoxeWidthCheckBox>
          <S.LongInputBox>
            <h1>제목</h1>
            <input
              {...register('title', {
                required: '제목을 입력해주세요',
                maxLength: {
                  value: 100,
                  message: '제목은 100자 이내로 입력해주세요',
                },
              })}
              type="text"
              placeholder="제목 입력"
            />
          </S.LongInputBox>
          <S.SecretBtn>
            <img
              onClick={onClickCheck}
              src={secret ? checked : unchecked}
              alt="체크 이미지"
            />
            <p>비밀글</p>
          </S.SecretBtn>
        </S.InputBoxeWidthCheckBox>
        <S.InputBoxes>
          <S.ShortInputBox>
            <S.IncludeRequire fontColor={theme.colors.fontColor}>
              <h1>닉네임(별명)</h1>
              <h2>최대 16자리</h2>
            </S.IncludeRequire>
            <input
              {...register('userId', {
                required: '닉네임(별명)을 입력해주세요',
                maxLength: {
                  value: 16,
                  message: '닉네임(별명)은 16자리 이내로 입력해주세요',
                  // maxLength에 대한 오류 message출력
                },
              })}
              placeholder="닉네임(별명) 입력"
            />
          </S.ShortInputBox>
          <S.ShortInputBox>
            <S.IncludeRequire
              disabled={!secret}
              fontColor={theme.colors.fontColor}
            >
              <h1>비밀번호</h1>
              <h2>숫자 4자리</h2>
            </S.IncludeRequire>
            {secret ? (
              <input
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                  pattern: {
                    value: /^[0-9]{4}$/,
                    message: '비밀번호는 숫자 4자리로 입력해주세요',
                  },
                })}
                placeholder="비밀번호 입력"
              />
            ) : (
              <input disabled />
            )}
          </S.ShortInputBox>
        </S.InputBoxes>
        <S.ContentInputBox>
          <S.IncludeRequire disabled={false} fontColor={theme.colors.fontColor}>
            <h1>내용</h1>
            <h2>최대 500자</h2>
          </S.IncludeRequire>
          <textarea
            {...register('content', {
              required: '문의내용을 입력해주세요',
              maxLength: {
                value: 500,
                message: '문의내용은 500자 이내로 입력해주세요',
              },
            })}
            placeholder="내용 입력"
          />
        </S.ContentInputBox>
        <S.SubmitButton
          type="submit"
          disabled={!isValid}
          bgColor={theme.colors.fontColor}
        >
          제출
        </S.SubmitButton>
      </S.InquiryForm>
    </S.BoardInquiryForm>
  );
}

export default InquiryFormBoard;
