import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  STAMP_INFO_BOOTH_ID,
  STAMP_START_BACKGROUND,
  STAMP_TITLE,
} from 'src/assets/database/stampData';
import TopFixedBarStamp from 'src/components/user-mobile/stamp/TopFixedBarStamp';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import {
  IMixedMissionUserInfo,
  IStampUserMissionInfo,
} from 'src/interfaces/user-mobile/stamp.interface';
import { FlexBox, GridBox } from 'src/styles/OverviewStyle';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';

import { BoardSetWidthStamp } from 'src/styles/user-mobile/StampStyle';
import BottomButtonBox from 'src/components/user-mobile/stamp/BottomButtonBox';

import x from 'src/assets/images/user-mobile/stamp/X.svg';
import getMissionComplete from 'src/utils/getMissionComplete';
import { apiStampMission, apiStampUser } from 'src/apis/utils/stamp';
import { useQuery } from '@tanstack/react-query';
import {
  stampMissionBlank,
  stampUserMissionInfoBlank,
} from 'src/consts/user-mobile/blank.const';
import { IS_STAMP_GUIDE, IS_STAMP_ON_SITE_GET } from 'src/consts/config.const';

function StampHome() {
  const navigate = useNavigate();
  const firstVisit = useLocation().state?.firstVisit;
  const [completed, setCompleted] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [mixedUserInfoList, setMixedUserInfoList] = useState<
    IMixedMissionUserInfo[]
  >([
    {
      ...stampMissionBlank,
      ...stampUserMissionInfoBlank,
    },
  ]);
  const { data: stampMissionList } = useQuery({
    queryKey: ['stampMissionList', localStorage.getItem('stampId')],
    queryFn: () =>
      apiStampMission
        .getStampMissionList(Number(localStorage.getItem('stampId') as string))
        .then(response => response.data.missionSummaryGetDtos),
    placeholderData: [{ ...stampMissionBlank }],
  });
  const { data: userMissionInfo, refetch: refetchUserMissionInfo } = useQuery({
    queryKey: ['userMissionInfo', localStorage.getItem('uuid')],
    queryFn: () =>
      apiStampUser
        .getStampUser(localStorage.getItem('uuid') as string)
        .then(response => response.data),
    placeholderData: [{ ...stampUserMissionInfoBlank }],
  });

  const fetchMissionInfo = async () => {
    try {
      const response = await refetchUserMissionInfo();
      if (getMissionComplete(response.data.userMissionInfo)) setCompleted(true);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (
      getMissionComplete(
        userMissionInfo?.userMissionInfoGetListDto?.userMissionInfoGetDtos,
      )
    ) {
      setCompleted(true);
      if (!userMissionInfo.finished && IS_STAMP_ON_SITE_GET) setShowPopUp(true);
    }
  }, [userMissionInfo]);

  // navigate의 state를 전달받고, 첫 방문시 가이드 페이지로 이동
  useEffect(() => {
    if (IS_STAMP_GUIDE && firstVisit) navigate('/stamp/guide');
  }, [firstVisit]);

  // 미션정보와 유저의 미션정보를 합친 리스트 제작
  useEffect(() => {
    if (
      stampMissionList?.length > 0 &&
      userMissionInfo?.userMissionInfoGetListDto?.userMissionInfoGetDtos
        ?.length > 0
    ) {
      const tmp = [];
      const { userMissionInfoGetDtos } =
        userMissionInfo.userMissionInfoGetListDto;
      for (let i = 0; i < stampMissionList.length; i += 1) {
        const index = userMissionInfoGetDtos?.findIndex(
          (item: IStampUserMissionInfo) =>
            item.missionId === stampMissionList[i].missionId,
        );
        if (index !== -1)
          tmp.push({
            ...stampMissionList[i],
            ...userMissionInfoGetDtos[index],
          });
      }
      setMixedUserInfoList(tmp);
    }
  }, [stampMissionList, userMissionInfo]);

  return (
    <Wrapper>
      <TopFixedBarStamp
        text={STAMP_TITLE}
        navigateText="/stamp/start"
        fetchMissionInfo={fetchMissionInfo}
      />
      <OpacityBackground />
      <StyledBoardSetWidthStamp>
        <StyledGridBox>
          {mixedUserInfoList?.map(
            (item: IMixedMissionUserInfo, index: number) => (
              <img
                src={
                  item.clear ? item.clearedThumbnail : item.notClearedThumbnail
                }
                alt="스탬프 이미지"
                onClick={() =>
                  navigate(`${item.missionId}`, {
                    state: {
                      completed, // 미션 전체 완료 여부
                      finished: userMissionInfo.finished, // 경품 수령 여부
                      // 해당 미션 클리어 여부
                      detailIndex: index,
                    },
                  })
                }
              />
            ),
          )}
        </StyledGridBox>
        {showPopUp && (
          <GuidePopUp>
            지도를 클릭해 {STAMP_TITLE} 부스를 방문하세요.
            <img onClick={() => setShowPopUp(false)} src={x} alt="" />
          </GuidePopUp>
        )}

        <BottomButtonBox
          isDetail={false}
          boothId={STAMP_INFO_BOOTH_ID}
          finished={userMissionInfo?.finished}
          completed={completed}
        />
      </StyledBoardSetWidthStamp>
    </Wrapper>
  );
}

export default StampHome;

const StyledBoardSetWidthStamp = styled(BoardSetWidthStamp)`
  padding: 52px 32px;
`;

const StyledGridBox = styled(GridBox)`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 32px;
  margin-bottom: 64px;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`;

const OpacityBackground = styled.div`
  position: absolute;
  background-image: url(${STAMP_START_BACKGROUND});
  background-size: cover;
  background-position: center;
  opacity: 0.7;
  min-height: 100vh;
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
`;

const GuidePopUp = styled(FlexBox)`
  position: absolute;
  bottom: 84px;

  width: 90%;
  height: 40px;
  flex-shrink: 0;
  padding: 10px 16px;

  border-radius: 12px;
  background: var(--bg-black-opacity-60, rgba(0, 0, 0, 0.6));
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(6px);

  color: var(--white, #fff);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;

  justify-content: space-between;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;
