import { instance } from 'src/apis/index';

export const apiUniv = {
  getUniv: (subAddress: string) =>
    instance.get('/univ', { params: { subAddress } }),
};

export const apiHome = {
  getHome: (festivalId: number, dateTime: string) =>
    instance.get(`home/${festivalId}`, { params: { dateTime } }),
};
