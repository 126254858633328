function setStampLocalStorage(
  stampId: string,
  uuid: string,
  name: string,
  phone: string,
  participantCount: string,
) {
  localStorage.removeItem('uuid');
  localStorage.removeItem('name');
  localStorage.removeItem('phone');
  localStorage.removeItem('participantCount');
  localStorage.removeItem('stampId');

  localStorage.setItem('stampId', stampId);
  localStorage.setItem('uuid', uuid);
  localStorage.setItem('name', name);
  localStorage.setItem('phone', phone);
  localStorage.setItem('participantCount', participantCount);
}

export default setStampLocalStorage;
