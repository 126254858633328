import { FlexBox } from 'src/styles/OverviewStyle';
import { useEffect, useState } from 'react';
import { IGetBoothSingle } from 'src/interfaces/user-mobile/map.interface';
import { apiBooth } from 'src/apis/utils/map';
// import { Flex } from 'src/styles/info.styled';
import getTranslateString from 'src/utils/getTranslateString';

import defaultImg from 'src/assets/images/map/defaultImg.png';
import { IGetBoothSingleBlank } from 'src/consts/map/blank.const';
import MapFull from 'src/pages/user-mobile/map/MapFull';
import * as S from './ClickPopupStyle';

function ClickInfo({ id }: { id: number }) {
  const [isFull, setIsFull] = useState(false);
  // const navigate = useNavigate();
  const [data, setData] = useState<IGetBoothSingle>({
    ...IGetBoothSingleBlank,
  });
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [operationHours, setOperationHours] = useState('');
  const [buttonName, setButtonName] = useState('');

  useEffect(() => {
    apiBooth
      .getBoothSingle(id)
      .then(res => {
        setData(res.data);

        if (res.data.name !== '')
          getTranslateString(res.data.name).then(translatedName => {
            setName(translatedName);
          });
        if (res.data.summary !== '')
          getTranslateString(res.data.summary).then(translatedSummary => {
            setSummary(translatedSummary);
          });
        if (res.data.operationHours !== '')
          getTranslateString(res.data.operationHours).then(translatedHours => {
            setOperationHours(translatedHours);
          });
        if (res.data.buttonName !== '')
          getTranslateString(res.data.buttonName).then(translateBtnName => {
            setButtonName(translateBtnName);
          });
      })
      .catch(() => {
        // console.log(err, item.name);
      });
  }, [id]);

  const handleFull = () => {
    if (data?.content || (data.menus && data?.menus?.length > 0))
      setIsFull(true);
    // navigate(`${props.id}`, { state: data });
  };
  const handleLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    window.location.href = `${data?.url}`;
  };

  return (
    <>
      {isFull ? (
        <MapFull data={data} setIsFull={setIsFull} />
      ) : (
        <S.Map>
          <S.Container onClick={handleFull}>
            <S.InBox>
              <S.TextBox>
                <S.Name dangerouslySetInnerHTML={{ __html: name }} />
                <S.Summary dangerouslySetInnerHTML={{ __html: summary }} />
                <FlexBox style={{ gap: '4px' }}>
                  <S.Time>{operationHours}</S.Time>
                </FlexBox>
              </S.TextBox>
              {data?.thumbnail ? (
                <S.Img src={data?.thumbnail} />
              ) : (
                <S.Img src={defaultImg} />
              )}
            </S.InBox>
            {data?.buttonName && (
              <S.LinkBox onClick={handleLink}>
                {data?.buttonImage && <S.LinkImg src={data?.buttonImage} />}
                <S.LinkName>{buttonName}</S.LinkName>
              </S.LinkBox>
            )}
          </S.Container>
        </S.Map>
      )}
    </>
  );
}

export default ClickInfo;
