import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useRecoilState } from 'recoil';
// import { missingInfoState } from 'recoils/atoms_missing';
// import { missingPost } from 'apis/apis_post';
import TopFixedBarLostItem from 'src/components/user-mobile/lost-item/TopFixedBarLostItem';
import MissingInput, {
  MissingSemiTitle,
} from 'src/components/user-mobile/missing/MissingInput';
// import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import { missingInfoState } from 'src/recoils/user-mobile.atom';
import MissingBtn from 'src/components/user-mobile/missing/MissingBtn';
import AgreePopup from 'src/components/user-mobile/missing/AgreePopup';
import SuccessPopup from 'src/components/user-mobile/missing/SuccessPopup';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { useOutletContext } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { apiMissing } from 'src/apis/utils/missing';

function MissingHome() {
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const [significantLength, setSignificantLength] = useState(0);
  //   const navigate = useNavigate();
  //   const [active, setActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [info, setInfo] = useRecoilState(missingInfoState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkInfo = () => {
    const requiredFields = [
      'name',
      'age',
      'gender',
      'content',
      'missingLocation',
      'parentName',
      'parentNo',
    ];
    const hasEmptyField = requiredFields.some((field: string) => {
      if (!info[field] || info[field] === '') {
        alert(`필수 정보를 모두 입력해주세요.`);
        return true;
      }
      return false;
    });

    return !hasEmptyField;
  };

  const handleSumbit = () => {
    if (checkInfo()) {
      apiMissing
        .post(info, festivalId)
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {});
    }
  };

  const handleStringLength = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length >= 51) {
      alert('50자 이하로 작성해주시기 바랍니다.');
      e.target.value = e.target.value.slice(0, 50); // 50자 초과된 부분을 잘라냅니다.
    }
    setSignificantLength(e.target.value.length);
    setInfo({ ...info, [e.target.id]: e.target.value });
  };
  return (
    <Wrapper>
      <TopFixedBarLostItem text="실종자 찾기" />
      <Container>
        <MissingInfoContainer style={{ paddingTop: '20px' }}>
          <MissingTitle>실종자 정보</MissingTitle>
          <MissingInput title="실종자 이름" placeholder="이름 입력" id="name" />
          <MissingBtn
            title="실종자 연령대"
            select={['아동', '청소년', '성인', '노인']}
            id="age"
          />
          <MissingBtn
            title="실종자 성별"
            select={['남자', '여자']}
            id="gender"
          />
          <MissingSemiTitle>
            기타 특이사항(인상착의, 주의사항 등)
          </MissingSemiTitle>
          <MissingTextArea
            onChange={handleStringLength}
            placeholder={`인상착의, 주의사항 등 특이사항 입력
ex) 빨간 크록스 신발, 파란색 반팔 티셔츠`}
            id="content"
          />
          <LengthBox>{significantLength} / 50</LengthBox>
          <MissingInput
            title="실종 위치"
            placeholder="실종 위치 입력"
            id="missingLocation"
          />
        </MissingInfoContainer>
        <MissingInfoContainer>
          <MissingTitle>보호자 정보</MissingTitle>
          <MissingInput
            title="보호자 이름"
            placeholder="보호자 이름 입력"
            id="parentName"
          />
          <MissingInput
            title="보호자 연락처"
            placeholder="보호자 연락처 입력"
            id="parentNo"
          />
        </MissingInfoContainer>
        <MissingInfoContainer>
          <MissingTitle>추가 정보(선택)</MissingTitle>
          <p style={{ marginTop: '4px', fontSize: '15px', fontWeight: '600' }}>
            실종자 탐색에 큰 도움이 됩니다.
          </p>
          <MissingInput
            title="실종 시간"
            placeholder="실종 시간 입력"
            id="missingTime"
          />
          <MissingInput id="img" title="실종자 사진" img />
          <ApplyBtn onClick={handleSumbit}>신청하기</ApplyBtn>
        </MissingInfoContainer>
        <AgreePopup />
        {isSuccess && <SuccessPopup setIsSuccess={setIsSuccess} />}
      </Container>
    </Wrapper>
  );
}

export default MissingHome;

const Container = styled.div`
  padding: 52px 16px;
`;
// const AgreeBox = styled.div`
//   width: 358px;
//   height: 102px;
//   flex-shrink: 0;
//   border-radius: 12px;
//   background: #222;
//   color: #aaa;

//   font-size: 15px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 22px; /* 146.667% */
//   overflow-y: auto;
//   ::-webkit-scrollbar {
//     width: 8px;
//   }
//   padding: 16px 12px;
// `;
export const MissingTitle = styled.div`
  color: #fafafa;

  color: #111;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */

  align-self: flex-start;
`;
export const MissingInfoContainer = styled.div`
  padding-top: 40px;
`;
// const Agree = styled.div`
//   color: #ddd;

//   font-size: 16px;

//   font-weight: 600;
//   line-height: 24px; /* 150% */
//   margin-left: 8px;
// `;

// const Checkbox = styled.div<{ active: boolean }>`
//   width: 20px;
//   height: 20px;
//   flex-shrink: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid #999;
//   border-radius: 20px;
//   &:hover {
//     cursor: pointer;
//   }
//   ${props => (props.active ? ' border: 1px solid #53CDDD;' : '')}
// `;

export const ApplyBtn = styled.button`
  margin-top: 64px;
  margin-bottom: 24px;
  width: 343px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.mainColor};

  color: #fff;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
`;

// const Circle = styled.div`
//   width: 14px;
//   height: 14px;
//   flex-shrink: 0;
//   background-color: #53cddd;
//   border-radius: 14px;
// `;

export const MissingTextArea = styled.textarea`
  padding: 16px 14px;
  width: 343px;

  border-radius: 8px;
  background: var(--white, #fff);

  border: 0;
  font-size: 16px;

  font-family: 'Pretendard Variable';
`;

export const LengthBox = styled.div`
  display: flex;
  position: absolute;
  width: 343px;
  justify-content: end;
  margin-top: 4px;
`;
