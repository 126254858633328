import styled from 'styled-components';
import detailImg from 'src/assets/images/user-mobile/schedule/detail.svg';

function ScheduleBox() {
  const data = {
    title: 'K-이노스 스케일업 위크 참가기업 모집',
    category: '창업경진대회',
    dDay: 10,
  };
  return (
    <Container>
      <div style={{ position: 'relative' }}>
        <FlexBox>
          <Category>{data.category}</Category>
          <Dday>{`마감 D-${data.dDay}`}</Dday>
        </FlexBox>
        <Title>{data.title}</Title>
        <Detail>
          자세히 보기
          <img src={detailImg} alt="자세히보기 이미지" />
        </Detail>
      </div>
      <Poster src={detailImg} alt="프로그램 이미지" />
    </Container>
  );
}

export default ScheduleBox;

const Container = styled.div`
  width: 362px;
  height: 152px;

  display: flex;
  justify-content: space-between;
  padding: 16px;
  height: 152px;

  margin-top: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--white, #fff);

  gap: 12px;

  @media screen and (min-width: 1280px) {
    width: 376px;
    height: 232px;
    flex-shrink: 0;
  }
`;

const Category = styled.div`
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #138b49;
  color: white;

  color: var(--Font-01_White, var(--white, #fff));

  font-size: 12px;

  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.3px;
`;

const Dday = styled(Category)``;

const FlexBox = styled.div`
  display: flex;
  gap: 6px;
`;

const Title = styled.div`
  margin-top: 8px;
  color: #111;

  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.45px;
`;

const Detail = styled.div`
  display: flex;
  position: absolute;

  bottom: 0;
  left: 0;

  color: var(--Font-02_black, #111);

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
`;

const Poster = styled.img`
  flex-shrink: 0;

  width: 84px;
  height: 120px;
  @media screen and (min-width: 1280px) {
    width: 140px;
    height: 200px;
  }
`;
