import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import grayBlank from 'src/assets/images/user-mobile/GrayBlank.png';
import { IBanner } from 'src/interfaces/user-mobile/main.interface';
import styled, { useTheme } from 'styled-components';
import { SLIDER_SIZE_RATE } from 'src/consts/user-mobile/overview.const';
import { ICustomSliderMainBanner } from 'src/interfaces/user-mobile/overview.interface';
import Slider from 'react-slick';
import {
  SliderBoard,
  SliderImgBoard,
  SliderIndexDotHome,
  SliderIndexHome,
} from 'src/styles/user-mobile/CustomSliderStyle';

function CustomSliderMainBanner({ imageBoardInfo }: ICustomSliderMainBanner) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentNum, setCurrentNum] = useState(1);

  const [imgHeight, setImgHeight] = useState(0);
  const refSliderBoard = useRef<HTMLDivElement>(null);

  const settings = {
    dots: false, // 슬라이드 바닥에 점을 보이게 할 것인지 설정
    arrows: false,
    infinite: true, // 무한 반복되게 할 것인지 설정
    speed: 300, // 슬라이드하는데 걸리는 시간 설정
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 슬라이드 넘어갈 때마다 몇 개의 슬라이드를 넘길 것인지 설정
    autoplay: true, // 자동으로 슬라이드를 넘길 것인지 설정

    afterChange: (current: number) => {
      // event처럼 이미 current자리는 현재 슬라이드 번호에 대한 인자임
      setCurrentNum(current + 1);
    }, // 현재 슬라이드 위치에 따른 변화 get가능
  };

  useEffect(() => {
    if (refSliderBoard.current) {
      setImgHeight(refSliderBoard.current.offsetWidth * SLIDER_SIZE_RATE);
    }
  }, [refSliderBoard.current, imageBoardInfo]);

  return (
    <StyledSliderBoard ref={refSliderBoard} height={Number(imgHeight)}>
      {imageBoardInfo?.length > 1 ? (
        <StyledSlider {...settings} imageBoardInfo={imageBoardInfo}>
          {imageBoardInfo?.map(
            // imagesInfo가 interface에서 '?'표시가 되어있기 때문에, 안전하지 않음.
            // 때문에 imagesInfo에도 '?'추가
            (item: IBanner, index: number) => (
              // const id = props.imagesInfo?.ids?[index];
              // map함수 안에서는 변수 선언 불가
              /* '?.'(옵셔널 체이닝)과 '?='가 한 묶음. '?[]'연산자는 아직 없음 */
              <StyledSliderImgBoard
                key={index.toString()}
                height={Number(imgHeight)}
                onClick={() =>
                  navigate(`post/${item.type.toLowerCase()}/${item.id}`)
                }
                src={item.thumbnail || grayBlank}
              />
            ),
          )}
        </StyledSlider>
      ) : (
        imageBoardInfo?.length !== 0 && (
          <StyledSliderImgBoard
            height={Number(imgHeight)}
            onClick={() => navigate(`post/event/${imageBoardInfo[0]?.id}`)}
            src={imageBoardInfo[0]?.thumbnail || grayBlank}
          />
        )
      )}

      {imageBoardInfo?.length !== 0 && (
        <SliderIndexHome>
          {imageBoardInfo.map((item: IBanner, index: number) => (
            <SliderIndexDotHome
              key={index.toString()}
              active={index === currentNum - 1}
              activeColor={theme.colors.fontColor}
            />
          ))}
        </SliderIndexHome>
      )}
    </StyledSliderBoard>
  );
}

export default CustomSliderMainBanner;

const StyledSlider = styled(Slider)<ICustomSliderMainBanner>`
  height: 100%;
  background-color: transparent;
  border-radius: 0px 0px 16px 16px;
  overflow-y: hidden;
`;

const StyledSliderImgBoard = styled(SliderImgBoard)<{
  height: number;
}>`
  height: ${({ height }) => height}px;
  border-radius: 16px;
  cursor: pointer;
`;

const StyledSliderBoard = styled(SliderBoard)<{ height: number }>`
  height: ${props => props.height - 3}px;
`;
