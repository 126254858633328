import { healingList } from 'src/assets/database/webData';
import { FlexBox, GridBox } from 'src/styles/OverviewStyle';
import { ProgramReserveButton } from 'src/styles/user-web/OverviewStyle';
import styled from 'styled-components';
import forward from 'src/assets/images/user-web/Forward.svg';
import backward from 'src/assets/images/user-web/Backward.svg';

function WebProgramHealing() {
  return (
    <BoardProgramHealing>
      <ProgramReserveButton
        onClick={() =>
          alert(
            '8월 27일 ~ 8월 30일 (4일간)\n사전예약 접수 예정 입니다.\n미달된 모집인원은 현장 접수 예정입니다.',
          )
        }
      >
        <img src={forward} alt="버튼 이미지" />
        <h1>서우봉 트래킹 체험 예약하기</h1>
        <img src={backward} alt="버튼 이미지" />
      </ProgramReserveButton>
      <GridBoardHealing>
        <h1>힐링 체험 콘텐츠</h1>
        <GridBoxHealing>
          {healingList.map(item => (
            <HealingBox key={item.id}>
              <img src={item.thumbnail} alt="샘플 이미지" />
            </HealingBox>
          ))}
        </GridBoxHealing>
      </GridBoardHealing>
    </BoardProgramHealing>
  );
}

export default WebProgramHealing;

const BoardProgramHealing = styled(FlexBox)`
  width: 100vw;

  margin-bottom: 160px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const GridBoardHealing = styled(FlexBox)`
  width: 1109px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > h1 {
    margin-bottom: 28px;
    color: #111;
    text-align: center;
    font-family: Jalnan;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    letter-spacing: -0.07px;
  }
`;

const GridBoxHealing = styled(GridBox)`
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(
    100px,
    auto
  ); /* 행의 최소 높이를 100px로 설정하고 내용에 따라 자동으로 조정 */
  column-gap: 40px;
  row-gap: 32px;
`;

const HealingBox = styled(FlexBox)`
  position: relative;

  width: 440px;
  height: 338px;
  flex-shrink: 0;

  > img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }
`;
