import { INIT_MAP_OPTION } from 'src/consts/map/overview.const';

import { IGetBoothInfo } from 'src/interfaces/user-mobile/map.interface';
import { imgStyle, markerStyle, textStyle } from 'src/consts/map/style.const';
import { MutableRefObject } from 'react';
import { SetterOrUpdater } from 'recoil';
import { getMarkerSize } from './getMarkerSize';
import { CheckDurationTime } from './CheckDurationTime';
import { handleMarker } from './handleMarker';
import getTranslateString from '../getTranslateString';

export const makeMarker = async (
  mapRef: MutableRefObject<naver.maps.Map | undefined>,
  boothList: IGetBoothInfo[],
  markerImg: string,
  day: number,
  isNight: boolean,
  zoom: number,
  setMarkers: SetterOrUpdater<naver.maps.Marker[]>,
  setMarkerPopup: SetterOrUpdater<number>,
) => {
  if (!boothList || boothList.length === 0) {
    setMarkers((prevMarkers: naver.maps.Marker[]) => {
      if (prevMarkers !== undefined) {
        prevMarkers.forEach(e => {
          e.setMap(null);
        });
      }
      return [];
    });
    return;
  }

  const tmpMarkers = await Promise.all(
    boothList.map(async (booth: IGetBoothInfo) => {
      const [width, height] = getMarkerSize(booth);

      if (CheckDurationTime(booth, day, isNight)) {
        // 비동기적으로 번역된 boothName을 가져옴
        const boothName = await getTranslateString(booth?.name || '');
        const marker = new naver.maps.Marker({
          position: new naver.maps.LatLng(booth?.latitude, booth?.longitude),
          map: mapRef.current,
          icon: {
            content: `
                      <div style="${markerStyle}">
                        <img style="${imgStyle}" src=${markerImg} />
                        ${zoom > INIT_MAP_OPTION.zoom ? `<div style="${textStyle}">${boothName}</div>` : `<div style="height: 18px"/>`}
                      </div>
                    `,
            size: new naver.maps.Size(width, height),
            anchor: new naver.maps.Point(width / 2, height / 2),
          },
        });
        naver.maps.Event.addListener(marker, 'click', () => {
          handleMarker(mapRef, booth, setMarkerPopup);
        });
        return marker;
      }
      return null;
    }),
  );
  const filteredMarkers = tmpMarkers.filter(
    (marker): marker is naver.maps.Marker => marker !== null,
  );

  setMarkers((prevMarkers: naver.maps.Marker[]) => {
    if (prevMarkers !== undefined) {
      prevMarkers.forEach(e => {
        e.setMap(null);
      });
    }
    return filteredMarkers;
  });
};
