import { useEffect } from 'react';

import TopFixedBarPostList from 'src/components/user-mobile/post/TopFixedBarPostList';

import PostListBoard from 'src/components/user-mobile/post/PostListBoard';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';

function PostList() {
  // const theme = useTheme();
  // const widthView = window.innerWidth > 450 ? 390 : window.innerWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // 스크롤 오류 때문에 일단 이렇게 강제로 올려놈

  return (
    <Wrapper>
      <TopFixedBarPostList />
      <TopFixedBarBlank />
      <TopFixedBarBlank style={{ height: '46px' }} />
      <PostListBoard />
    </Wrapper>
  );
}

export default PostList;
