import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import styled from 'styled-components';

export const PostContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
  min-height: 100vh;
  background-color: white;
  overflow-x: hidden;
`;

export const SliderBoardPost = styled.div`
  width: 100%;
  height: 100%;
`;

export const MainTextBoard = styled.div<{ fontColor: string }>`
  background-color: white;
  padding: 20px 24px 0px 24px;
  > h6 {
    color: ${props => props.fontColor};

    /* body1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    margin-bottom: 8px;
  }
  > h1 {
    color: #111;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */

    margin-bottom: 8px;
  }
  > h2 {
    color: var(--Font-04_Gray, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    margin-bottom: 16px;
  }
  > h3 {
    color: var(--Font-02_black, #111);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &:last-of-type {
      margin-bottom: 100px;
    }
  }
`;
