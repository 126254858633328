import { IStampPostUserInfo } from 'src/interfaces/user-mobile/stamp.interface';
import { instance } from '..';

export const apiStamp = {
  getStampList: (festivalId: number) =>
    instance.get(`/stamp`, { params: { festivalId } }),
};

export const apiStampMission = {
  getStampMissionList: (stampId: number) =>
    instance.get(`/stamp/missions`, { params: { stampId } }),
  getStampMission: (missionId: number) =>
    instance.get(`/stamp/mission`, { params: { missionId } }),
};

export const apiStampUser = {
  postStampUser: (stampId: number, userInfo: IStampPostUserInfo) =>
    instance.post('/stamp/user', userInfo, { params: { stampId } }),

  postStampUserCustom: (stampId: number, userInfo: IStampPostUserInfo) =>
    instance.post('/stamp/user/custom', userInfo, { params: { stampId } }),

  postStampUserLogin: (stampId: number, name: string, phone: string) =>
    instance.post(
      '/stamp/user/login',
      { name, phone },
      { params: { stampId } },
    ),

  patchStampUser: (uuid: string, userMissionId: number) =>
    instance.patch(`/stamp/user/${uuid}/${userMissionId}`),

  patchStampUserFinish: (uuid: string) =>
    instance.patch(`/stamp/user/check/${uuid}`),

  getStampUser: (uuid: string) => instance.get(`/stamp/user/${uuid}`),
};
