import imageBlank from 'src/assets/images/user-mobile/GrayBlank.png';

import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';
import { useEffect, useState } from 'react';
import { apiConcertInfo } from 'src/apis/utils/concert';
import {
  concertCategoryInfoBlank,
  userConcertCategoryBlank,
} from 'src/consts/user-mobile/blank.const';
import { useOutletContext } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';
import {
  BoardSetWidthInfoImageBoard,
  InfoImageBoard,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import TopFixedBarConcertCategory from 'src/components/user-mobile/concert/TopFixedBarConcertCategory';
import { useQuery } from '@tanstack/react-query';

function ConcertMainInfo({ type }: { type: string }) {
  const { festivalId } = useOutletContext<IFestivalOutlet>();

  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: concertCategoryList } = useQuery({
    queryKey: ['concertCategoryList', festivalId, type],
    queryFn: () =>
      apiConcertInfo
        .getConcertInfos(festivalId, type)
        .then(response => response.data.concertInfoGetDto)
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: [{ ...userConcertCategoryBlank }],
  });

  const { data: categoryInfo } = useQuery({
    queryKey: ['categoryInfo', concertCategoryList, currentIndex],
    queryFn: () =>
      apiConcertInfo
        .getConcertInfo(concertCategoryList[currentIndex]?.concertInfoId)
        .then(response => response.data)
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: { ...concertCategoryInfoBlank, type },
  });

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Wrapper>
      <BoardSetWidthInfoImageBoard>
        <TopFixedBarConcertCategory
          categoryName={
            window.location.pathname.includes('info')
              ? '공연 정보'
              : type === 'entry'
                ? '입장 방법'
                : '관람 안내'
          }
          concertCategoryList={concertCategoryList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
        <TopFixedBarBlank />
        <TopFixedBarBlank style={{ height: '46px' }} />

        <InfoImageBoard>
          {categoryInfo?.images.map((item: IImageInfo) => (
            <img
              key={item.image}
              id={item.imageId.toString()}
              src={item.image || imageBlank}
              alt="카테고리 이미지"
            />
          ))}
        </InfoImageBoard>
      </BoardSetWidthInfoImageBoard>
    </Wrapper>
  );
}

export default ConcertMainInfo;
