import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import styled from 'styled-components';

export const InquiryContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
  min-height: 100vh;

  background-color: white;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const MainTextBoard = styled.div<{ fontColor: string }>`
  background-color: white;
  padding: 20px 24px;
  > h6 {
    color: ${props => props.fontColor};

    /* body1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    margin-bottom: 8px;
  }
  > h1 {
    color: #111;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */

    margin-bottom: 8px;
  }
  > h2 {
    color: var(--Font-04_Gray, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    margin-bottom: 16px;
  }
  > h3 {
    color: var(--Font-02_black, #111);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
`;

export const AnswerTextBoard = styled.div`
  align-self: center;
  width: 90%;

  padding: 16px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  color: var(--Font-02_black, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  white-space: pre-line;
  h1 {
    color: #111;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */

    margin-bottom: 8px;
  }
`;
