import styled from 'styled-components';
import bkBtn from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dayState } from 'src/recoils/user-mobile.atom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { useEffect, useState } from 'react';
import { BkBtn } from 'src/styles/user-mobile/TopFixedBarStyle';
import getTranslateString from 'src/utils/getTranslateString';

function TopFixedBarMap({
  topText,
  setDayPopup,
}: {
  topText: string;
  setDayPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [day, setDay] = useRecoilState(dayState);
  const [dayText, setDayText] = useState('');
  const { todayOf } = useOutletContext<IFestivalOutlet>();
  const navigate = useNavigate();

  useEffect(() => {
    if (todayOf === 0) setDay(1);
    else setDay(todayOf);
  }, []);

  useEffect(() => {
    getTranslateString(`${day}일차`).then(res => {
      setDayText(res);
    });
  }, [day]);

  const handleDay = () => {
    setDayPopup((prev: boolean) => !prev);
  };
  return (
    <Container>
      <Back
        src={bkBtn}
        onClick={() => {
          navigate(-1);
        }}
      />

      <h1>{topText}</h1>
      <DayBtn onClick={handleDay}>{dayText}</DayBtn>
    </Container>
  );
}

export default TopFixedBarMap;

const Container = styled.div`
  width: 100%;
  height: 48px;
  position: absolute;
  z-index: 1005;
  flex-shrink: 0;
  border-bottom: 1px solid #dadada;
  background: #fff;

  display: flex;
  align-items: center;

  h1 {
    color: #111;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
  }
`;
const Back = styled(BkBtn)`
  margin-left: 16px;
`;
const DayBtn = styled.button`
  /* width: 58px; */
  height: 32px;

  display: inline-flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;

  right: 16px;
  border-radius: 24px;
  border: 1px solid var(--BG-Black_Color, #111);
  background: #fff;

  color: var(--Font-02_black, #111);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.325px;
`;
