import { IWebInquiryModal } from 'src/interfaces/user-web.interface';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

function WebInquiryModal({
  onChangePassword,
  onClickCloseModal,
  onClickSubmitInfo,
}: IWebInquiryModal) {
  return (
    <BlackBackground>
      <PasswordModal>
        <h1>비밀번호 입력</h1>
        <input onChange={onChangePassword} placeholder="비밀번호 4자리 입력" />
        <TwoBtnBox>
          <CancleBtn onClick={onClickCloseModal}>취소</CancleBtn>
          <SubmitBtn onClick={onClickSubmitInfo}>확인</SubmitBtn>
        </TwoBtnBox>
      </PasswordModal>
    </BlackBackground>
  );
}

export default WebInquiryModal;

const PasswordModal = styled(FlexBox)`
  position: absolute;
  top: 320px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;

  width: 296px;
  height: 180px;

  border-radius: 12px;
  background-color: #fff;

  padding: 24px;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    margin-bottom: 8px;

    color: #111;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  > input {
    width: 248px;
    height: 44px;
    flex-shrink: 0;
    border: none;
    border-bottom: 1px solid var(--Line-Regular_Color, #e5e5ec);
    margin-bottom: 24px;
  }
`;

const TwoBtnBox = styled(FlexBox)`
  gap: 8px;
  > button {
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;

    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;
const CancleBtn = styled.button`
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
  background-color: var(--white, #fff);

  color: #111;
`;
const SubmitBtn = styled.button`
  color: #fff;
  background-color: #054c95;
`;

const BlackBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  z-index: 20;
`;
