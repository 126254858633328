import styled from 'styled-components';
import dropDown from 'src/assets/images/user-mobile/schedule/dropdown.svg';
import { useState } from 'react';
import YearPopup from './YearPoup';

function SelectYear() {
  const [isPopup, setIsPopup] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  const handlePopup = () => {
    setIsPopup(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <YearContainer>
      {isPopup && <YearPopup setIsPopup={setIsPopup} setYear={setYear} />}
      <YearBox onClick={handlePopup}>
        {`${year}년`} <DropDown src={dropDown} alt="드롭다운" />
      </YearBox>
    </YearContainer>
  );
}
export default SelectYear;

const YearContainer = styled.div`
  background-color: #fff;
  margin-top: 52px;
  @media screen and (min-width: 1280px) {
    margin-top: 82px;

    display: flex;
    justify-content: center;
  }
`;

const YearBox = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: #fff;
  color: #111;
  padding-left: 16px;
  padding-top: 4px;

  font-size: 28px;

  font-weight: 600;
  line-height: 38px; /* 135.714% */
  letter-spacing: -0.7px;
`;

const DropDown = styled.img`
  width: 28px;
  height: 28px;
`;
