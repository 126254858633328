import checked from 'src/assets/images/user-web/Checked.svg';
import unchecked from 'src/assets/images/user-web/UnChecked.svg';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IWebInquiryForm } from 'src/interfaces/user-web.interface';
import { apiInquiry } from 'src/apis/utils/inquiry';
import { IInquiryFormHook } from 'src/interfaces/overview.interface';
import { useMutation } from '@tanstack/react-query';
import WebBackButton from '../../WebBackButton';

import * as S from './WebInquiryFormStyle';

function WebInquiryForm({ festivalId, setCurrentPage }: IWebInquiryForm) {
  const [secret, setSecret] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    formState: { isValid },
  } = useForm<IInquiryFormHook>({ mode: 'onChange' });
  // mode: 'onChange' => input의 변화가 있을 때마다 검증
  // (기본값은 'onSubmit'으로, 폼 제출시에만 검증)
  // 다만 유효성검사 => 렌더링을 의미하지는 않음.
  // 다만, 유효성검사로 인해 errors 객체에 변화가 생긴다면 그것은 렌더링을 작동시킴.
  // => 즉 유효성 검사에 변화가 없다면 리렌더링이 없다.
  const mutationForm = useMutation({
    mutationFn: ({ id, data }: { id: number; data: IInquiryFormHook }) =>
      apiInquiry.postInquiryInfo(id, data),
    onSuccess: () => {
      alert('문의가 성공적으로 등록되었습니다.');
      setCurrentPage('list');
    },
    onError: error => alert(error),
  });
  const onSubmitForm = async (data: IInquiryFormHook) => {
    // errors는 handleSubmit에서 확인 불가능.
    // 애초에 폼 태그의 내용이 현재 들어가있어야만 확인 가능한 것
    // => 실시간 console 및 렌더링시에만 확인 가능

    let requestData = {} as IInquiryFormHook;

    if (secret) requestData = { ...data, isSecret: secret };
    else requestData = { ...data, isSecret: secret, password: '' };

    const tmp = window.confirm(
      '작성해주신 내용으로 문의를 등록하시겠습니까?\n** 등록 후 조회만 가능하며, 수정 및 삭제는 불가합니다 **',
    );

    if (tmp) mutationForm.mutate({ id: festivalId, data: requestData });
  };

  const onClickCheck = () => {
    setSecret(prev => {
      if (prev) unregister('password');
      return !prev;
    });
  };

  const onClickBackButton = () => {
    setCurrentPage('list');
  };

  // console.log(errors);

  return (
    <S.BoardInquiry>
      <S.TopContainer>
        <WebBackButton className="" onClickBackButton={onClickBackButton} />
        <h1>문의사항 작성</h1>
      </S.TopContainer>
      <S.HR />
      <S.InquiryForm onSubmit={handleSubmit(onSubmitForm)}>
        <S.InputBoxeWidthCheckBox>
          <S.LongInputBox>
            <h1>제목</h1>
            <input
              {...register('title', {
                required: '제목을 입력해주세요',
                maxLength: {
                  value: 100,
                  message: '제목은 100자 이내로 입력해주세요',
                },
              })}
              type="text"
              placeholder="제목 입력"
            />
          </S.LongInputBox>
          <S.SecretBtn>
            <img
              onClick={onClickCheck}
              src={secret ? checked : unchecked}
              alt="체크 이미지"
            />
            <p>비밀글</p>
          </S.SecretBtn>
        </S.InputBoxeWidthCheckBox>
        <S.InputBoxes>
          <S.ShortInputBox>
            <S.IncludeRequire>
              <h1>닉네임(별명)</h1>
              <h2>최대 16자리</h2>
            </S.IncludeRequire>
            <input
              {...register('userId', {
                required: '닉네임(별명)을 입력해주세요',
                maxLength: {
                  value: 16,
                  message: '닉네임(별명)은 16자리 이내로 입력해주세요',
                  // maxLength에 대한 오류 message출력
                },
              })}
              placeholder="닉네임(별명) 입력"
            />
          </S.ShortInputBox>
          <S.ShortInputBox>
            <S.IncludeRequire disabled={!secret}>
              <h1>비밀번호</h1>
              <h2>숫자 4자리</h2>
            </S.IncludeRequire>
            {secret ? (
              <input
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                  pattern: {
                    value: /^[0-9]{4}$/,
                    message: '비밀번호는 숫자 4자리로 입력해주세요',
                    // 해당 함수의 반환 값이 에러 메시지로 출력됨
                    // 즉, 반환값의 조건식이 에러의 조건이 됨
                    // 에러의 조건이 참일 경우 에러메세지 출력!
                    // true 반환일 경우에는 errors가 없다고 출력 (so, true처리 필요)
                  },
                })}
                placeholder="비밀번호 입력"
              />
            ) : (
              <input disabled />
            )}
          </S.ShortInputBox>
        </S.InputBoxes>
        <S.ContentInputBox>
          <S.IncludeRequire disabled={false}>
            <h1>내용</h1>
            <h2>최대 500자</h2>
          </S.IncludeRequire>
          <textarea
            {...register('content', {
              required: '문의내용을 입력해주세요',
              maxLength: {
                value: 500,
                message: '문의내용은 500자 이내로 입력해주세요',
              },
            })}
            placeholder="내용 입력"
          />
        </S.ContentInputBox>
        <S.SubmitButton type="submit" disabled={!isValid}>
          제출
        </S.SubmitButton>
      </S.InquiryForm>
    </S.BoardInquiry>
  );
}

export default WebInquiryForm;
