import { instance } from 'src/apis/index';

export const apiCategory = {
  getCategory: (festivalId: number) =>
    instance.get('/mapCategory', { params: { festivalId } }),
  getCategorySingle: (categoryId: number) =>
    instance.get(`/mapCategory/${categoryId}`),
  getCategoryImg: (categoryId: number) =>
    instance.get('/mapCategory/image', {
      params: { mapCategoryId: categoryId },
    }),
};

export const apiBooth = {
  getBoothes: (categoryId: number) =>
    instance.get('/map', { params: { mapCategoryId: categoryId } }),

  getBoothSingle: (boothId: number) => instance.get(`/map/${boothId}`),
};

export const apiMenu = {
  getMenu: (menuId: number) =>
    instance.get('/menu', { params: { storeId: menuId } }),
};
