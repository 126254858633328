import styled from 'styled-components';

export const MovingBar = styled.div<{ left: string }>`
  display: ${props => (props.left === 'Infinity%' ? 'none' : '')};

  position: absolute;
  left: ${props => props.left};
  bottom: 0;
  transform: translateX(-50%);

  width: 32px;
  height: 4px;
  flex-shrink: 0;

  background-color: black;
`;
