import scan from 'src/assets/images/user-mobile/stamp/Scan.svg';
import gift from 'src/assets/images/user-mobile/stamp/Gift.svg';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import { IS_STAMP_ON_SITE_GET } from 'src/consts/config.const';
import ToMapButton from './ToMapButton';

interface IProps {
  isDetail: boolean;
  completed: boolean;
  finished: boolean;
  boothId: number;
}

function BottomButtonBox({
  isDetail,
  completed, // 미션 전체 완료 여부
  finished, // 경품 수령 여부
  boothId, // 해당 스탬프 미션 부스 id
}: IProps) {
  const navigate = useNavigate();

  return (
    <ButtonBox>
      {/* 미션 세부사항 페이지인지 여부에 따라 지도 버튼을 렌더링 */}
      {/* 미션 클리어했는데 현장수령이 아닌 경우 지도 버튼 안 보이게 함 */}
      {!isDetail && !(completed && !IS_STAMP_ON_SITE_GET) && (
        <ToMapButton isDetail={isDetail} boothId={boothId} />
      )}
      {/* 미션 전부 클리어 && 경품 현장 수령 */}
      {completed && IS_STAMP_ON_SITE_GET && (
        <GiftButton
          onClick={() =>
            finished
              ? alert('이미 경품을 수령하셨습니다.')
              : navigate('/stamp/finished')
          }
        >
          <img src={gift} alt="" />
          경품 수령하기
        </GiftButton>
      )}
      {/* 미션 전부 클리어 && 경품 현장 수령 아님 */}
      {completed && !IS_STAMP_ON_SITE_GET && (
        <GiftButton onClick={() => navigate('/stamp/prize')}>
          <img src={gift} alt="" />
          투어 완료!
        </GiftButton>
      )}
      {/* 미션 아직 전부 클리어 못함 (단순 QR화면 띄우기) */}
      {!completed && (
        <ScanButton onClick={() => navigate('/stamp/qr')} isDetail={isDetail}>
          <img src={scan} alt="" />
          미션 인증하기
        </ScanButton>
      )}
    </ButtonBox>
  );
}

export default BottomButtonBox;

const ButtonBox = styled(FlexBox)`
  position: fixed;
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }

  bottom: 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  > button {
    padding: 16px;
    border-radius: 28px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.64);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
`;

const ScanButton = styled.button<{ isDetail: boolean }>`
  width: ${({ isDetail }) => (isDetail ? '90%' : '60%')};
  background: ${({ theme }) => theme.colors.mainColor};
  color: var(--white, #fff);
`;

const GiftButton = styled.button`
  width: ${IS_STAMP_ON_SITE_GET ? '60%' : '90%'};
  border: 1px solid ${({ theme }) => theme.colors.mainColor};
  background: var(--white, #fff);

  color: #111;
  cursor: pointer;
`;
