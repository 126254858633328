import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { STAMP_FONT, stampGuideList } from 'src/assets/database/stampData';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import { FlexBox } from 'src/styles/OverviewStyle';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { BoardSetWidthStamp } from 'src/styles/user-mobile/StampStyle';

import TopFixedBarStamp from 'src/components/user-mobile/stamp/TopFixedBarStamp';
import getBoldString from 'src/utils/getBoldString';
import styled from 'styled-components';

function StampGuide() {
  const navigate = useNavigate();
  // currentIndex로 데이터를 바로 띄워줘야 함 => 0이 초기값
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Wrapper>
      <TopFixedBarStamp navigateText="/stamp" />
      <StyledBoardSetWidthStamp>
        <GuideImage src={stampGuideList[currentIndex].thumbnail} />
        <GuideTitle>{stampGuideList[currentIndex].title}</GuideTitle>
        <GuideContent>
          {getBoldString(stampGuideList[currentIndex].content)}
        </GuideContent>
        <GuidePs>{stampGuideList[currentIndex].ps}</GuidePs>
        <IndexBox>
          {stampGuideList.map((_, index) => (
            <IndexCircle
              key={index}
              active={index === currentIndex}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </IndexBox>
      </StyledBoardSetWidthStamp>
      {currentIndex !== stampGuideList.length - 1 ? (
        <NextButton onClick={() => setCurrentIndex(prev => prev + 1)}>
          다음
        </NextButton>
      ) : (
        <NextButton onClick={() => navigate('/stamp')}>탐험 시작</NextButton>
      )}
    </Wrapper>
  );
}

export default StampGuide;

const StyledBoardSetWidthStamp = styled(BoardSetWidthStamp)`
  position: relative;
  padding-bottom: 180px;

  background-color: var(--white, #fff);
  justify-content: flex-start;
`;

const GuideImage = styled.img`
  width: 75%;
  height: auto;
  margin-bottom: 24px;
`;

const GuideTitle = styled.h1`
  color: #111;
  text-align: center;
  font-family: 'Gmarket Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: -0.05px;
  margin-bottom: 6px;
`;

const GuideContent = styled.h2`
  color: var(--Font-02_black, #111);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
  white-space: pre-line;

  margin-bottom: 6px;
`;

const GuidePs = styled.h3`
  color: var(--Font-03_Gray, #505050);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.325px;

  white-space: pre-line;
`;

const IndexBox = styled(FlexBox)`
  position: fixed;
  bottom: 112px;

  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const IndexCircle = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.fontColor : theme.colors.subColor};
  cursor: pointer;
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 40px;

  width: 80%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 312px;
  }
  height: 52px;
  flex-shrink: 0;

  border-radius: 28px;
  background: ${({ theme }) => theme.colors.mainColor};
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.32);

  color: var(--white, #fff);
  text-align: center;
  font-family: ${STAMP_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
