import InquiryFormBoard from 'src/components/user-mobile/inquiry/InquiryFormBoard/InquiryFormBoard';
import TopFixedBarInquiry from 'src/components/user-mobile/inquiry/TopFixedBarInquiry';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';

function InquiryForm() {
  return (
    <Wrapper>
      <TopFixedBarInquiry text="문의사항 작성" />
      <InquiryFormBoard />
    </Wrapper>
  );
}

export default InquiryForm;
