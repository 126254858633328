export const IMG_BUCKET_PC = process.env.REACT_APP_S3_IMAGE_PC;

export const EXCEPT_FOR_WEB_BOARD = 432;

export const noticeInfoBlank = {
  id: 0,
  title: '',
  thumbnail: '',
  url: ' ',
  type: '',
  images: [{ imageId: 0, image: '' }],
  index: '',
  writer: '',
  content: '',
  createTime: '',
};

export const inquiryInfoBlank = {
  id: 0,
  title: '',
  userId: '',
  createdDate: '',
  content: '',
  answer: '',
  isAnswered: true,
};

export const inquiryBlank = {
  id: 0,
  title: '',
  isAnswered: false,
  isSecret: false,
  userId: '',
  createdDate: '',
};
