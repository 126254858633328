import { IMG_BUCKET_PC } from 'src/consts/user-web.const';

export const seaLeisureList = [
  {
    id: 1,
    thumbnail: `${IMG_BUCKET_PC}SeaLeisure1.webp`,
    url: 'https://map.naver.com/p/entry/place/1510559789?c=13.27,0,0,0,dh&placePath=/ticket',
  },
  {
    id: 2,
    thumbnail: `${IMG_BUCKET_PC}SeaLeisure2.webp`,
    url: 'https://map.naver.com/p/entry/place/1510559789?c=13.27,0,0,0,dh&placePath=/ticket',
  },
  {
    id: 3,
    thumbnail: `${IMG_BUCKET_PC}SeaLeisure3.webp`,
    url: 'https://map.naver.com/p/entry/place/1510559789?c=13.27,0,0,0,dh&placePath=/ticket',
  },
  {
    id: 4,
    thumbnail: `${IMG_BUCKET_PC}SeaLeisure4.webp`,
    url: '',
  },
];

export const landLeisureList = [
  {
    id: 1,
    thumbnail: `${IMG_BUCKET_PC}LandLeisure1.webp`,
    url: 'https://map.naver.com/p/entry/place/1510559789?c=13.27,0,0,0,dh&placePath=/ticket',
  },
  {
    id: 2,
    thumbnail: `${IMG_BUCKET_PC}LandLeisure2.webp`,
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdsySYk_wmenM8vMG74L8SiFeAWdaB8q_JVh1AsN7n7CCR81g/viewform?usp=sf_link',
  },
  {
    id: 3,
    thumbnail: `${IMG_BUCKET_PC}LandLeisure3.webp`,
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdsySYk_wmenM8vMG74L8SiFeAWdaB8q_JVh1AsN7n7CCR81g/viewform?usp=sf_link',
  },
];

export const healingList = [
  {
    id: 1,
    thumbnail: `${IMG_BUCKET_PC}Healing1.webp`,
    url: ' ',
  },
  {
    id: 2,
    thumbnail: `${IMG_BUCKET_PC}Healing2.webp`,
    url: ' ',
  },
  {
    id: 3,
    thumbnail: `${IMG_BUCKET_PC}Healing3.webp`,
    url: ' ',
  },
  {
    id: 4,
    thumbnail: `${IMG_BUCKET_PC}Healing4.webp`,
    url: ' ',
  },
  {
    id: 5,
    thumbnail: `${IMG_BUCKET_PC}Healing5.webp`,
    url: ' ',
  },
  {
    id: 6,
    thumbnail: `${IMG_BUCKET_PC}Healing6.webp`,
    url: ' ',
  },
  {
    id: 7,
    thumbnail: `${IMG_BUCKET_PC}Healing7.webp`,
    url: ' ',
  },
];
