import { Dispatch, SetStateAction } from 'react';
import * as S from 'src/components/user-mobile/map/DayPopup/DayPopupStyle';

function YearPopup({
  setIsPopup,
  setYear,
}: {
  setIsPopup: Dispatch<SetStateAction<boolean>>;
  setYear: Dispatch<SetStateAction<number>>;
}) {
  const years = [2024, 2025, 2026];
  const handleYear = (e: number) => {
    setYear(e);
    setIsPopup(false);
    document.body.style.overflow = '';
  };
  return (
    <S.Container
      onClick={prev => {
        document.body.style.overflow = '';
        setIsPopup(!prev);
      }}
    >
      <S.BoxContainer
        onClick={event => {
          event.stopPropagation(); // 부모 태그의 이벤트가 퍼지지 않도록 함.
        }}
      >
        <h1>연도 선택</h1>
        {years.map(e => (
          <button type="button" id={e.toString()} onClick={() => handleYear(e)}>
            {e}
          </button>
        ))}
      </S.BoxContainer>
    </S.Container>
  );
}

export default YearPopup;
