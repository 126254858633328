import { FlexBox } from 'src/styles/OverviewStyle';
import { InputBoxWeb } from 'src/styles/user-web/OverviewStyle';
import styled from 'styled-components';

export const BoardInquiry = styled(FlexBox)`
  width: 400px;
  margin-top: 64px;
  margin-bottom: 120px;

  flex-direction: column;
`;

export const TopContainer = styled(FlexBox)`
  width: 100%;
  margin-bottom: 20px;
  > h1 {
    margin-left: 12px;

    color: #111;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.6px;
  }
`;

export const HR = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--Font-03_Gray, #505050);

  margin-bottom: 24px;
`;

export const InquiryForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

export const InputBoxeWidthCheckBox = styled(FlexBox)`
  gap: 14px;
`;

export const LongInputBox = styled(InputBoxWeb)`
  width: 320px;
`;

export const SecretBtn = styled(FlexBox)`
  align-self: flex-end;
  gap: 8px;
  > p {
    color: #111;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
  > img {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

export const InputBoxes = styled(FlexBox)`
  gap: 20px;
`;

export const ShortInputBox = styled(InputBoxWeb)`
  > input {
    width: 190px;
    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: #fff;
    }
  }
`;

export const IncludeRequire = styled(FlexBox)<{ disabled?: boolean }>`
  margin-bottom: 8px;
  align-items: flex-end;
  gap: 4px;
  > h1 {
    color: ${props => (props.disabled ? '#AAA' : '#111')};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
  > h2 {
    color: ${props => (props.disabled ? '#BBB' : '#054c95;')};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;
  }
`;

export const ContentInputBox = styled(InputBoxWeb)`
  margin-bottom: 60px;

  textarea {
    width: 100%;
    height: 232px;

    padding: 16px;

    background-color: transparent;
    border: 1px solid var(--Line-Regular_Color, #e5e5ec);
    color: var(--Font-03_Gray, #505050);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    resize: none;
  }
`;

export const SubmitButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${props => (props.disabled ? '#8fb9e5' : '#054C95')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: #fbf7f3;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
`;
