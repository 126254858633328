// import logo from 'src/assets/images/web/Logo.webp';
import facebook from 'src/assets/images/user-web/Facebook.svg';
import instagram from 'src/assets/images/user-web/Instagram.svg';
import youtube from 'src/assets/images/user-web/Youtube.svg';
import scrollTopButton from 'src/assets/images/user-web/ScrollTop.svg';
import { useEffect, useRef, useState } from 'react';
import WebInstroduction from 'src/components/user-web/WebIntroduction';
import WebGuide from 'src/components/user-web/WebGuide';
import WebProgram from 'src/components/user-web/program/WebProgram';
import * as S from 'src/pages/user-web/WebRootStyle';
import WebInquiry from 'src/components/user-web/inquiry/WebInquiry';
import WebNotice from 'src/components/user-web/notice/WebNotice';
import { IMG_BUCKET_PC } from 'src/consts/user-web.const';

function WebRoot({ festivalId }: { festivalId: number }) {
  const INIT_WIDTH = 188 * 2;
  const barRef = useRef<HTMLDivElement>(null);

  const [currentBtnId, setCurrentBtnId] = useState(1);
  const [currentMenu, setCurrentMenu] = useState('introduction');
  const [scrollTop, setScrollTop] = useState(false);

  const onClickBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { id, value } = event.currentTarget;
    setCurrentBtnId(Number(id));
    setCurrentMenu(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let offsetTop = 0;
    if (barRef.current) {
      offsetTop = barRef.current.getBoundingClientRect().top;
      const handleScroll = () => {
        if (window.scrollY > offsetTop) {
          setScrollTop(true);
        } else {
          setScrollTop(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return () => {};
  }, [barRef]);

  return (
    <S.Wrapper>
      <S.LogoContainer>
        <S.Logo
          width={INIT_WIDTH}
          src={`${IMG_BUCKET_PC}Logo.webp` || ''}
          alt="로고 이미지"
        />
      </S.LogoContainer>
      <S.TopMovingFixedBar ref={barRef} top={scrollTop}>
        <S.BtnBox>
          <S.BarBtn
            id="1"
            onClick={onClickBtn}
            current={currentBtnId === 1}
            type="button"
            value="introduction"
          >
            축제소개
          </S.BarBtn>
          <S.BarBtn
            id="2"
            onClick={onClickBtn}
            current={currentBtnId === 2}
            type="button"
            value="guide"
          >
            공연안내
          </S.BarBtn>
          <S.BarBtn
            id="3"
            onClick={onClickBtn}
            current={currentBtnId === 3}
            type="button"
            value="program"
          >
            프로그램
          </S.BarBtn>
          <S.BarBtn
            id="4"
            onClick={onClickBtn}
            current={currentBtnId === 4}
            type="button"
            value="notice"
          >
            공지사항
          </S.BarBtn>
          <S.BarBtn
            id="5"
            onClick={onClickBtn}
            current={currentBtnId === 5}
            type="button"
            value="inquiry"
          >
            문의사항
          </S.BarBtn>
        </S.BtnBox>
      </S.TopMovingFixedBar>
      <S.TopMovingFixedBarBlank top={scrollTop} />
      <S.ScrollTopButton
        src={scrollTopButton}
        alt="위로가기 이미지"
        onClick={() => window.scrollTo(0, 0)}
      />

      {currentMenu === 'introduction' && <WebInstroduction />}
      {currentMenu === 'guide' && <WebGuide />}
      {currentMenu === 'program' && <WebProgram />}
      {currentMenu === 'notice' && <WebNotice festivalId={festivalId} />}
      {currentMenu === 'inquiry' && <WebInquiry festivalId={festivalId} />}

      <S.Footer>
        <S.IconBox>
          <img
            onClick={() =>
              window.open('https://www.facebook.com/jejulhfestival/')
            }
            src={facebook}
            alt="페이스북 이미지"
          />
          <img
            onClick={() =>
              window.open('https://www.instagram.com/jejulhfestival/')
            }
            src={instagram}
            alt="인스타그램 이미지"
          />
          <img
            onClick={() =>
              window.open('https://www.youtube.com/@wowjejusi/videos')
            }
            src={youtube}
            alt="유튜브 이미지"
          />
        </S.IconBox>
        <h1>2024 제주레저힐링축제 JEJU LEISURE HEALING FESTIVAL</h1>
        <h2>
          (63208) 제주특별자치도 제주시 광양9길 10 / 대표전화：064-728-2757
          <br />
          Copyright © 2024 JEJU-SI All rights reserved.
        </h2>
      </S.Footer>
    </S.Wrapper>
  );
}

export default WebRoot;
