import { FlexBox } from 'src/styles/OverviewStyle';
import styled, { useTheme } from 'styled-components';

function ParticipantBox({
  onClickNumber,
  addPersonCount,
}: {
  onClickNumber: (type: string) => void;
  addPersonCount: number;
}) {
  const theme = useTheme();

  return (
    <BoardParticipantBox>
      <h1>추가 참여 인원</h1>
      <h2>스마트기기 사용이 어려운 미성년자만 추가 가능</h2>
      <NumberBox>
        <NumberButton
          onClick={() => onClickNumber('minus')}
          subColor={theme.colors.subColor}
        >
          <MinusDirection fontColor={theme.colors.fontColor} />
        </NumberButton>
        <Number>{addPersonCount}명</Number>
        <NumberButton
          onClick={() => onClickNumber('plus')}
          subColor={theme.colors.subColor}
        >
          <PlusDirection fontColor={theme.colors.fontColor} />
        </NumberButton>
      </NumberBox>
    </BoardParticipantBox>
  );
}

export default ParticipantBox;

const BoardParticipantBox = styled(FlexBox)`
  margin-bottom: 36px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1 {
    color: #111;
    text-align: center;
    font-family: 'Gmarket Sans TTF';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.045px;
  }
  > h2 {
    color: var(--Font-03_Gray, #505050);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    margin-bottom: 8px;
  }
`;

const NumberBox = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const NumberButton = styled(FlexBox)<{ subColor: string }>`
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  border-radius: 6px;
  background: ${({ subColor }) => subColor};
  cursor: pointer;

  justify-content: center;
  align-items: center;
`;
const PlusDirection = styled.div<{ fontColor: string }>`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid ${({ fontColor }) => fontColor};
`;
const MinusDirection = styled(PlusDirection)`
  transform: rotate(180deg);
`;

const Number = styled(FlexBox)`
  width: 48px;
  height: 40px;
  flex-shrink: 0;

  border-radius: 6px;
  background: var(--white, #fff);

  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.04px;

  justify-content: center;
  align-items: center;
`;
