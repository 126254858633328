import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import { IWebInquiryInfo } from 'src/interfaces/user-mobile/inquiry.interface';
import WebBackButton from '../WebBackButton';

function WebInquiryDetail({ inquiryInfo, setCurrentPage }: IWebInquiryInfo) {
  const onClickBackButton = () => {
    setCurrentPage('list');
  };
  return (
    <BoardInquiryDetail>
      <WebBackButtonInquiryDetail onClickBackButton={onClickBackButton} />
      <TitleBox>
        <h1>{inquiryInfo.title}</h1>
        <UserBox>
          <h2>{inquiryInfo.userId}</h2>
          <h3>{inquiryInfo.createdDate.replace('T', ' ').slice(0, 19)}</h3>
        </UserBox>
      </TitleBox>
      <ContentBox>{inquiryInfo.content}</ContentBox>
      {inquiryInfo?.answer?.length > 0 && (
        <AnswerBox>
          <h1>답변</h1>
          <p>{inquiryInfo.answer}</p>
        </AnswerBox>
      )}
    </BoardInquiryDetail>
  );
}

export default WebInquiryDetail;

const BoardInquiryDetail = styled(FlexBox)`
  width: 610px;

  margin-top: 40px;
  margin-bottom: 88px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

const WebBackButtonInquiryDetail = styled(WebBackButton)`
  align-self: flex-start;
`;

const TitleBox = styled(FlexBox)`
  width: 100%;

  border-bottom: 1px solid var(--Font-03_Gray, #505050);

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  > h1 {
    margin-bottom: 8px;

    color: #111;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.5px;
  }
`;
const UserBox = styled(FlexBox)`
  margin-bottom: 16px;
  gap: 8px;
  > h2,
  > h3 {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }
  > h2 {
    color: #111;
  }
  > h3 {
    color: var(--Font-03_Gray, #505050);
  }
`;

const ContentBox = styled.div`
  width: 100%;
  min-height: 212px;
  padding: 16px;

  line-height: 1.5;
`;

const AnswerBox = styled(FlexBox)`
  width: 100%;
  min-height: 160px;

  border-radius: 8px;
  background-color: #f5f0eb;
  padding: 20px 16px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  > h1 {
    color: #111;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  > p {
    color: #111;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.375px;

    white-space: pre-line;
  }
`;
