/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IDownWidgetInfo } from 'src/interfaces/user-mobile/main.interface';
import { activeLanguageState } from 'src/recoils/user-mobile.atom';
import getTranslateString from 'src/utils/getTranslateString';
import styled from 'styled-components';

function DownWidgetBoard({
  downWidgetList,
  onClickLink,
  item,
}: {
  downWidgetList: IDownWidgetInfo[];
  onClickLink: (e: React.MouseEvent<HTMLDivElement>) => void;
  item: IDownWidgetInfo;
}) {
  const [itemName, setItemName] = useState('');
  const lang = useRecoilState(activeLanguageState);

  useEffect(() => {
    if (item.name !== '') {
      if (localStorage.getItem('language') === 'ko') setItemName(item.name);
      else
        getTranslateString(item.name)
          .then(res => {
            setItemName(res);
          })
          .catch(() => {
            // console.log(err, item.name);
          });
    }
  }, [lang]);

  return (
    <DownWidgetBox
      length={downWidgetList.length}
      onClick={onClickLink}
      data-url={item.url}
    >
      {itemName}
    </DownWidgetBox>
  );
}
export default DownWidgetBoard;

const DownWidgetBox = styled.h1<{ length: number }>`
  width: ${props => 100 / props.length}%;
  color: var(--Font-02_black, #111);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
  border-right: 1px solid #e5e5ec;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    border-right: none;
  }
`;
