import TopFixedBarPost from 'src/components/user-mobile/post/TopFixedBarPost';
import { useEffect } from 'react';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import PostDetailBoard from 'src/components/user-mobile/post/PostDetailBoard/PostDetailBoard';

function PostDetail() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Wrapper>
      <TopFixedBarPost />
      <PostDetailBoard />
    </Wrapper>
  );
}

export default PostDetail;
