import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TopFixedBarStamp from 'src/components/user-mobile/stamp/TopFixedBarStamp';
import { stampPostUserInfoBlank } from 'src/consts/user-mobile/blank.const';
import { IStampPostUserInfo } from 'src/interfaces/user-mobile/stamp.interface';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { apiStampUser } from 'src/apis/utils/stamp';
import StampFooter from 'src/components/user-mobile/stamp/StampFooter';
import { BoardSetWidthStamp } from 'src/styles/user-mobile/StampStyle';
import StampStartForm from 'src/components/user-mobile/stamp/StampStartForm';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import setStampLocalStorage from 'src/utils/setStampLocalStorage';

function StampLogin() {
  const stampId = useLocation().state?.stampData[0]?.stampId || 0;
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(true);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [userInfo, setUserInfo] = useState<IStampPostUserInfo>({
    ...stampPostUserInfoBlank,
  });

  const mutationStampUserLogin = useMutation({
    mutationFn: ({
      id,
      name,
      phone,
    }: {
      id: number;
      name: string;
      phone: string;
    }) => apiStampUser.postStampUserLogin(id, name, phone),
    onSuccess: response => response,
  });

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const onClickSubmit = async () => {
    try {
      const response = await mutationStampUserLogin.mutateAsync({
        id: stampId,
        name: userInfo.name,
        phone: userInfo.phone,
      });

      setStampLocalStorage(
        stampId,
        response.data.uuid,
        userInfo.name,
        userInfo.phone,
        response.data.participantCount,
      );

      navigate('/stamp');
    } catch (error) {
      const typedError = error as AxiosError<{ code: string }>; // AxiosError로 캐스팅
      const { code } = typedError.response?.data || {};
      if (code === 'C004')
        alert(
          '등록되지 않은 번호이거나 이름 혹은 전화번호를 잘못 입력하셨습니다.',
        );
      // else console.log(typedError);
    }
  };

  useEffect(() => {
    if (!stampId) {
      alert(
        '스탬프 투어에 대한 정보가 없습니다.\n스탬프 시작페이지로 돌아갑니다.',
      );
      navigate('/stamp/start');
    }
  }, [stampId]);

  useEffect(() => {
    const phoneRegex = /^010\d{8}$/;
    if (userInfo.name && phoneRegex.test(userInfo.phone)) setDisabled(false);
    else setDisabled(true);

    if (phoneRegex.test(userInfo.phone)) setPhoneRequired(false);
    else setPhoneRequired(true);
  }, [userInfo]);

  return (
    <Wrapper>
      <TopFixedBarStamp />
      <BoardSetWidthStamp>
        <StampStartForm
          onChangeForm={onChangeForm}
          onClickSubmit={onClickSubmit}
          disabled={disabled}
          phoneRequired={phoneRequired}
          userInfo={userInfo}
          buttonText="시작하기"
        />
        <StampFooter />
      </BoardSetWidthStamp>
    </Wrapper>
  );
}

export default StampLogin;
