import styled from 'styled-components';

export const SliderImgBoard = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
`;

export const SliderBoard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const SliderIndex = styled.div`
  position: absolute;

  width: 40px;
  height: 28px;
  flex-shrink: 0;

  border-radius: 12px;
  background: rgba(255, 255, 255, 0.48);
  backdrop-filter: blur(2px);

  color: #111;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 200% */

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSliderBoardHome = styled(SliderBoard)<{ height: number }>`
  height: ${props => props.height - 3}px;
`;

export const SliderIndexHome = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SliderIndexDotHome = styled.div<{
  active: boolean;
  activeColor: string;
}>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => (props.active ? props.activeColor : '#fFf')};
  margin-right: 4px;
  cursor: pointer;
`;
