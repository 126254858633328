import { IGetBoothInfo } from 'src/interfaces/user-mobile/map.interface';

export function CheckDurationTime(
  boothInfo: IGetBoothInfo,
  selectDay: number,
  isNight: boolean,
) {
  let dayCheck = false;
  let timeCheck = false;

  boothInfo.durationGetDto.forEach(e => {
    if (e.day === selectDay) {
      dayCheck = true;
    }
  });
  // const time = boothInfo.operationTime;
  const time: string = 'all';

  if (isNight && time === 'evening') timeCheck = true;
  else if (!isNight && time === 'afternoon') timeCheck = true;
  else if (time === 'all') timeCheck = true;

  if (dayCheck && timeCheck) return true;
  // else return false;
}
