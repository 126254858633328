import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { useSetRecoilState } from 'recoil';
import { Helmet } from 'react-helmet';

import favicon from 'src/assets/images/user-mobile/FaviconWaba.png';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DefaultTheme } from './global/theme';
import { ITheme } from './interfaces/overview.interface';

import GlobalStyles from './global/GlobalStyles';

import WebRoot from './pages/user-web/WebRoot';
import getSubAddress from './utils/getSubAddress';

import useGetFestivalId from './hooks/useGetFestivalId';
import useSetLinkImageType from './hooks/useSetLinkImageType';
import useGetFestivalInfo from './hooks/useGetFestivalInfo';
import useStampCheck from './hooks/useStampCheck';
import useMobileCheck from './hooks/useMobileCheck';
import useAccess from './hooks/useAccess';
import { AccessDiv } from './styles/OverviewStyle';
import { ALERT_TEXT, IS_CLOSED, IS_ONLY_MOBILE } from './consts/config.const';
import { latlngState } from './recoils/map.atom';

function App() {
  const queryClient = new QueryClient();

  const subAddress = getSubAddress(IS_ONLY_MOBILE);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [faviconType, setFaviconType] = useState('image/png');
  const [access, setAccess] = useState(false);
  const [mobileUrlCheck, setMobileUrlCheck] = useState(false);
  const [festivalId, setFestivalId] = useState(0);
  const [logoSrc, setLogoSrc] = useState('');
  const [title, setTitle] = useState('');
  const [todayOf, setTodayOf] = useState(0);
  const [theme, setTheme] = useState<ITheme>(DefaultTheme);
  const setLatLng = useSetRecoilState(latlngState);

  // <link>태그의 type속성에 들어갈 이미지 타입을 추출하는 훅
  // favicon에는 이미지 경로, setFavicionType에는 string setState함수
  useSetLinkImageType(favicon, setFaviconType);

  // 스크롤 초기화
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 사이트 오픈여부 체크 + 접근비밀번호 입력하는 훅
  useAccess(IS_CLOSED, ALERT_TEXT, setAccess);

  // 웹사이트 여부 체크 + 모바일로 접속했을 때 m.으로 이동하는 훅
  useMobileCheck(IS_ONLY_MOBILE, setMobileUrlCheck);

  // subAddress통해서 festivalId 추출하는 훅
  useGetFestivalId(subAddress, festivalId, setFestivalId);

  // festivalId 추출 후 축제정보 get
  useGetFestivalInfo(
    festivalId,
    setTheme,
    setTitle,
    setLogoSrc,
    setTodayOf,
    setLatLng,
  );

  // 스탬프 화면일 때 uuid 및 stampId 체크
  useStampCheck();

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        {/* <link rel="icon" type={faviconType} href={favicon} sizes="16x16" /> */}
        {/* 16*16px의 png, jpg, jpeg만 가능 */}
      </Helmet>

      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}

        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {access ? (
            mobileUrlCheck ? (
              <Outlet
                context={{
                  festivalId,
                  logoSrc,
                  todayOf,
                }}
              />
            ) : (
              <WebRoot festivalId={festivalId} />
            )
          ) : (
            <AccessDiv />
          )}
        </ThemeProvider>

        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </div>
  );
}

export default App;
