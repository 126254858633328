import {
  STAMP_FONT,
  STAMP_SIGNUP_CAUTION_MODAL,
} from 'src/assets/database/stampData';
import { BlackBackground } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

function SignUpModal({
  setShowModal,
}: {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <BlackBackground>
      <ModalBoard>
        <h1>개인정보 수집 및 활용 동의</h1>
        <p>{STAMP_SIGNUP_CAUTION_MODAL}</p>
        <button onClick={() => setShowModal(false)} type="button">
          확인
        </button>
      </ModalBoard>
    </BlackBackground>
  );
}

export default SignUpModal;

const ModalBoard = styled.div`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  width: 295px;
  flex-shrink: 0;
  padding: 20px 16px;

  border-radius: 8px;
  background: var(--white, #fff);
  > h1 {
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: ${STAMP_FONT};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */

    margin-bottom: 12px;
  }
  > p {
    color: var(--Font-03_Gray, #505050);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.3px;
    white-space: pre-wrap;
    margin-bottom: 16px;
  }
  > button {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.mainColor};

    color: var(--white, #fff);
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
    padding: 8px 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
