import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { apiStampMission } from 'src/apis/utils/stamp';
import { stampList } from 'src/assets/database/stampData';
import BottomButtonBox from 'src/components/user-mobile/stamp/BottomButtonBox';
import TopFixedBarStamp from 'src/components/user-mobile/stamp/TopFixedBarStamp';
import { stampMissionInfoBlank } from 'src/consts/user-mobile/blank.const';
import { FlexBox } from 'src/styles/OverviewStyle';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { BoardSetWidthStamp } from 'src/styles/user-mobile/StampStyle';
import styled from 'styled-components';
import ToMapButton from 'src/components/user-mobile/stamp/ToMapButton';

function StampDetail() {
  const { completed, finished, detailIndex } = useLocation().state;
  const missionId = Number(useParams().id);
  const { data: stampMissionInfo } = useQuery({
    queryKey: ['stampMissionInfo', missionId],
    queryFn: () =>
      apiStampMission
        .getStampMission(missionId)
        .then(response => response.data),
    placeholderData: { ...stampMissionInfoBlank },
  });

  return (
    <Wrapper>
      <TopFixedBarStamp text={stampMissionInfo?.title} />
      <StyledBoardSetWidthStamp>
        <DetailThumbnailBox>
          <img src={stampList[detailIndex].detailThumbnail} alt="" />
          <ToMapButton isDetail boothId={stampMissionInfo?.boothId} />
        </DetailThumbnailBox>
        <StampInfoBoard>
          <TitleText>{stampMissionInfo?.title}</TitleText>
          <StampInfoBoardRaw>
            운영 시간&nbsp;({stampMissionInfo?.time})
          </StampInfoBoardRaw>
          <StampInfoBoardRaw>
            미션 장소 : {stampMissionInfo?.place}
          </StampInfoBoardRaw>
          <StampInfoBoardRaw>{stampMissionInfo?.content}</StampInfoBoardRaw>
        </StampInfoBoard>
        <BottomButtonBox
          isDetail
          boothId={stampMissionInfo?.boothId}
          finished={finished}
          completed={completed}
        />
      </StyledBoardSetWidthStamp>
    </Wrapper>
  );
}

export default StampDetail;

const StyledBoardSetWidthStamp = styled(BoardSetWidthStamp)`
  background-color: #fff;
  background-image: none;
`;

const DetailThumbnailBox = styled.div`
  position: relative;
  width: 80%;
  margin-bottom: 12px;
  > img {
    width: 100%;
  }
`;

const StampInfoBoard = styled(FlexBox)`
  width: 80%;
  flex-shrink: 0;

  border-radius: 12px;
  background: #f4f4f3;
  padding: 18px;
  margin-bottom: 56px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const StampInfoBoardRaw = styled(FlexBox)`
  width: 70%;
  justify-content: center;
  align-items: center;
  color: #111;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
`;

const TitleText = styled.p`
  color: ${({ theme }) => theme.colors.mainColor};

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.45px;
`;
