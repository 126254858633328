import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
`;

export const FlexBoxFullWidth = styled.div`
  display: flex;
  width: 100%;
`;

export const GridBox = styled.div`
  display: grid;
`;

export const BlackBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background: var(--bg-black-opacity-30, rgba(0, 0, 0, 0.3));
`;

export const AccessDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

export const StyledEditor = styled.div`
  width: 100%;
  .toastui-editor-popup-add-heading {
    div,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      font-size: 14px;
      color: #111;
      font-style: normal;
      line-height: 16px; /* 140% */
      border-bottom: none;
      line-height: 28px;
    }
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    div {
      font-size: 15px;
      font-weight: 400;
      line-height: 28px; /* 140% */
    }
    h1 {
      font-size: 20px;
      line-height: 48px; /* 140% */
    }
    h2 {
      font-size: 18px;
      line-height: 36px; /* 140% */
    }
    h3 {
      font-size: 16px;
      line-height: 32px; /* 140% */
    }
    h4 {
      font-size: 14px;
      line-height: 28px; /* 140% */
    }
    h5 {
      font-size: 12px;
      line-height: 24px; /* 140% */
    }
    h6 {
      font-size: 10px;
      line-height: 20px; /* 140% */
    }
  }

  .ProseMirror {
    div,
    .toastui-editor-md-strong,
    .toastui-editor-md-marked-text {
      font-weight: 400;
      font-size: 15px;
      color: #111;
      font-style: normal;
      line-height: 28px; /* 140% */
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
    .toastui-editor-md-strong {
      font-weight: 800;
    }
    .toastui-editor-md-html {
      font-size: 16px;
    }
    .toastui-editor-md-delimiter {
      font-weight: 700;
      font-size: 16px;
    }
    .toastui-editor-md-heading1,
    .toastui-editor-md-heading2,
    .toastui-editor-md-heading3,
    .toastui-editor-md-heading4,
    .toastui-editor-md-heading5,
    .toastui-editor-md-heading6 {
      font-weight: 700;
      font-size: 14px;
      color: #111;
      font-style: normal;
      line-height: 14px; /* 140% */
      border-bottom: none;
      margin: 0;
      padding: 0;
    }

    .toastui-editor-md-heading1 {
      font-size: 24px;
      line-height: 48px; /* 140% */
    }
    .toastui-editor-md-heading2 {
      font-size: 18px;
      line-height: 36px; /* 140% */
    }
    .toastui-editor-md-heading3 {
      font-size: 16px;
      line-height: 32px; /* 140% */
    }
    .toastui-editor-md-heading4 {
      font-size: 14px;
      line-height: 28px; /* 140% */
    }
    .toastui-editor-md-heading5 {
      font-size: 12px;
      line-height: 24px; /* 140% */
    }
    .toastui-editor-md-heading6 {
      font-size: 10px;
      line-height: 20px; /* 140% */
    }
  }
  .toastui-editor-contents {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      font-size: 14px;
      color: #111;
      font-style: normal;
      line-height: 28px; /* 140% */
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 28px; /* 140% */
      strong {
        font-weight: 800;
      }
    }
    h1 {
      font-size: 24px;
      line-height: 48px; /* 140% */
    }
    h2 {
      font-size: 18px;
      line-height: 36px; /* 140% */
    }
    h3 {
      font-size: 16px;
      line-height: 32px; /* 140% */
    }
    h4 {
      font-size: 14px;
      line-height: 24px; /* 140% */
    }
    h5 {
      font-size: 12px;
      line-height: 20px; /* 140% */
    }
    h6 {
      font-size: 10px;
      line-height: 20px; /* 140% */
    }

    ol,
    ul {
      margin: 8px;
    }
    ol > li::before {
      width: 28px;
      height: 28px;
      font-size: 18px;
      margin-left: -32px;
      margin-top: 3px;
    }
    ul > li::before {
      margin-left: -14px;
      margin-top: 10px;
    }
  }

  .tab-item {
    font-size: 14px;
  }
`;

export const StyledViewer = styled.div`
  width: 100%;
  padding-bottom: 40px;
  .toastui-editor-contents {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Pretendard Variable';
      font-weight: 700;
      font-size: 14px;
      color: #111;
      font-style: normal;
      line-height: 28px; /* 140% */
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px; /* 140% */
      strong {
        font-weight: 800;
      }
    }
    h1 {
      font-size: 24px;
      line-height: 48px; /* 140% */
    }
    h2 {
      font-size: 18px;
      line-height: 36px; /* 140% */
    }
    h3 {
      font-size: 16px;
      line-height: 32px; /* 140% */
    }
    h4 {
      font-size: 14px;
      line-height: 24px; /* 140% */
    }
    h5 {
      font-size: 12px;
      line-height: 20px; /* 140% */
    }
    h6 {
      font-size: 10px;
      line-height: 20px; /* 140% */
    }

    ol,
    ul {
      margin: 8px 0;
    }
    ol > li::before {
      width: 28px;
      height: 28px;
      font-size: 18px;
      margin-left: -32px;
      margin-top: 3px;
    }
    ul > li::before {
      margin-left: -14px;
      margin-top: 10px;
    }
  }
`;
