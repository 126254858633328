import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import imgUpload from 'src/assets/images/user-mobile/imageUpload.svg';

import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import { useRecoilState } from 'recoil';
import { missingInfoState } from 'src/recoils/user-mobile.atom';
import { uploadS3 } from 'src/utils/uploadS3';

interface IProps {
  title?: string;
  img?: boolean;
  id: string;
  placeholder?: string;
}
MissingInput.defaultProps = {
  title: '',
  img: false,
  placeholder: '',
};
function MissingInput({ title, img, id, placeholder }: IProps) {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useRecoilState(missingInfoState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfo({ ...info, [e.target.id]: e.target.value });
  };

  const [imagePreview, setImagePreview] = useState<string>();
  const imagesInput = useRef<HTMLInputElement>(null);

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setImagePreview('');

    if (!e.target.files) return;

    const file = e.target.files[0];
    uploadS3(file)
      .then(res => {
        setImagePreview(`${process.env.REACT_APP_S3_IMAGE}${res}`);
        setLoading(false);
        setInfo({
          ...info,
          thumbnail: `${process.env.REACT_APP_S3_IMAGE}${res}`,
        });
      })
      .catch(err => {
        alert(err.response.data.error);
        setImagePreview('');
        setLoading(false);
      });
  };

  useEffect(() => {
    setImagePreview(info.thumbnail);
  }, [info]);

  return (
    <Container>
      <MissingSemiTitle>
        {title}
        {/* <Require>{require ? '(필수)' : ''}</Require> */}
      </MissingSemiTitle>
      {img ? (
        <>
          <input
            style={{ display: 'none' }}
            accept="image/*"
            id="images"
            onChange={handleImg}
            type="file"
            ref={imagesInput}
          />
          <Flex style={{ justifyContent: 'flex-start' }}>
            <div>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '200px',
                    height: '200px',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                ''
              )}
              {imagePreview ? <ImgPreview src={imagePreview} /> : ''}
              <AddPic
                onClick={() => {
                  imagesInput.current?.click(); // Trigger file input click
                }}
              >
                <img src={imgUpload} alt="이미지 업로드" />

                <span style={{ marginLeft: '8px' }}>
                  {imagePreview ? '사진 변경' : '사진 추가'}
                </span>
              </AddPic>
            </div>
            {imagePreview ? (
              ''
            ) : (
              <PicD>
                실종 당일 사진 혹은 같은 의상을 입은 사진을 올려주세요.
              </PicD>
            )}
          </Flex>
        </>
      ) : (
        <Input
          value={
            typeof info[id] === 'boolean'
              ? '' // boolean일 경우 빈 문자열로 처리
              : String(info[id]) || '' // 그 외의 값은 문자열로 변환하여 사용, undefined일 경우 빈 문자열로 처리
          }
          id={id}
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
    </Container>
  );
}

export default MissingInput;

const Container = styled.div`
  margin-top: 20px;
`;
const PicD = styled.div`
  margin-left: 8px;
  width: 180px;
  color: var(--Font-02_black, #111);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.035px;
`;
export const MissingSemiTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 6px;
  color: var(--Font-02_black, #111);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.037px;
  display: flex;
`;
const Input = styled.input`
  width: 343px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  color: #111;
  ::placeholder {
    color: #505050;
  }
  padding-left: 16px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

// const Require = styled.div`
//   color: #53cddd;
// `;

const ImgPreview = styled.img`
  width: 180px;
  height: 240px;
`;

const AddPic = styled.button`
  width: 128px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
      border: 2px solid #f3f3f3;
    }
    50% {
      transform: rotate(180deg);
      border: 2px solid #53cddd;
    }
    100% {
      transform: rotate(360deg);
      border: 2px solid #f3f3f3;
    }
  }
  margin: 0 auto;
  width: 20px;
  height: 20px;
  animation: spin 2s ease-in-out infinite;
`;
