import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';

import { useEffect } from 'react';
import { apiConcertInfo } from 'src/apis/utils/concert';
import { concertCategoryInfoBlank } from 'src/consts/user-mobile/blank.const';
import { useLocation, useParams } from 'react-router-dom';
import TopFixedBarConcertOthers from 'src/components/user-mobile/concert/TopFixedBarConcertOthers';
import {
  BoardSetWidthInfoImageBoard,
  InfoImageBoard,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import imageBlank from 'src/assets/images/user-mobile/GrayBlank.png';
import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';
import { useQuery } from '@tanstack/react-query';

function ConcertOthersInfo() {
  const categoryName = useParams().name || '';
  const concertInfoId = useLocation().state.id;

  const { data: categoryInfo } = useQuery({
    queryKey: ['categoryInfo', concertInfoId],
    queryFn: () =>
      apiConcertInfo
        .getConcertInfo(concertInfoId)
        .then(response => response.data)
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: { ...concertCategoryInfoBlank, type: 'others' },
  });

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Wrapper>
      <TopFixedBarConcertOthers categoryName={categoryName} />
      <BoardSetWidthInfoImageBoard>
        <TopFixedBarBlank />
        <InfoImageBoard>
          {categoryInfo?.images.map((item: IImageInfo) => (
            <img
              key={item.image}
              id={item.imageId.toString()}
              src={item.image || imageBlank}
              alt="카테고리 이미지"
            />
          ))}
        </InfoImageBoard>
      </BoardSetWidthInfoImageBoard>
    </Wrapper>
  );
}

export default ConcertOthersInfo;
