import { instance } from 'src/apis/index';
import { IInquiryInfoRequest } from 'src/interfaces/user-mobile/inquiry.interface';

export const apiInquiry = {
  getInquiryListForUser: (festivalId: number) =>
    instance.get(`inquiry/forUser`, { params: { festivalId } }),

  postInquiryInfoGetForUser: (
    inquiryId: number,
    userId: string,
    password: string,
  ) =>
    instance.post(`inquiry/forUser/${inquiryId}`, {
      userId,
      password,
    }),

  postInquiryInfo: (
    festivalId: number,
    inquiryInfoRequest: IInquiryInfoRequest,
  ) => instance.post(`inquiry`, inquiryInfoRequest, { params: { festivalId } }),
};
