/* eslint-disable global-require */
import AWS from 'aws-sdk';

export function uploadS3(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const REGION = process.env.REACT_APP_REGION;
    const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
    const SECRET_ACCESS_KEY_ID = process.env.REACT_APP_SECRET_ACCESS_KEY_ID;

    AWS.config.update({
      region: REGION,
      accessKeyId: ACCESS_KEY_ID,
      secretAccessKey: SECRET_ACCESS_KEY_ID,
    });
    const { v4: uuidv4 } = require('uuid');

    const IMAGE_S3_BUCKET_SPLITED =
      process.env.REACT_APP_S3_IMAGE?.split('/') || '';

    const festivalDir = `${IMAGE_S3_BUCKET_SPLITED[IMAGE_S3_BUCKET_SPLITED.length - 2]}/`;
    // console.log(
    //   'IMAGE_S3_BUCKET_SPLITED, festivalDir',
    //   IMAGE_S3_BUCKET_SPLITED,
    //   festivalDir,
    // );

    const uuid = uuidv4();

    // "파일 본체 이름 + uuid + 확장자" => 접두사 검색만 지원하는 AWS의 지침에 대비
    const fileNameSplited = file.name.split('.');
    const fileExtension = fileNameSplited.pop();
    const imgName = fileNameSplited.join('');
    const convertedFileName = `${imgName}_${uuid}.${fileExtension}`;
    // "파일 본체 이름_uuid.확장자" 형태로 변환 완료

    // console.log(convertedFileName);

    const upload = new AWS.S3.ManagedUpload({
      params: {
        ACL: 'public-read',
        Bucket: 'waba-image',
        Key: `${festivalDir}${convertedFileName}`,
        Body: file,
      },
    });

    upload
      .promise()
      .then(() => {
        resolve(convertedFileName);
      })
      .catch(err => {
        alert('이미지 업로드에 오류가 발생했습니다. 다시 시도해주세요.');
        reject(err);
      });
  });
}
