import UserQrScanner from 'src/components/user-mobile/stamp/UserQrScanner';
import { BlackBackground, FlexBox } from 'src/styles/OverviewStyle';
import { BoardSetWidth, Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import x from 'src/assets/images/user-mobile/stamp/X2.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiStampMission, apiStampUser } from 'src/apis/utils/stamp';
import getMissionComplete from 'src/utils/getMissionComplete';
import { STAMP_BACKGROUND } from 'src/assets/database/stampData';
import { StampSuccessModal } from 'src/styles/user-mobile/StampStyle';
import { IS_STAMP_ON_SITE_GET } from 'src/consts/config.const';

function StampQr() {
  const navigate = useNavigate();
  const uuid = localStorage.getItem('uuid') || '';
  const [missionId, setMissionId] = useState(0);
  const [missionTitle, setMissionTitle] = useState('');
  const [showModal, setShowModal] = useState(false);

  const onClickCheck = () => {
    setShowModal(false);
    apiStampUser
      .getStampUser(uuid)
      .then(response => {
        if (
          getMissionComplete(
            response.data?.userMissionInfoGetListDto?.userMissionInfoGetDtos,
          )
        ) {
          if (!IS_STAMP_ON_SITE_GET)
            apiStampUser
              .patchStampUserFinish(uuid)
              .then(response2 => console.log(response2.data))
              .catch(() => {
                // console.log(err, item.name);
              });
          navigate('/stamp/complete');
        }
      })
      .catch(() => {
        // console.log(err, item.name);
      });
    navigate('/stamp');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (missionId !== 0) {
      apiStampMission
        .getStampMission(missionId)
        .then(response => setMissionTitle(response.data.title))
        .catch(error => alert(`미션 정보를 불러오지 못했습니다${error}`));
    }
  }, [missionId]);

  return (
    <Wrapper>
      <StyledBoardSetWidth>
        <XButton src={x} alt="닫기 버튼" onClick={() => navigate('/stamp')} />
        <UserQrScanner
          uuid={uuid}
          setMissionId={setMissionId}
          setShowModal={setShowModal}
        />
        <InfoText>• QR 코드를 인식시켜주세요.</InfoText>
      </StyledBoardSetWidth>

      {showModal && (
        <BlackBackground>
          <StampSuccessModal>
            <h1>
              {missionTitle}
              <br />
              미션 인증 완료
            </h1>
            <button type="button" onClick={onClickCheck}>
              확인
            </button>
          </StampSuccessModal>
        </BlackBackground>
      )}
    </Wrapper>
  );
}

export default StampQr;

const StyledBoardSetWidth = styled(BoardSetWidth)`
  position: relative;
  background-image: url(${STAMP_BACKGROUND});
  background-position: center 10%;
  justify-content: center;
`;

const InfoText = styled(FlexBox)`
  width: 180px;

  padding: 10px;
  justify-content: center;
  align-items: center;

  margin-top: 24px;

  color: #000;
  text-align: center;

  border-radius: 6px;
  background: var(--BG-White_Color, #fff);
  backdrop-filter: blur(0px);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;

const XButton = styled.img`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
`;
