import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { useEffect, useRef } from 'react';
import { StyledViewer } from 'src/styles/OverviewStyle';

function MarkdownViewer({ content }: { content: string }) {
  const refViewer = useRef<Viewer | null>(null);

  useEffect(() => {
    function toLowerExtension(markdownContent: string) {
      return markdownContent.replace(
        /!\[.*?\]\((https?:\/\/.*?\.(?:png|jpg|jpeg|gif|webp))\)/g,
        (match, url) => {
          const splitArr = url.split('.');

          splitArr[splitArr.length - 1] =
            splitArr[splitArr.length - 1].toLowerCase();

          // 확장자 소문자로 만듦
          return match.replace(url, splitArr.join('.'));
        },
      );
    }

    function encodeImageUrls(markdownContent: string): string {
      return markdownContent.replace(
        /!\[.*?\]\((https?:\/\/.*?\.(?:png|jpg|jpeg|gif|webp))\)/g,
        (match, url) => {
          const encodedUrl = encodeURI(url); // URL 전체를 인코딩

          return match.replace(url, encodedUrl);
        },
      );
    }

    const processedContent = encodeImageUrls(toLowerExtension(content));

    if (refViewer.current) {
      refViewer.current.setMarkdown(processedContent);
    } else {
      refViewer.current = new Viewer({
        el: document.querySelector('#viewer') as HTMLElement,
        initialValue: processedContent,
      });
    }
    return () => {
      if (refViewer.current) {
        refViewer.current.destroy();
        refViewer.current = null;
      }
    };
  }, [content]);

  return <StyledViewer id="viewer" />;
}

export default MarkdownViewer;
