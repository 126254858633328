import { atom } from 'recoil';
import { IMissingInfo } from 'src/interfaces/user-mobile/missing.interface';

// choi's namespace//
export const dayState = atom({
  key: 'day',
  default: 1,
});

export const isNightState = atom({
  key: 'isNight',
  default: false,
});

export const onMarkersInfoState = atom({
  // 마커 타입을 정의한다.
  key: 'onMarkerInfo',
  default: {
    id: 0,
    name: '',
    lat: 0,
    lng: 0,
  },
});
export const activeCategoryState = atom({
  key: 'activeCategory',
  default: 1,
});

export const markerPopupState = atom({
  key: 'markerPopup',
  default: 0,
});

// noh's namespace//
export const typeState = atom({
  key: 'type',
  default: 'NOTICE',
});

export const topTextState = atom({
  key: 'topText',
  default: '',
});

export const missingInfoState = atom<IMissingInfo>({
  key: 'missingInfo',
  default: {
    name: '',
    age: '아동',
    gender: '남자',
    thumbnail: '',
    missingLocation: '',
    missingTime: '',
    content: '',
    parentName: '',
    parentNo: '',
  },
});

export const isMissingState = atom({
  key: 'isMissing',
  default: true,
});

export const activeLanguageState = atom({
  key: 'activeLanguage',
  default: 'KOR',
});

export const detectRefState = atom<React.RefObject<HTMLDivElement> | null>({
  key: 'detectRef',
  default: null,
});
