import { useQuery } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { apiStamp } from 'src/apis/utils/stamp';
import {
  STAMP_DOING,
  STAMP_START_BACKGROUND,
  STAMP_START_LOGO,
  // STAMP_START_P,
  // STAMP_START_THUMBNAIL,
} from 'src/assets/database/stampData';
import TopFixedBarStamp from 'src/components/user-mobile/stamp/TopFixedBarStamp';
import { stampDataBlank } from 'src/consts/user-mobile/blank.const';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { IStampData } from 'src/interfaces/user-mobile/stamp.interface';
import { BoardSetWidth, Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import refresh from 'src/assets/images/user-mobile/stamp/Refresh.svg';
import justPrize from 'src/assets/images/user-mobile/stamp/JustPrize.svg';
import { useEffect } from 'react';
import { IS_STAMP_ON_SITE_GET } from 'src/consts/config.const';

function StampStart() {
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const navigate = useNavigate();
  const { data: stampData } = useQuery({
    queryKey: ['stampData', festivalId],
    queryFn: () =>
      apiStamp
        .getStampList(festivalId)
        .then(response => response.data.stampGetDtos)
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: [{ ...stampDataBlank }] as IStampData[],
  });

  const onClickClear = () => {
    const tmp = window.confirm(
      '"name, phone, uuid, participantCount, stampVisited"\n위 localStorage항목들을 삭제하시겠습니까?',
    );
    if (tmp) {
      localStorage.removeItem('name');
      localStorage.removeItem('phone');
      localStorage.removeItem('uuid');
      localStorage.removeItem('participantCount');
      localStorage.removeItem('stampVisited');
    }
  };

  // 모바일에서 화면이 100vh가 안 되는 버그 임시 방편 해결
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <TopFixedBarStamp isStart navigateText="/" />
      <StyledBoardSetWidth>
        {/* <ThumbnailImage
          src={STAMP_START_THUMBNAIL}
          alt="스탬프 썸네일 이미지"
        /> */}
        <LogoImage src={STAMP_START_LOGO} />
        {/* <p>{STAMP_START_P}</p> */}
        <StartButton
          active={stampData[0]?.stampOn}
          onClick={() =>
            stampData[0]?.stampOn
              ? navigate('/stamp/sign_up', { state: { stampData } })
              : alert('금일 스탬프 투어 신규 등록은 마감되었습니다.')
          }
          type="button"
        >
          {STAMP_DOING}&nbsp;시작하기
        </StartButton>
        <ContinueButton
          onClick={() => {
            if (localStorage.getItem('uuid')) navigate('/stamp');
            else navigate('/stamp/login', { state: { stampData } });
          }}
        >
          {STAMP_DOING}&nbsp;이어가기
        </ContinueButton>
      </StyledBoardSetWidth>
      {!IS_STAMP_ON_SITE_GET && (
        <PrizeButton onClick={() => navigate('/stamp/prize')}>
          <img src={justPrize} alt="" />
          경품 안내
        </PrizeButton>
      )}
      {process.env.NODE_ENV === 'development' && (
        <ClearLocalStorageButton onClick={onClickClear}>
          <img src={refresh} alt="" />
        </ClearLocalStorageButton>
      )}
    </Wrapper>
  );
}

export default StampStart;

const StyledBoardSetWidth = styled(BoardSetWidth)`
  max-height: 100vh;

  background-image: url(${STAMP_START_BACKGROUND});
  background-size: cover;
  background-position: center;

  justify-content: center;
  > p {
    color: #fff;
    text-align: center;
    font-family: 'Gmarket Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    white-space: pre-wrap;
    margin-bottom: 24px;
  }
  > button {
    width: 176px;
    height: 56px;
    flex-shrink: 0;

    border-radius: 28px;
    box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.32);

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
`;

// const ThumbnailImage = styled.img`
//   width: 240px;
//   height: 240px;
//   margin-bottom: 52px;
// `;

const LogoImage = styled.img`
  width: 230px;
  height: auto;
  margin-bottom: 24px;
`;

const StartButton = styled.button<{ active: boolean }>`
  background: ${({ theme, active }) =>
    active ? theme.colors.mainColor : '#F1F1F5'};
  color: ${({ active }) => (active ? 'var(--white, #fff)' : '#999')};
  margin-bottom: 8px;
`;

const ContinueButton = styled.button`
  background: var(--white, #fff);
  color: #111;
`;

const ClearLocalStorageButton = styled.button`
  position: fixed;
  left: 48px;
  bottom: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.32);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrizeButton = styled.button`
  position: fixed;
  bottom: 20px;
  padding: 10px 16px;

  color: var(--Font-02_black, #111);
  border-radius: 64px;
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
  background: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;

  display: flex;
  align-items: center;
  gap: 6px;
`;
