import { IThemeColors } from 'src/interfaces/overview.interface';
import { Flex, RoundBox } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import grayBlankDiv from 'src/assets/images/user-mobile/GrayBlankDiv.svg';
import { IHomeMainMenu } from 'src/interfaces/user-mobile/overview.interface';
import {
  activeLanguageState,
  topTextState,
} from 'src/recoils/user-mobile.atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import getTranslateString from 'src/utils/getTranslateString';

function HomeMainMenu({
  mainMenus,
  fontColor,
  subColor,
  onClickLink,
}: IHomeMainMenu) {
  const setTopText = useSetRecoilState(topTextState);

  const [menuName1, setMenuName1] = useState('지도');
  const [menuSummary1, setMenuSummary1] = useState('');
  const [menuName2, setMenuName2] = useState('정보');
  const [menuSummary2, setMenuSummary2] = useState('');

  const [BtnContent1, setBtnContent1] = useState(`${menuName1} 보기`);
  const [BtnContent2, setBtnContent2] = useState(`${menuName2} 확인`);
  const lang = useRecoilValue(activeLanguageState);

  useEffect(() => {
    if (localStorage.getItem('language') !== 'ko') {
      if (mainMenus?.menuName1 !== '' && mainMenus?.menuName1 !== null) {
        getTranslateString(mainMenus?.menuName1).then(res => setMenuName1(res));
      } else getTranslateString('지도').then(res => setMenuName1(res));
      if (mainMenus?.menuSummary1 !== '' && mainMenus?.menuSummary1 !== null)
        getTranslateString(mainMenus?.menuSummary1).then(res =>
          setMenuSummary1(res),
        );
      if (mainMenus?.menuName2 !== '' && mainMenus?.menuName2 !== null)
        getTranslateString(mainMenus?.menuName2).then(res => setMenuName2(res));
      else getTranslateString('지도').then(res => setMenuName2(res));
      if (mainMenus?.menuSummary2 !== '' && mainMenus?.menuSummary2 !== null)
        getTranslateString(mainMenus?.menuSummary2)
          .then(res => setMenuSummary2(res))
          .catch(() => {
            // console.log(err, item.name);
          });

      if (mainMenus?.menuName1 !== '' && mainMenus?.menuName1 !== null)
        getTranslateString(`${mainMenus.menuName1?.split(' ')[1]} 보기`).then(
          res => setBtnContent1(res),
        );
      if (mainMenus?.menuName2 !== '' && mainMenus?.menuName2 !== null)
        getTranslateString(`${mainMenus.menuName2?.split(' ')[1]} 확인`).then(
          res => setBtnContent2(res),
        );
    } else {
      setMenuName1(mainMenus.menuName1);
      setMenuName2(mainMenus.menuName2);
      setMenuSummary1(mainMenus.menuSummary1);
      setMenuSummary2(mainMenus.menuSummary2);
      setBtnContent1(`${mainMenus.menuName1?.split(' ')[1]} 보기`);
      setBtnContent2(`${mainMenus.menuName2?.split(' ')[1]} 확인`);
    }
  }, [lang, mainMenus]);

  return (
    <MainMenuBox fontColor={fontColor} subColor={subColor}>
      <MainMenuElement>
        <Flex>
          <RoundBox
            src={mainMenus?.menuImage1 ? mainMenus?.menuImage1 : grayBlankDiv}
          />
          <MainMenuText>
            <h1>{menuName1}</h1>
            <h2>{menuSummary1}</h2>
          </MainMenuText>
        </Flex>

        <button
          type="button"
          onClick={event => {
            setTopText(menuName1);
            onClickLink(event);
          }}
          data-url={mainMenus?.menuUrl1}
        >
          {BtnContent1}
        </button>
      </MainMenuElement>

      <HR />

      <MainMenuElement>
        <Flex>
          <RoundBox
            src={mainMenus?.menuImage2 ? mainMenus?.menuImage2 : grayBlankDiv}
          />
          <MainMenuText>
            <h1>{menuName2}</h1>
            <h2>{menuSummary2}</h2>
          </MainMenuText>
        </Flex>

        <button
          type="button"
          onClick={event => {
            setTopText(menuName2);
            onClickLink(event);
          }}
          data-url={mainMenus?.menuUrl2}
        >
          {BtnContent2}
        </button>
      </MainMenuElement>
    </MainMenuBox>
  );
}

export default HomeMainMenu;

const HR = styled.div``;

const MainMenuElement = styled.div``;
const MainMenuText = styled(Flex)``;
const MainMenuBox = styled.div<IThemeColors>`
  width: 100%;
  flex-shrink: 0;

  border-radius: 16px;
  background: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px 16px 20px 16px;
  ${MainMenuElement} {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${MainMenuText} {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      > h1 {
        color: #111;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 144.444% */

        margin-left: 12px;
      }
      > h2 {
        color: var(--Font-03_Gray, #505050);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.35px;
        margin-left: 12px;
      }
    }

    > button {
      border-radius: 6px;
      background: ${props => props.subColor};

      color: ${props => props.fontColor};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */

      display: inline-flex;
      padding: 8px 12px;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }

  ${HR} {
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background-color: #ddd;

    margin: 20px;
  }
`;
