import {
  STAMP_START_LOGO,
  STAMP_PRIZE_TEXT,
  stampPrizeList,
  STAMP_FONT,
} from 'src/assets/database/stampData';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import {
  BoardSetWidthStamp,
  CloseButton,
} from 'src/styles/user-mobile/StampStyle';
import styled from 'styled-components';
import x from 'src/assets/images/user-mobile/stamp/X2.svg';
import { useNavigate } from 'react-router-dom';
import { FlexBox } from 'src/styles/OverviewStyle';

function StampPrize() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <StyledBoardSetWidthStamp>
        <XButton onClick={() => navigate(-1)} src={x} alt="스탬프 홈" />
        <CompleteMainBoard>
          <LogoImage src={STAMP_START_LOGO} />
          <ContentText>{STAMP_PRIZE_TEXT}</ContentText>
          <PrizeBoard>
            <PrizeBoardText>스탬프투어 경품</PrizeBoardText>
            <PrizeBox>
              {stampPrizeList.map((item, index) => (
                <PrizeBoxText key={index.toString()}>
                  <span>{index + 1}등.</span>
                  &nbsp;
                  {item.prize}
                </PrizeBoxText>
              ))}
            </PrizeBox>
          </PrizeBoard>
        </CompleteMainBoard>
        <CloseButton onClick={() => navigate(-1)}>닫기</CloseButton>
      </StyledBoardSetWidthStamp>
    </Wrapper>
  );
}

export default StampPrize;

const StyledBoardSetWidthStamp = styled(BoardSetWidthStamp)`
  position: relative;
  padding: 0;
`;

const CompleteMainBoard = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

const LogoImage = styled.img`
  width: 160px;
  height: auto;
  margin-bottom: 24px;
`;

const XButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const PrizeBoard = styled(FlexBox)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PrizeBoardText = styled.h1`
  margin-bottom: 8px;

  color: #111;
  text-align: center;
  font-family: ${STAMP_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
const PrizeBox = styled(FlexBox)`
  width: 280px;
  height: 140px;
  flex-shrink: 0;

  padding: 18px 20px;

  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.fontColor};
  background: #fff;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;
const PrizeBoxText = styled.p`
  width: 100%;
  color: #111;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;

  text-align: left;
  span {
    color: ${({ theme }) => theme.colors.mainColor};
  }
`;

const ContentText = styled.h2`
  width: 280px;
  color: #111;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 48px;
  white-space: pre-line;
  span {
    color: ${({ theme }) => theme.colors.fontColor};
    font-weight: 600;
  }
`;
