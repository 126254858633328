import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

function useMobileCheck(
  isOnlyMobile: boolean,
  setMobileUrlCheck: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const { hostname } = window.location;

  // 서브도메인에 'm.' 존재
  useEffect(() => {
    if (isOnlyMobile) setMobileUrlCheck(true);
    else if (hostname.startsWith('m.')) setMobileUrlCheck(true);
    else if (isMobile) {
      // m.이 없는데 모바일로 접속한 경우
      const newUrl = `${window.location.protocol}//m.${window.location.host}${window.location.pathname}${window.location.search}`;
      window.location.replace(newUrl);
      // replace를 사용하여 브라우저 기록을 남기지 않고 URL 변경, 강제 m.으로 이동
    }
  }, [hostname]);
}
export default useMobileCheck;
