import { MutableRefObject } from 'react';
import { IGetBoothInfo } from 'src/interfaces/user-mobile/map.interface';

export const handleMarker = (
  mapRef: MutableRefObject<naver.maps.Map | undefined>,
  info: IGetBoothInfo,
  setPopup: React.Dispatch<React.SetStateAction<number>>,
) => {
  // console.log(onMarker, info);
  // if (onMarker.current) {
  //   setMarkerOff(onMarker.current, onMarkerInfo.current); // 켜져있는 마커 존재하는 경우에만
  // }
  // onMarker.current = marker; // onMarker의 상태업데이트는 비동기로 이루어진다.
  // onMarkerInfo.current = info;
  // setMarkerOn(marker, info);
  setPopup(info.id);
  const location = new naver.maps.LatLng(info.latitude, info.longitude);
  mapRef.current?.panTo(location, {
    duration: 700,
    easing: 'easeOutCubic',
  });
};
