import { useLocation } from 'react-router-dom';
import TopFixedBarLostItem from 'src/components/user-mobile/lost-item/TopFixedBarLostItem';
import { ILostItem } from 'src/interfaces/user-mobile/lostItem.interface';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';

function LostItemDetail() {
  const location = useLocation();
  const data: ILostItem = location.state.props;

  const handleTel = () => {
    alert('축제 기간 중 업데이트 예정입니다.');
    // document.location.href = 'tel:0612448729';
  };

  return (
    <Wrapper>
      <TopFixedBarLostItem text="분실물 세부 정보" />
      <Container>
        <Img src={data.thumbnail} />
        <Tag>
          <Category>{data.type}</Category>
          <Name style={{ marginTop: '2px' }}>{data.name}</Name>
          <Date style={{ marginTop: '4px' }}>발견날짜:{data.findDate}</Date>
        </Tag>

        <Btn onClick={handleTel}>분실물 센터 연락하기</Btn>
        {/* <Tel>연락처: 061-244-8729</Tel> */}
      </Container>
    </Wrapper>
  );
}
export default LostItemDetail;
const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-bottom: 20px;
`;
const Category = styled.div`
  color: #f23d37;

  /* body1 */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
const Name = styled.div`
  color: #111;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
`;
const Img = styled.img`
  margin-top: 64px;
  width: 343px;
  height: 460px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  background: #fff;
`;

const Tag = styled.div`
  width: 343px;
  border-radius: 0 0 8px 8px;
  background: #fff;
  box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.24);

  padding: 16px 16px;
`;

const Date = styled.div`
  color: #505050;

  /* body3 */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Btn = styled.button`
  margin-top: 32px;

  width: 343px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #ff5a5a;

  color: #fff;
  text-align: center;
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

// const Tel = styled.div`
//   color: #777;
//   text-align: center;
//   margin-top: 4px;

//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px; /* 142.857% */
// `;
