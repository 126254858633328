export const agreeText = `가. 수집 및 이용 목적
 - 페스티맵에서 제공하는 “실종자 찾기"에 필요한 사항(실종자 정보, 보호자 정보 등)에 대하여 실종자 찾기 서비스 제공을 위하여 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다.

나. 수집 및 이용 항목
  1) 필수항목 : 실종자 이름, 실종자 연령대, 실종자 성별, 실종자 사진, 보호자 이름, 보호자 연락처

실종자 찾기 신청하기 버튼 선택 시 페스티맵이 위와 같이 개인정보 필수항목을 수집ㆍ이용하는 것에 동의하는 것으로 간주합니다.

다. 개인정보의 보유 및 이용 기간
  - 참여자의 개인정보 수집ㆍ이용에 관한 동의일로부터 2024제주레저힐링축제 종료 시까지 위 이용목적을 위하여 보유 및 이용하게 됩니다. 단, 축제 종료 후에는 경품 증정, 민원처리, 분쟁해결 및 법령상 의무이행 등을 위하여 1년간 보유하게 됩니다.

라. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
  - 위 개인정보 중 필수정보의 수집ㆍ이용에 관한 동의는 “실종자 찾기" 진행을 위해 필수적이므로, 위 사항에 동의하셔야만 실종자 찾기 서비스 제공이 가능합니다.`;
