import { useNavigate } from 'react-router-dom';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';

import { BkBtn, TopFixedBar } from 'src/styles/user-mobile/TopFixedBarStyle';
import styled from 'styled-components';

function TopFixedBarPost() {
  const navigate = useNavigate();
  return (
    <StyledTopFixedBar>
      <BkBtn onClick={() => navigate(-1)} src={bkBtn2} />
      {/* <MenuBtn src={menuBtn}/> */}
    </StyledTopFixedBar>
  );
}

export default TopFixedBarPost;

const StyledTopFixedBar = styled(TopFixedBar)`
  background-color: #fff;
  position: fixed;
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
  padding: 16px;

  border-bottom: 1px solid #ededf0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
