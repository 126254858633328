import { useEffect } from 'react';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import InquiryDetailBoard from 'src/components/user-mobile/inquiry/InquiryDetailBoard/InquiryDetailBoard';
import TopFixedBarInquiry from 'src/components/user-mobile/inquiry/TopFixedBarInquiry';

function InquiryDetail() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Wrapper>
      <TopFixedBarInquiry text="문의사항" />
      <InquiryDetailBoard />
    </Wrapper>
  );
}

export default InquiryDetail;
