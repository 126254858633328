import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  CategoryBoxProps,
  ICategoryInfo,
} from 'src/interfaces/user-mobile/map.interface';
import { activeCategoryState } from 'src/recoils/user-mobile.atom';
import getTranslateString from 'src/utils/getTranslateString';
import styled from 'styled-components';

function CategoryBox({ categoryInfo }: { categoryInfo: ICategoryInfo }) {
  const [activeCategory, setActiveCategory] =
    useRecoilState(activeCategoryState);
  const [categoryName, setCategoryName] = useState('');
  const handleCategory = (id: number) => {
    setActiveCategory(id);
  };

  useEffect(() => {
    if (localStorage.getItem('language') !== 'KOR')
      getTranslateString(categoryInfo.categoryName).then(translateRes => {
        setCategoryName(translateRes);
      });
    else setCategoryName(categoryInfo.categoryName);
  }, []);

  return (
    <Box
      active={activeCategory === categoryInfo.mapCategoryId}
      onClick={() => handleCategory(categoryInfo.mapCategoryId)}
    >
      <h1>{categoryName}</h1> <PinImg src={categoryInfo.pin} />
    </Box>
  );
}

export default CategoryBox;
const Box = styled.div<CategoryBoxProps>`
  margin-left: 6px;

  border-radius: 24px;

  border-color: ${({ active, theme }) =>
    active ? theme.colors.mainColor : '#DADADA'};
  background: ${({ active, theme }) =>
    active ? theme.colors.mainColor : '#FFF'};

  display: flex;
  padding: 8px 12px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  color: ${({ active }) => (active ? '#FFF' : '#111')};
  h1 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;
const PinImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 2px;
`;
