import { IThemeColors } from 'src/interfaces/overview.interface';
import styled from 'styled-components';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import { Flex } from './OverviewStyle';

export const TopFixedBlock = styled(Flex)`
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
  position: fixed;
  z-index: 100;
  flex-direction: column;
`;

export const BkBtn = styled.img`
  margin-right: 14px;
  cursor: pointer;
`;

export const TopFixedBar = styled.div<IThemeColors>`
  position: fixed;
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
  height: 52px;
  top: 0;
  z-index: 10;

  background-color: ${props => props.backgroundColor};

  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: #111;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const SelectBar = styled.div`
  position: relative;

  width: 100%;
  height: 46px;
  flex-shrink: 0;

  border-bottom: 1px solid #dadada;
  background: #fff;
`;

export const SelectBarBlank = styled(SelectBar)`
  background-color: transparent;
`;

export const TopFixedBarHome = styled(TopFixedBar)<{ mainBlockWidth: number }>`
  position: relative;
  width: ${props => props.mainBlockWidth}px;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 358px;
  }
`;

export const TopFixedBarBlank = styled(TopFixedBar)`
  position: relative;
  z-index: -10;
`;
