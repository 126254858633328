import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import {
  BkBtn,
  TopFixedBar,
  TopFixedBlock,
} from 'src/styles/user-mobile/TopFixedBarStyle';

function TopFixedBarLostItem({ text }: { text: string }) {
  const navigate = useNavigate();

  return (
    <TopFixedBlock>
      <StyledTopFixedBar>
        <Flex style={{ marginLeft: '16px' }}>
          <BkBtn onClick={() => navigate(-1)} src={bkBtn2} />
          <h1>{text}</h1>
        </Flex>
      </StyledTopFixedBar>
    </TopFixedBlock>
  );
}

export default TopFixedBarLostItem;

const StyledTopFixedBar = styled(TopFixedBar)`
  position: relative;
  background-color: white;
  border-bottom: 1px solid #ededf0;
  color: var(--Font-02_black, #111);

  button {
    display: inline-flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;

    color: ${({ theme }) => theme.colors.fontColor};

    border-radius: 6px;
    background: ${({ theme }) => theme.colors.subColor};

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    margin-right: 16px;
  }
`;
