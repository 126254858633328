import { useEffect } from 'react';

function useAccess(
  isClosed: boolean,
  alertText: string,
  setAccess: React.Dispatch<React.SetStateAction<boolean>>,
) {
  useEffect(() => {
    if (isClosed) {
      // 사이트가 닫혀있는 경우
      const { pathname } = window.location;
      if (pathname === '/access') {
        // access페이지 접속 시
        const tmp = window.prompt('password');
        const password = process.env.REACT_APP_ACCESS_PASSWORD;
        if (tmp === password) {
          // 비밀번호 일치 시
          sessionStorage.setItem('accessFestival', 'true');
          window.location.href = '/';
        } else {
          alert('잘못된 비밀번호입니다.');
        }
      } else if (sessionStorage.getItem('accessFestival') !== 'true')
        // access페이지 접속이 아닌데 비밀번호 입력을 안한 경우
        alert(alertText);
      else setAccess(true);
      // access페이지에서 비밀번호 입력을 성공적으로 마친 경우
    } else setAccess(true);
  }, []);
}

export default useAccess;
