import FullInfo from 'src/components/user-mobile/map/click/full/FullInfo';
import FullMenu from 'src/components/user-mobile/map/click/full/FullMenu';
import Close from 'src/components/user-mobile/map/click/full/Close';

import styled from 'styled-components';
import FullBasic from 'src/components/user-mobile/map/click/full/FullBasic';
import { IGetBoothSingle } from 'src/interfaces/user-mobile/map.interface';

function MapFull({
  setIsFull,
  data,
}: {
  setIsFull: React.Dispatch<React.SetStateAction<boolean>>;
  data: IGetBoothSingle;
}) {
  return (
    <Container>
      <FullInfo {...data} />
      <DivideLine />
      {(data.menus?.length || 0) === 0 ? ( // undefined인 경우에는 0과 비교연산 불가, so '|| 0' 추가
        <FullBasic content={data.content || ''} />
      ) : (
        <FullMenu menus={data.menus || []} />
      )}

      <Close setIsFull={setIsFull} />
    </Container>
  );
}
export default MapFull;

const Container = styled.div`
  width: 100%; // 전체 너비에서 좌우 마진만큼 빼서 계산
  height: calc(var(--vh, 1vh) * 100);

  flex-shrink: 0;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.12);

  position: absolute;
  bottom: 0;

  overflow: scroll;

  z-index: 1400;
`;

const DivideLine = styled.div`
  width: 100%;
  height: 8px;
  flex-shrink: 0;
  background: var(--Button-Light_Color, #f7f7fb);
`;
