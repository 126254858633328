import { FlexBox, GridBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import forward from 'src/assets/images/user-web/Forward.svg';
import backward from 'src/assets/images/user-web/Backward.svg';
import { landLeisureList, seaLeisureList } from 'src/assets/database/webData';
import { ProgramReserveButton } from 'src/styles/user-web/OverviewStyle';

function WebProgramLeisure() {
  return (
    <BoardProgramLeisure>
      <ProgramReserveButton
        onClick={() =>
          window.open(
            'https://map.naver.com/p/entry/place/1510559789?c=13.27,0,0,0,dh&placePath=/ticket',
          )
        }
      >
        <img src={forward} alt="버튼 이미지" />
        <h1>레저 프로그램 예약하기</h1>
        <img src={backward} alt="버튼 이미지" />
      </ProgramReserveButton>
      <GridBoardLeisure style={{ marginBottom: '100px' }}>
        <h1>해양 레저 스포츠 체험</h1>
        <GridBoxLeisure>
          {seaLeisureList.map(item => (
            <LeisureBox key={item.id}>
              <img src={item.thumbnail} alt="샘플 이미지" />
              {item.url?.length > 0 ? (
                <LeisureBoxReserveButton onClick={() => window.open(item.url)}>
                  예약하기
                </LeisureBoxReserveButton>
              ) : (
                <LeisureBoxFieldButton>현장신청</LeisureBoxFieldButton>
              )}
            </LeisureBox>
          ))}
        </GridBoxLeisure>
      </GridBoardLeisure>

      <GridBoardLeisure>
        <h1>육상 레저 스포츠 체험</h1>
        <GridBoxLeisure>
          {landLeisureList.map(item => (
            <LeisureBox key={item.id}>
              <img src={item.thumbnail} alt="샘플 이미지" />
              {item.url?.length > 0 ? (
                <LeisureBoxReserveButton onClick={() => window.open(item.url)}>
                  예약하기
                </LeisureBoxReserveButton>
              ) : (
                <LeisureBoxFieldButton>현장신청</LeisureBoxFieldButton>
              )}
            </LeisureBox>
          ))}
        </GridBoxLeisure>
      </GridBoardLeisure>
    </BoardProgramLeisure>
  );
}

export default WebProgramLeisure;

const BoardProgramLeisure = styled(FlexBox)`
  width: 100vw;

  margin-bottom: 160px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const GridBoardLeisure = styled(FlexBox)`
  width: 1109px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > h1 {
    margin-bottom: 28px;
    color: #111;
    text-align: center;
    font-family: Jalnan;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    letter-spacing: -0.07px;
  }
`;

const GridBoxLeisure = styled(GridBox)`
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(
    100px,
    auto
  ); /* 행의 최소 높이를 100px로 설정하고 내용에 따라 자동으로 조정 */
  column-gap: 40px;
  row-gap: 32px;
`;

const LeisureBox = styled(FlexBox)`
  position: relative;

  width: 343px;
  height: 264px;
  flex-shrink: 0;

  > img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }
`;
const LeisureBoxReserveButton = styled.button`
  position: absolute;
  bottom: 49px;
  right: 24px;

  border-radius: 6px;
  border: 1px solid #f2744f;
  color: #e25228;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.3px;
  padding: 3px 8px;
`;
const LeisureBoxFieldButton = styled.button`
  position: absolute;
  bottom: 49px;
  right: 24px;

  border-radius: 6px;
  border: 1px solid var(--Font-04_Gray, #767676);
  color: #111;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.3px;
  padding: 3px 8px;
  cursor: default;
`;
