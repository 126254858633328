import { IMissingInfo } from 'src/interfaces/user-mobile/missing.interface';
import { instance } from '..';

export const apiMissing = {
  getAll: () => instance.get('/missingPerson'),

  post: (missingInfo: IMissingInfo, festivalId: number) =>
    instance.post('/missingPerson', missingInfo, {
      params: { festivalId },
    }),

  getPerson: (missingId: number | undefined) =>
    instance.get(`/missingPerson/${missingId}`),
};
