import TopFixedBarLostItem from 'src/components/user-mobile/lost-item/TopFixedBarLostItem';
import { Flex, Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { ILostItem } from 'src/interfaces/user-mobile/lostItem.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiLostItem } from 'src/apis/utils/lost-item';
import * as S from './LostStyle';

function LostItemHome() {
  const navigate = useNavigate();
  const [data, setData] = useState<ILostItem[]>([]);

  useEffect(() => {
    window.scrollTo(0, -200);
    apiLostItem.getAll().then(res => {
      setData(res.data.lostItems);
    });
  }, []);

  const handleRouteDetail = (lostItemInfo: ILostItem) => {
    navigate(`${lostItemInfo.id}`, { state: { props: lostItemInfo } });
  };
  return (
    <Wrapper>
      <TopFixedBarLostItem text="분실물 리스트" />
      <Flex style={{ width: '100%' }}>
        <S.Container>
          {data?.length !== 0 ? (
            data.map((lostItemInfo: ILostItem) => {
              return (
                <div
                  role="presentation"
                  onClick={() => handleRouteDetail(lostItemInfo)}
                >
                  <S.Box alt="분실물 사진" src={lostItemInfo.thumbnail} />
                  <S.Tag>
                    <S.Category>{lostItemInfo.type}</S.Category>
                    <S.Name>{lostItemInfo.name}</S.Name>
                  </S.Tag>
                </div>
              );
            })
          ) : (
            <S.EmptyText>보관 중인 분실물이 없습니다</S.EmptyText>
          )}
        </S.Container>
      </Flex>
    </Wrapper>
  );
}

export default LostItemHome;
