import { IGetBoothSingle } from 'src/interfaces/user-mobile/map.interface';

export const IGetBoothSingleBlank: IGetBoothSingle = {
  name: '',
  summary: '',
  content: '',
  location: '',
  latitude: 0,
  longitude: 0,
  operationHours: '',
  operationTime: 'afternoon',
  thumbnail: '',
  menus: [
    {
      name: '',
      price: 0,
      image: '',
      summary: '',
    },
  ],
  type: '',
  icon: '',
  durations: [
    {
      durationId: 0,
      date: '2024-05-15',
      day: 0,
    },
  ],
};
