import { useState } from 'react';
import { agreeText } from 'src/assets/database/agreeData';
import styled from 'styled-components';
import { Btn } from './SuccessPopup';

function AgreePopup() {
  const [isPopup, setIsPopup] = useState(false);
  const handlePopup = () => {
    setIsPopup(!isPopup);
  };
  return (
    <>
      {isPopup && (
        <>
          <PopupOverlay />
          <PopupBox>
            <p>개인정보 수집 및 활용 동의</p>
            <PopupText disabled style={{ color: '#505050', width: '263px' }}>
              {agreeText}
            </PopupText>
            <Btn
              onClick={handlePopup}
              style={{ marginTop: '12px', width: '263px' }}
            >
              확인
            </Btn>
          </PopupBox>
        </>
      )}
      <AgreePopupContainer>
        <AgreeText>
          신청 시,&nbsp;
          <UnderLine onClick={handlePopup}>개인정보 수집</UnderLine>에 동의하게
          됩니다.
        </AgreeText>
      </AgreePopupContainer>
    </>
  );
}

export default AgreePopup;
const AgreePopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; // 부모 컨테이너의 가로 폭을 모두 사용
`;
const AgreeText = styled.div`
  display: flex;

  color: var(--Font-04_Gray, #767676);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;

const UnderLine = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const PopupBox = styled.div`
  padding: 16px;
  position: fixed;

  display: flex;
  flex-direction: column;

  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 295px;
  height: 532px;
  z-index: 1000;
  border-radius: 8px;
  background: var(--white, #fff);
  p {
    color: #111;
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
  }
`;

const PopupText = styled.textarea`
  overflow: hidden;
  background-color: #fff;
  border: 0;
  width: 270px;
  height: 400px;
  flex-shrink: 0;
  margin-top: 20px;
  color: #505050;
  font-family: 'Pretendard Variable';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.3px;
`;
export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
