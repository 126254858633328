import { INIT_MAP_OPTION } from 'src/consts/map/overview.const';
import { MutableRefObject } from 'react';
import { IGetBoothInfo } from 'src/interfaces/user-mobile/map.interface';
import { SetterOrUpdater } from 'recoil';
import {
  fixedImgStyle,
  markerStyle,
  textStyle,
} from 'src/consts/map/style.const';
import { getMarkerSize } from './getMarkerSize';
import { handleMarker } from './handleMarker';
import { CheckDurationTime } from './CheckDurationTime';
import getTranslateString from '../getTranslateString'; // Import the translation function

export const makeFixedMarker = async (
  mapRef: MutableRefObject<naver.maps.Map | undefined>,
  day: number,
  isNight: boolean,
  zoom: number,
  setFixedMarkers: SetterOrUpdater<naver.maps.Marker[]>,
  setPopup: React.Dispatch<React.SetStateAction<number>>,
  fixedBoothList: IGetBoothInfo[] = [],
) => {
  const tmpMarkers = await Promise.all(
    fixedBoothList.map(async (booth: IGetBoothInfo) => {
      const [width, height] = getMarkerSize(booth);

      // 비동기적으로 booth.name을 번역
      const boothName = await getTranslateString(booth.name);

      if (CheckDurationTime(booth, day, isNight)) {
        const marker = new naver.maps.Marker({
          position: new naver.maps.LatLng(booth.latitude, booth.longitude),
          map: mapRef.current,
          icon: {
            content: `
                  <div style="${markerStyle}">
                    <img style="${fixedImgStyle}" src=${booth.icon} />
                    ${zoom > INIT_MAP_OPTION.zoom ? `<div style="${textStyle}">${boothName}</div>` : ''}
                  </div>
                    `,
            size: new naver.maps.Size(width, height),
            anchor: new naver.maps.Point(width / 2, height / 2),
          },
        });

        naver.maps.Event.addListener(marker, 'click', () => {
          handleMarker(mapRef, booth, setPopup);
        });
        return marker;
      }
      return null;
    }),
  );

  const filteredMarkers = tmpMarkers.filter(
    (marker): marker is naver.maps.Marker => marker !== null,
  );

  setFixedMarkers((prevMarkers: naver.maps.Marker[]) => {
    if (prevMarkers !== undefined) {
      prevMarkers.forEach(e => {
        e.setMap(null);
      });
    }
    return filteredMarkers || [];
  });
};
