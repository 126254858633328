import { BkBtn, TopFixedBar } from 'src/styles/user-mobile/TopFixedBarStyle';
import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function TopFixedBarSchedule() {
  const navigate = useNavigate();
  return (
    <StyledTopFixedBar>
      <BkBtn onClick={() => navigate(-1)} src={bkBtn2} />
    </StyledTopFixedBar>
  );
}

export default TopFixedBarSchedule;

const StyledTopFixedBar = styled(TopFixedBar)`
  background-color: #fff;
  position: fixed;
  width: 100%;
  @media screen and (min-width: 1280px) {
    display: none;
  }
  padding: 12px 16px;
  border-bottom: 1px solid #ededf0;
`;
