import styled from 'styled-components';
import { FlexBox } from '../OverviewStyle';

export const ContainerFooter = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 24px 16px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FooterIconBox = styled(FlexBox)`
  margin-bottom: 12px;
  justify-content: center;
  align-items: center;
  img {
    cursor: pointer;
  }
`;
