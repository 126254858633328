import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import ComponentMap from 'src/components/user-mobile/map/ComponentMap';

import DayPopup from 'src/components/user-mobile/map/DayPopup/DayPopup';
import MapHeader from 'src/components/user-mobile/map/MapHeader';
import { topTextState } from 'src/recoils/user-mobile.atom';

function MapHome() {
  const [dayPopup, setDayPopup] = useState(false);
  const topText = useRecoilValue(topTextState);
  return (
    <div>
      {dayPopup && <DayPopup setDayPopup={setDayPopup} />}
      <MapHeader topText={topText} setDayPopup={setDayPopup} />
      <ComponentMap />
    </div>
  );
}

export default MapHome;
