import { useSetRecoilState } from 'recoil';
import { markerPopupState } from 'src/recoils/user-mobile.atom';
import styled from 'styled-components';
import x from 'src/assets/images/map/x.png';

function Close({
  setIsFull,
}: {
  setIsFull: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const setMarkerPopup = useSetRecoilState(markerPopupState);

  const handleIsFull = () => {
    setIsFull(false);
    setMarkerPopup(0);
  };
  return (
    <CloseButton onClick={handleIsFull}>
      <img src={x} alt="x" />
    </CloseButton>
  );
}
export default Close;

const CloseButton = styled.button`
  width: 48px;
  height: 48px;

  position: fixed;
  bottom: 20px;

  //fixed에서 가운데정렬 하는 법
  left: 50%; // 화면의 가로 중앙에 위치하도록 설정하기
  transform: translateX(-50%); // 자신의 너비의 반만큼 왼쪽으로 이동하기

  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 32px;
  border: 1px solid #fff;

  background: ${({ theme }) => theme.colors.backgroundColor};

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  p {
    font-size: 24px;
    flex-shrink: 0;
    color: var(--Font-01_White, #111);
    text-align: center;

    font-style: normal;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
`;
