import { useState } from 'react';
import { IMissingInfo } from 'src/interfaces/user-mobile/missing.interface';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import { Btn } from './SuccessPopup';
import { PopupOverlay } from './AgreePopup';

function MissingPersonPopupBox({
  info,
  i,
  total,
}: {
  info: IMissingInfo;
  i: number;
  total: number;
}) {
  const [isPopup, setIsPopup] = useState<boolean>(true);

  const handleClose = () => {
    setIsPopup(false);
  };
  return isPopup ? (
    <>
      {i === 0 && <PopupOverlay />}
      <PopupBox>
        <h1>
          {info.name}({info.age}) 실종자를 찾습니다.
        </h1>
        {info.thumbnail ? <Img src={info.thumbnail} /> : ''}

        <TextBox>
          <Flex>
            <Title>실종위치</Title>
            <Text>{info.missingLocation}</Text>
          </Flex>
          <Flex>
            <Title>실종시간</Title>
            <Text>{info.missingTime}</Text>
          </Flex>
          <Flex style={{ alignItems: 'start' }}>
            <Title>특이사항</Title>
            <TextArea>{info.content}</TextArea>
          </Flex>
        </TextBox>
        <Flex>
          <Btn
            onClick={() => {
              document.location.href = 'tel:0612448729';
            }}
            style={{ marginTop: '20px', width: '256px', height: '52px' }}
          >
            실종자 센터 연락
          </Btn>
          <Close onClick={handleClose}>닫기 {`(${total - i}/${total})`}</Close>
        </Flex>
      </PopupBox>
    </>
  ) : (
    <></>
  );
}
export default MissingPersonPopupBox;

const PopupBox = styled.div`
  padding: 24px 16px;

  width: 376px;
  min-height: 312px;
  flex-shrink: 0;
  border-radius: 16px 16px 0px 0px;
  background: var(--white, #fff);

  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  bottom: 0;

  z-index: 1000;

  h1 {
    width: 352px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const Img = styled.img`
  margin-top: 12px;
  width: 180px;
  height: 240px;
  flex-shrink: 0;
  border-radius: 8px;
`;
const TextBox = styled.div`
  padding: 14px 16px;
  width: 343px;
  height: 156px;
  border-radius: 8px;
  background: #fbf7f3;
  flex-shrink: 0;
  margin-top: 12px;

  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Title = styled.div`
  color: #f23d37;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;
const Text = styled.div`
  color: #111;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-left: 6px;
`;

const Close = styled.div`
  height: 52px;
  flex-shrink: 0;

  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  color: #111;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 141.176% */
  letter-spacing: -0.425px;
`;

const TextArea = styled.textarea`
  padding: 0;
  margin-left: 6px;
  width: 248px;
  height: 80px;
  color: #111;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  overflow: hidden;
  border: 0;
  background-color: #fbf7f3;
`;
