import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { missingInfoState } from 'src/recoils/user-mobile.atom';
import styled from 'styled-components';
import { MissingSemiTitle } from './MissingInput';

interface IProps {
  select: string[];
  title: string;
  id: string;
}

function MissingBtn({ select, title, id }: IProps) {
  const [info, setInfo] = useRecoilState(missingInfoState);
  const [active, setActive] = useState(info[id]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setActive(e.currentTarget.id);
    const value = e.currentTarget.id;
    setInfo({ ...info, [`${id}`]: value });
  };

  useEffect(() => {
    setActive(info[id]);
  }, [info]);

  return (
    <>
      <MissingSemiTitle>{title}</MissingSemiTitle>
      <BtnContainer>
        {select.map(e => {
          return (
            <GenderBtn active={active} id={e} onClick={handleClick}>
              {e}
            </GenderBtn>
          );
        })}
      </BtnContainer>
    </>
  );
}

export default MissingBtn;

const GenderBtn = styled.button<{
  active: string | boolean | number | undefined;
  id: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 48px;
  flex-shrink: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  ${props =>
    props.active === props.id
      ? 'background: #111; color:white;'
      : 'background: #fff; color:#111;'}
  border-radius: 12px;
`;

const BtnContainer = styled.div`
  margin-top: 6px;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 12px;

  display: flex;
  padding: 4px;
`;
