import { useState } from 'react';
import { inquiryBlank } from 'src/consts/user-mobile/blank.const';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { IInquiry } from 'src/interfaces/user-mobile/inquiry.interface';
import { apiInquiry } from 'src/apis/utils/inquiry';
import styled from 'styled-components';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import lock from 'src/assets/images/user-web/Secret.svg';
import {
  ListBoard,
  MainBoard,
  PostElement,
  WriterDateBox,
} from 'src/styles/user-mobile/PostListStyle';
import { useMutation, useQuery } from '@tanstack/react-query';
import InquiryModal from './InquiryModal';

function InquiryListBoard() {
  const navigate = useNavigate();
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  // 현재 카테고리의 인덱스, 우선 처음부터 원소가 선택되어있으면 안 되므로 초기값은 -1

  const onClickModal = (isShow: boolean) => {
    setShowModal(isShow);
  };
  // useQuery 훅을 사용하여 문의 리스트 가져오기
  const { data: inquiryList } = useQuery({
    queryKey: ['inquiryList', festivalId], // 캐시 키
    queryFn: () =>
      apiInquiry
        .getInquiryListForUser(festivalId)
        .then(response =>
          response.data.inquiryList.sort(
            (a: IInquiry, b: IInquiry) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime(),
          ),
        )
        .catch(() => {
          // console.log(err, item.name);
        }),
    placeholderData: [{ ...inquiryBlank }],
  });

  // useMutation 훅을 사용하여 특정 문의 정보 가져오기
  const postMutation = useMutation({
    mutationFn: ({
      id,
      userId,
      password,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isSecret,
    }: {
      id: number;
      userId: string;
      password: string;
      isSecret: boolean;
    }) => apiInquiry.postInquiryInfoGetForUser(id, userId, password),
    onSuccess: (response, variables) => {
      const inquiryInfo = response.data;
      navigate(`${variables.id}`, { state: { ...inquiryInfo } });
    },
    onError: (_, variables) => {
      if (variables.isSecret) alert('잘못된 비밀번호입니다.');
      else alert('문의사항 조회 오류입니다.');
    },
  });

  const navigateInquiryInfo = (
    id: number,
    userId: string,
    password: string,
    isSecret: boolean,
  ) => {
    postMutation.mutate({ id, userId, password, isSecret });
  };

  return (
    <ContainerInquiry>
      <ListBoard>
        <MainBoard>
          {inquiryList?.map((item: IInquiry, index: number) => (
            <InquiryElement
              key={index.toString()}
              onClick={() => {
                setCurrentIndex(index);
                if (item.isSecret) {
                  onClickModal(true);
                } else navigateInquiryInfo(item.id, item.userId, '', false);
              }}
              isAnswered={item.isAnswered}
            >
              {item.isAnswered ? <h3>답변완료</h3> : <h3>미답변</h3>}
              {item.isSecret ? (
                <h1>
                  비밀글입니다.
                  <img src={lock} alt="" />
                </h1>
              ) : (
                <h1>{item.title}</h1>
              )}

              <WriterDateBox>
                <h2>{item.userId}</h2>
                <h4>{item.createdDate.slice(0, 19).replace('T', ' ')}</h4>
              </WriterDateBox>
            </InquiryElement>
          ))}
        </MainBoard>
      </ListBoard>
      {showModal && (
        <InquiryModal
          onClickModal={onClickModal}
          inquiryId={inquiryList[currentIndex].id}
          userId={inquiryList[currentIndex].userId}
          navigateInquiryInfo={navigateInquiryInfo}
        />
      )}
    </ContainerInquiry>
  );
}
export default InquiryListBoard;

const ContainerInquiry = styled(Flex)`
  position: relative;
  width: 100%;
  flex-direction: column;
`;
const InquiryElement = styled(PostElement)<{ isAnswered: boolean }>`
  h1 {
    > img {
      padding-top: 2px;
      width: 16px;
      height: 16px;
    }
  }
  h3 {
    color: ${props =>
      props.isAnswered ? props.theme.colors.fontColor : '#505050'};
  }
`;
