import back from 'src/assets/images/user-web/Back.svg';
import { IWebBackButton } from 'src/interfaces/user-web.interface';
import styled from 'styled-components';

function WebBackButton({ onClickBackButton, className }: IWebBackButton) {
  return (
    <BackImage
      onClick={onClickBackButton}
      className={className}
      src={back}
      alt="뒤로가기 이미지"
    />
  );
}

export default WebBackButton;

const BackImage = styled.img`
  cursor: pointer;
`;
