import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPost } from 'src/interfaces/user-mobile/post.interface';
import {
  PostElement,
  WriterDateBox,
} from 'src/styles/user-mobile/PostListStyle';
import getTranslateString from 'src/utils/getTranslateString';

function PostListBox({ item, indexProp }: { item: IPost; indexProp: number }) {
  const navigate = useNavigate();
  const [index, setIndex] = useState('');
  const [title, setTitle] = useState('');
  const [writer, setWriter] = useState('');

  useEffect(() => {
    if (localStorage.getItem('language') !== 'ko') {
      if (item.index !== '')
        getTranslateString(item.index).then(res => {
          setIndex(res);
        });
      else setIndex('');
      if (item.title !== '')
        getTranslateString(item.title).then(res => {
          setTitle(res);
        });
      else setTitle('');
      if (item.writer !== '')
        getTranslateString(item.writer).then(res => {
          setWriter(res);
        });
      else setWriter('');
    } else {
      setIndex(item.index);
      setTitle(item.title);
      setWriter(item.writer);
    }
  }, [item]);

  return (
    <PostElement
      key={indexProp.toString()}
      onClick={() => navigate(`${item.id}`, { state: { time: item.time } })}
    >
      <h3>{index}</h3>

      <h1>{title}</h1>

      <WriterDateBox>
        <h2>{writer}</h2>
        <h4>
          {item.time.slice(0, 10)}
          &nbsp;
          {item.time.slice(11, 19)}
        </h4>
      </WriterDateBox>
      {/* {item.thumbnail.length > 0 ? (
  <img src={item.thumbnail} alt="썸네일 이미지" />
) : (
  <S.ImgBlank />
)} */}
    </PostElement>
  );
}

export default PostListBox;
