function getSubAddress(isOnlyMobile: boolean) {
  const { hostname } = window.location;
  const envSubAddress = process.env.REACT_APP_SUBADDRESS || '';

  if (isOnlyMobile) return hostname.split('.')[0];

  if (envSubAddress === 'ROOT_LOCAL')
    return hostname.startsWith('m.') // m.으로 시작할 경우
      ? hostname.split('.')[1] // m.을 건너뛰고 다음 원소(문자열)가 서브도메인
      : hostname.split('.')[0]; // m.이 아니니 첫 번쨰 원소가 바로 서브도메인
  return envSubAddress;
}

export default getSubAddress;
