import styled from 'styled-components';
import language from 'src/assets/images/user-mobile/language.svg';
import dropdown from 'src/assets/images/user-mobile/Dropdown.svg';
import { languageList } from 'src/assets/database/languageData';
import { useEffect, useState } from 'react';
import { ILanguage } from 'src/interfaces/user-mobile/main.interface';
import { useRecoilState } from 'recoil';
import { activeLanguageState } from 'src/recoils/user-mobile.atom';

function LanguageDropdown() {
  const [isPopup, setIsPopup] = useState(false);
  const [activeLanguage, setActiveLanguage] =
    useRecoilState(activeLanguageState);
  const handlePopup = () => {
    setIsPopup(!isPopup);
  };

  const handleLanguage = (languageProp: ILanguage) => {
    setActiveLanguage(languageProp.text);
    setIsPopup(false);
    localStorage.setItem('language', languageProp.langCode);
    localStorage.setItem('langText', languageProp.text);
  };

  useEffect(() => {
    setActiveLanguage(localStorage.getItem('langText') || 'KOR');
  }, []);

  return (
    <LanguageContainer>
      <LanguageBox onClick={handlePopup}>
        <img src={language} alt="언어 변경 이미지" />
        {activeLanguage}
        <img src={dropdown} alt="드롭다운 이미지" />
      </LanguageBox>
      {isPopup && (
        <DropDownContainer>
          {languageList.map((e: ILanguage) => (
            <DropDownBox onClick={() => handleLanguage(e)}>
              {e.text}
            </DropDownBox>
          ))}
        </DropDownContainer>
      )}
    </LanguageContainer>
  );
}

export default LanguageDropdown;

const LanguageContainer = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const LanguageBox = styled.div`
  display: inline-flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;

  border-radius: 24px;
  background: var(--Font-01_White, #fff);

  color: var(--Font-02_black, #111);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.3px;
`;

const DropDownContainer = styled.div`
  position: absolute;
  right: 0;
  width: 116px;

  flex-shrink: 0;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.24);

  padding: 14px 18px;
`;

const DropDownBox = styled.div`
  font-family: 'Pretendard Variable';

  display: flex;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
`;
