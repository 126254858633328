// 여기 state들은 안 쓰이는거같던데 이 파일 지우고
// 맵 관련 state들은 그냥 manage, user만 나눠서 진행하는건 어떨지?
import { atom } from 'recoil';

export const markerImgState = atom({
  key: 'markerImg',
  default: '',
});

export const activeMarkersState = atom<naver.maps.Marker[]>({
  key: 'activeMarkers',
  default: [],
});

export const latlngState = atom({
  key: 'latlng',
  default: new naver.maps.LatLng(0, 0),
});
