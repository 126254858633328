import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import styled from 'styled-components';
import { Flex } from './OverviewStyle';

export const ListBoard = styled.div`
  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
  margin-top: 6px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const MainBoard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 2px;
`;

export const PostElement = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--Line-Light_Color, #f1f1f5);

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  cursor: pointer;
  h1 {
    width: 95%;
    text-align: left;
    color: #111;

    /* body1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // 글자수 넘어갈 때 한 줄처리
  }
  h2 {
    text-align: left;
    color: var(--Font-02_black, #111);

    /* body3 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    margin-right: 4px;
  }
  h3 {
    width: 100%;
    text-align: left;
    color: ${({ theme }) => theme.colors.fontColor};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
  }
  h4 {
    text-align: left;
    color: #777;

    /* body3 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  img {
    width: 68px;
    height: 68px;
    object-fit: cover;
    object-position: center;

    border-radius: 8px;
  }
`;

export const WriterDateBox = styled(Flex)`
  align-items: flex-start;
  gap: 4px;
`;
export const ImgBlank = styled.div`
  width: 68px;
  height: 68px;
`;
