import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { topTextState } from 'src/recoils/user-mobile.atom';
import map from 'src/assets/images/user-mobile/stamp/Map.svg';
import styled from 'styled-components';

function ToMapButton({
  isDetail,
  boothId,
}: {
  isDetail: boolean;
  boothId: number;
}) {
  const navigate = useNavigate();
  const setTopText = useSetRecoilState(topTextState);

  const onClickMoveMap = () => {
    setTopText('스탬프 지도');
    navigate('/map', { state: { stampBoothId: boothId } });
  };

  return isDetail ? (
    <DetailButton onClick={onClickMoveMap}>
      <img src={map} alt="" />
      미션 장소 확인
    </DetailButton>
  ) : (
    <HomeButton onClick={onClickMoveMap}>
      <img src={map} alt="" />
      지도
    </HomeButton>
  );
}
export default ToMapButton;

const HomeButton = styled.button`
  width: 30%;
  padding: 14px;
  background: var(--white, #fff);

  color: #111;
`;

const DetailButton = styled.button`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8px;
  height: 40px;
  padding: 8px 16px;

  flex-shrink: 0;
  border-radius: 64px;
  background: #fff;
  box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.24);

  color: #111;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  > img {
    width: 16px;
  }
`;
