import {
  STAMP_DOING,
  STAMP_FONT,
  STAMP_USER_JOB,
} from 'src/assets/database/stampData';
import { IThemeColors } from 'src/interfaces/overview.interface';
import { IStampPostUserInfo } from 'src/interfaces/user-mobile/stamp.interface';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled, { useTheme } from 'styled-components';

interface IProps {
  buttonText: string;
  onChangeForm: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSubmit: () => void;
  disabled: boolean;
  phoneRequired: boolean;
  userInfo: IStampPostUserInfo;
}

function StampStartForm({
  onChangeForm,
  onClickSubmit,
  disabled,
  phoneRequired,
  userInfo,
  buttonText,
}: IProps) {
  const theme = useTheme();

  return (
    <BoardStampStartForm colors={theme.colors} disabled={disabled}>
      <h1>{STAMP_USER_JOB}&nbsp;정보&nbsp;입력</h1>
      <input
        onChange={onChangeForm}
        name="name"
        value={userInfo.name}
        placeholder="이름 입력"
      />
      <input
        onChange={onChangeForm}
        name="schoolNo"
        value={userInfo.schoolNo}
        placeholder="학번 입력"
      />
      <input
        onChange={onChangeForm}
        name="phone"
        value={userInfo.phone}
        placeholder="휴대폰 번호 입력"
      />

      {phoneRequired && (
        <p>&lsquo;-&rsquo; 없이 입력해주세요 (ex: 01012345678)</p>
      )}

      <button disabled={disabled} onClick={onClickSubmit} type="button">
        {STAMP_DOING}&nbsp;{buttonText}
      </button>
    </BoardStampStartForm>
  );
}

export default StampStartForm;

const BoardStampStartForm = styled(FlexBox)<{
  colors: IThemeColors;
  disabled: boolean;
}>`
  flex-direction: column;
  align-items: center;
  width: 100%;
  > h1 {
    color: #111;
    text-align: center;
    font-family: ${STAMP_FONT};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 128.571% */
    letter-spacing: -0.07px;

    margin-bottom: 20px;
  }
  > input {
    width: 80%;
    height: 52px;
    flex-shrink: 0;

    border: none;
    border-radius: 8px;
    background: var(--white, #fff);
    padding: 14px 16px;

    color: var(--Font-03_Gray, #505050);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;

    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 4px;
    }
  }
  > p {
    color: ${({ colors }) => colors.fontColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  > button {
    width: 80%;
    height: 56px;
    flex-shrink: 0;

    border-radius: 8px;
    background: ${({ colors }) => colors.mainColor};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    margin-top: 16px;
    margin-bottom: 12px;

    color: #fff;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
`;
