export const imageInfoBlank = {
  imageId: 0,
  image: '',
};

export const postBlank = {
  id: 0,
  title: '',
  index: '',
  writer: '',
  thumbnail: '',
  picked: false,
  time: '',
};
export const postInfoBlank = {
  id: 0,
  title: '',
  thumbnail: '',
  images: [{ imageId: 0, image: '' }],
  index: '',
  writer: '',
  content: '',
  createTime: '',
};

export const bannerInfoBlank = {
  id: 0,
  rank: 0,
  type: '',
  thumbnail: '',
};
export const downWidgetBlank = {
  name: '',
  url: '',
};
export const widgetInfoBlank = {
  id: 0,
  name: '',
  description: '',
  icon: '',
  url: '',
};
export const mainMenusBlank = {
  menuName1: '',
  menuName2: '',
  menuSummary1: '',
  menuSummary2: '',
  menuImage1: '',
  menuImage2: '',
  menuUrl1: '',
  menuUrl2: '',
};
export const middleBannerBlank = {
  url: '',
  image: '',
  bannerRank: 0,
};
export const upWidgetInfoBlank = {
  id: 0,
  title: '',
  url: '',
  startDateTime: '',
  endDateTime: '',
};

export const userConcertCategoryBlank = {
  concertInfoId: 0,
  name: '',
};

// 스탬프 (시작)
export const stampDataBlank = {
  stampOn: false,
  stampId: 0,
};

export const stampPostUserInfoBlank = {
  name: '',
  phone: '',
  participantCount: 0,
};
export const stampMissionBlank = {
  missionId: 0,
  title: '',
  clearedThumbnail: '',
  notClearedThumbnail: '',
};
export const stampMissionInfoBlank = {
  missionId: 0,
  title: '',
  boothId: 0,
  time: '',
  place: '',
  content: '',
  clearedThumbnail: '',
  notClearedThumbnail: '',
};
export const stampUserMissionInfoBlank = {
  userMissionId: 0,
  missionId: 0,
  clear: false,
};
export const stampUserInfoBlank = {
  id: 0,
  uuid: '',
  participantCount: 0,
  finished: false,
  userMissionInfoGetListDto: {
    userMissionInfoGetDtos: [{ ...stampUserMissionInfoBlank }],
  },
};
// 스탬프 (끝)

export const lineUpBlank = {
  concertId: 0,
  thumbnail: '',
  day: 0,
};

export const lineUpInfoBlank = {
  concertId: 0,
  thumbnail: '',
  images: [
    {
      imageId: 0,
      image: '',
    },
  ],
  durationDto: {
    durationId: 0,
    date: '0000-00-00',
    day: 0,
  },
};

export const concertInfoBlank = {
  icon: '',
  summary: '',
};

export const concertCategoryBlank = {
  concertInfoId: 0,
  name: '',
};
export const concertCategoryInfoBlank = {
  id: 0,
  name: '',
  images: [{ ...imageInfoBlank }],
};

export const inquiryInfoBlank = {
  id: 0,
  title: '',
  userId: '',
  createdDate: '',
  content: '',
  answer: '',
  isAnswered: true,
};

export const inquiryBlank = {
  id: 0,
  title: '',
  isAnswered: false,
  isSecret: false,
  userId: '',
  createdDate: '',
};
