import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;

  background-color: #fbf7f3;
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 166px;

  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img<{ width: number }>`
  width: ${props => props.width / 2}px;
  height: auto;
  object-fit: fill;
`;

export const TopMovingFixedBar = styled.div<{ top: boolean }>`
  position: ${props => (props.top ? 'fixed' : '')};
  top: ${props => (props.top ? '0' : '')};
  width: 100%;
  height: 56px;
  z-index: 10;
  flex-shrink: 0;
  border-bottom: 1px solid #dadada;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopMovingFixedBarBlank = styled.div<{ top: boolean }>`
  display: ${props => (props.top ? 'block' : 'none')};
  width: 100%;
  height: 56px;
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
`;
export const BarBtn = styled.button<{ current: boolean }>`
  color: ${props => (props.current ? '#f07600' : '#111')};

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.45px;

  &:hover {
    color: #f07600;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.45px;
  }
`;
export const Footer = styled(FlexBox)`
  width: 100%;
  height: 210px;
  flex-shrink: 0;

  background-color: #f4cca9;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    margin-bottom: 8px;

    color: var(--Font-02_black, #111);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }

  > h2 {
    margin-bottom: 8px;

    color: var(--Font-03_Gray, #505050);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;

export const IconBox = styled(FlexBox)`
  margin-bottom: 16px;
  cursor: pointer;

  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const ScrollTopButton = styled.img`
  position: fixed;
  z-index: 100;
  bottom: 72px;
  right: 74px;
  cursor: pointer;
  opacity: 0.7;
`;
