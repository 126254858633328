import markerImg from 'src/assets/images/map/markers/markerImg.svg';

import { imgStyle, markerStyle, textStyle } from 'src/consts/map/style.const';
import { getMarkerSize } from './getMarkerSize';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setMarkerOff(marker: any, info: any) {
  const [width, height] = getMarkerSize(info);

  marker.setIcon({
    content: `
        <div style="${markerStyle}">
          <img style="${imgStyle}" src=${markerImg} />
          <div style="${textStyle}">${info.name}<div/>
        <div/>
          `,
    size: new naver.maps.Size(width, height),
    anchor: new naver.maps.Point(width / 2, height / 2),
  });
}

export default setMarkerOff;
