// App.tsx전용 설정 상수
export const IS_CLOSED = false;
export const ALERT_TEXT = '해당 축제 사이트는 추후에 오픈 예정입니다.';
// 사이트 오픈 여부 상수 (true시 사이트 닫힘)
// + 접속 시 띄울 alert문구 상수
export const IS_ONLY_MOBILE = true;
// 모바일사이트만 존재하는지 여부 상수 (true시 웹사이트 없이 모바일 사이트만 존재))

// 스탬프 기능 설정 상수
// 스탬프 참여자 등록시 커스텀필드 여부
export const IS_STAMP_CUSTOM = true;
export const IS_STAMP_COUNT_PARTICIPANT = false;
export const IS_STAMP_GUIDE = false;
export const IS_STAMP_ON_SITE_GET = false;
export const IS_STAMP_REFETCH_BUTTON = false;
