import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { PopupOverlay } from './AgreePopup';

function SuccessPopup({
  setIsSuccess,
}: {
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const handlePopup = () => {
    setIsSuccess(false);
    navigate('/');
  };
  return (
    <>
      <PopupOverlay />
      <PopupBox>
        <h1>실종자 신청 완료</h1>
        <h2>
          필요 시, 종합안내센터로
          <br />
          방문해주세요.
        </h2>
        <Btn onClick={handlePopup}>확인</Btn>
      </PopupBox>
    </>
  );
}

export default SuccessPopup;

const PopupBox = styled.div`
  padding: 32px 16px 24px;

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  background-color: #fff;
  align-items: center;
  width: 279px;
  height: 192px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);

  h1 {
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
  }
  h2 {
    margin-top: 4px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
`;

export const Btn = styled.button`
  margin-top: 24px;
  width: 247px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #ff5a5a;
  color: #fff;
  font-size: 16px;
`;
