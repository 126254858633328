import {
  STAMP_COMPLETE_HEADER,
  STAMP_COMPLETE_PLACE,
  STAMP_DOING,
  STAMP_FONT,
  STAMP_COMPLETE_THUMBNAIL,
} from 'src/assets/database/stampData';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import {
  BoardSetWidthStamp,
  CloseButton,
} from 'src/styles/user-mobile/StampStyle';
import styled from 'styled-components';
import caution from 'src/assets/images/user-mobile/stamp/Caution.svg';
import x from 'src/assets/images/user-mobile/stamp/X2.svg';
import { useNavigate } from 'react-router-dom';
import { IS_STAMP_ON_SITE_GET } from 'src/consts/config.const';
import { FlexBox } from 'src/styles/OverviewStyle';

function StampComplete() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <StyledBoardSetWidthStamp>
        <XButton onClick={() => navigate('/stamp')} src={x} alt="스탬프 홈" />
        {IS_STAMP_ON_SITE_GET ? (
          <CompleteMainBoard>
            <HeaderText>{STAMP_COMPLETE_HEADER} 완료!</HeaderText>
            <ContentText>
              모든 미션을 마무리하고 {STAMP_DOING}을 완료하셨네요!
              <br />
              미션완료 기념 경품은&nbsp;
              <span>{STAMP_COMPLETE_PLACE}</span>에서
              <br /> 수령하실 수 있습니다.
            </ContentText>
            <ThumbnailImage
              src={STAMP_COMPLETE_THUMBNAIL}
              alt="스탬프 이미지"
            />
            <CautionText>
              <img src={caution} alt="" />
              참여인원 수에 맞게 상품을 제공드립니다.
            </CautionText>
          </CompleteMainBoard>
        ) : (
          <CompleteMainBoard>
            <ThumbnailImage
              src={STAMP_COMPLETE_THUMBNAIL}
              alt="스탬프 이미지"
            />
            <HeaderText>{STAMP_COMPLETE_HEADER} 완료!</HeaderText>
            <CautionText>
              완성한 스탬프판을 인스타 스토리로 인증하고 <br /> 추첨을 통해 경품
              받아가세요!
            </CautionText>
          </CompleteMainBoard>
        )}
        <CloseButton onClick={() => navigate('/stamp')}>닫기</CloseButton>
      </StyledBoardSetWidthStamp>
    </Wrapper>
  );
}

export default StampComplete;

const StyledBoardSetWidthStamp = styled(BoardSetWidthStamp)`
  position: relative;
  padding: 0;
`;

const CompleteMainBoard = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

const XButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const HeaderText = styled.h1`
  color: ${({ theme }) => theme.colors.mainColor};
  text-align: center;
  font-family: ${STAMP_FONT};
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  margin-bottom: 20px;
`;
const ContentText = styled.h2`
  color: #111;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 48px;
  span {
    color: ${({ theme }) => theme.colors.fontColor};
    font-weight: 600;
  }
`;
const ThumbnailImage = styled.img`
  width: 200px;
  margin-bottom: 32px;
`;
const CautionText = styled.h3`
  width: 270px;
  color: var(--Font-02_black, #111);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
  margin-bottom: 24px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;
