function getBoldString(tmpString: string) {
  // 정규식을 사용하여 **로 감싸진 부분을 감지하고 분리

  const splitedTmpString = tmpString.split(/(\*\*.*?\*\*)/);

  // 분리된 문자열 부분들을 map으로 순회하면서 **로 감싸진 부분만 <strong> 태그로 감싸기

  return splitedTmpString.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return <span key={index}>{part}</span>;
  });
}

export default getBoldString;
